import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingController, NavController } from '@ionic/angular';
import { PrimeNGConfig, SelectItem } from 'primeng/api';
import { ProgrammeClass } from 'src/app/classes/programme';
import { SareClass } from 'src/app/classes/sare';
import { AlertService } from 'src/app/services/alert/alert.service';
import {Location} from '@angular/common';

@Component({
  selector: 'app-asset-management',
  templateUrl: './asset-management.component.html',
  styleUrls: ['./asset-management.component.scss'],
})
export class AssetManagementComponent implements OnInit {

  employee_no: number = 0;
  selected_channels: number[] = [];
  searchFormGroup: FormGroup;
  channels: SareClass[] = [];

  display_array: ProgrammeClass[] = [];
  sortOptions: SelectItem[];
  sortOrder: number;
  sortField: string;
  
  constructor(private primengConfig: PrimeNGConfig,private alertService: AlertService, route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder, private httpClient: HttpClient,
    private loadingCtrl: LoadingController, private navCtrl: NavController, public dialog: MatDialog,
    private _location: Location) {

      this.employee_no = parseInt(JSON.parse(localStorage.getItem('employee_no')));
      this.onChannels(); 
  }

  ngOnInit() {

    this.searchFormGroup = this.formBuilder.group({
        channelsCtrl: [this.selected_channels],
    });

    this.sortOptions = [
      {label: 'Newest First', value: '!prog_no'},
      {label: 'Oldest First', value: 'prog_no'},
      {label: 'Programme Name', value: 'prog_name'}
  ];

    this.primengConfig.ripple = true;
  }

  onSortChange(event) {
    let value = event.value;

      if (value.indexOf('!') === 0) {
          this.sortOrder = -1;
          this.sortField = value.substring(1, value.length);
      }
      else {
          this.sortOrder = 1;
          this.sortField = value;
      }
  }

  async onChannels() {

    const response = this.httpClient.post(`https://mojalove.mmsafrica.co.za/api/schedule/get_channels.php`,
        {
            channes: this.selected_channels,
            date: '',
            employee_no: this.employee_no,
    
        }, {responseType: 'json'}).pipe()
        .subscribe(
            (data: any) => {
    
                for (let i = 0; i < data.length; i++) {
                    this.channels[i] = {
                        bdcr_no: data[i].bdcr_no,
                        genre: data[i].genre,
                        platform: data[i].platform,
                        sare_name: data[i].sare_name,
                        sare_no: data[i].sare_no
                    };
                }
                this.selected_channels.push(this.channels[0].sare_no);
                this.onReadProgrammes();
            }
        );
    }

  async onReadProgrammes() {

    const response = this.httpClient.post(`https://mojalove.mmsafrica.co.za/api/schedule/programme/get_programme.php`,
        {
            channes: this.selected_channels,
            employee_no: this.employee_no,
    
        }, {responseType: 'json'}).pipe()
        .subscribe(
            (data: any) => {
    
                this.display_array = [];
    
                for (let i = 0; i < data.length; i++) {
                    this.display_array[i] = {
                        genre: data[i].genre,
                        image: data[i].image,
                        language: data[i].language,
                        length: data[i].length,
                        prod_house: data[i].prod_house,
                        producer: data[i].producer,
                        prog_name: data[i].prog_name,
                        prog_no: data[i].prog_no,
                        bdcr_no: data[i].bdcr_no,
                        type: data[i].type,
                        category: data[i].category,
                        rcrd_no: data[i].rcrd_no,
                        repeat_name: data[i].repeat_name,
                        sare_no: data[i].sare_no,
                        subgenre: data[i].subgenre,
                        season_data: data[i].season_data,
                        genre_sentech: data[i].genre_sentech,
                        subgenre_sentech: data[i].subgenre_sentech,
                        contract_no: data[i].contract_no,
                        contract_type: data[i].contract_type,
                        distributors: data[i].distributors
                    };
                }
    
            }
        );
    }

    selectProgramme(prog_no,prog_name) {

    localStorage.setItem('prog_no', JSON.stringify(prog_no));
    localStorage.setItem('prog_name', JSON.stringify(prog_name));
    this.router.navigate(['/home/programme-assets']);
    }
}
