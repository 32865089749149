import {Component, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {AlertService} from '../../services/alert/alert.service';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {environment} from '../../../environments/environment';
import {BehaviorSubject} from 'rxjs';
import {SelectionModel} from '@angular/cdk/collections';
import {ConfirmationDialogComponent} from '../confirmation-dialog/confirmation-dialog.component';
import {Location} from '@angular/common';

//Import used to make HTTP get and post requests
import {HttpClient} from '@angular/common/http';
import {LoadingController, NavController} from '@ionic/angular';
import {finalize} from 'rxjs/operators';
import { FormBuilder, FormGroup,Validators, FormControl } from '@angular/forms';
import { GenreClass } from 'src/app/classes/genre';

@Component({
  selector: 'app-new-sentech-genre',
  templateUrl: './new-sentech-genre.component.html',
  styleUrls: ['./new-sentech-genre.component.scss'],
})
export class NewSentechGenreComponent implements OnInit {

  campaign: any;
  baseUrl = environment.serverUrl;

  genreFormGroup: FormGroup;
  genre: GenreClass = new GenreClass();

  constructor(private router: Router, private alertService: AlertService,private _location: Location,private httpClient: HttpClient,public dialog: MatDialog,private formBuilder: FormBuilder) {
    
    this.genre.bdcr_no = 10;
    this.genre.color = '#142FB8';
  }

  ngOnInit() {
   
    this.genreFormGroup = this.formBuilder.group({ 
      genreCtrl: [this.genre.genre, Validators.required],
      channelNoCtrl: [this.genre.bdcr_no, Validators.required],
      colorCtrl: [this.genre.color, Validators.required],
      codeCtrl: [this.genre.code, Validators.required],
    });
    
  }


  async saveGenre(){ 

    const response = this.httpClient.post(`https://mojalove.mmsafrica.co.za/api/schedule/save_sentech_genre.php`,
    {
      genre: this.genre.genre,
      bdcr_no: this.genre.bdcr_no,
      color: this.genre.color,
      code: this.genre.code,

    },{ responseType: 'json'}).pipe()
    .subscribe(
      (data:any) => {
         
        if(data.message == 'success')
        {

          this.genreFormGroup.reset();
          this.alertService.success("Category successfully saved");
          this.router.navigate(['/home/category-list']);
        }
        else{

          this.alertService.error(data.message);
        }

      },error => {
        this.alertService.error("Something went wrong, Try again");
      } 
    ) 
  }

  selectColor(color)
  {
    this.genre.color = color;
  }


  returnToDashboard() {
    this.router.navigate(['/home']);
  }

  back(){
    this._location.back();
  }

}