import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl,NgForm} from '@angular/forms';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {SelectionModel} from '@angular/cdk/collections';
import {MenuItem,TreeNode} from 'primeng/api';
import {TreeDragDropService} from 'primeng/api';
import {ScheduleClass} from '../../classes/schedule';
import {SareClass} from '../../classes/sare';
import {ProgrammeClass} from '../../classes/programme';
import {ContractClass} from '../../classes/acquisition';
import {LanguageClass} from '../../classes/language';
import {PGClass} from '../../classes/parentalguidance';
import {ContractListClass} from '../../classes/contractlist';
import { GenreClass } from '../../classes/genre';
import { SeasonClass } from '../../classes/season';
import { UserClass } from '../../classes/users';
import { SubGenreClass } from '../../classes/subgenre';
import { CategoryClass } from '../../classes/categories';
import {Location} from '@angular/common';

//Import used to make HTTP get and post requests
import { HttpClient } from '@angular/common/http';
import { LoadingController } from '@ionic/angular';
import { finalize } from 'rxjs/operators';
 
//To use autcomplete field
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { NavController } from '@ionic/angular';

//Toasta
import { Router, ActivatedRoute } from '@angular/router';
import {AlertService} from '../../services/alert/alert.service';

import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MatDatepicker} from '@angular/material/datepicker';

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

import * as _moment from 'moment';
import {default as _rollupMoment, Moment} from 'moment';
import { EpisodeClass } from '../../classes/episode';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { AddNewEpisodeDialogComponent } from '../add-new-episode-dialog/add-new-episode-dialog.component';
import { AddNewEpisode } from 'src/app/classes/addNewEpisodes';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MaterialClass } from 'src/app/classes/material';
const moment = _rollupMoment || _moment;

@Component({
  selector: 'app-view-season', 
  templateUrl: './view-season.component.html',
  styleUrls: ['./view-season.component.scss'],
  styles: [`
        .filter-container {
            text-align: center;
        }

        .car-details {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-bottom: 2em;
            border-bottom: 1px solid #d9dad9;
            width: 100%;
        }

        .car-details > div {
            display: flex;
            align-items: center;
        }

        .car-details > div img {
            margin-right: 14px;
        }

        .car-detail {
            padding: 0 1em 1em 1em;
            border-bottom: 1px solid #d9dad9;
            margin: 1em;
        }

        .ui-panel-content {
            padding: 1em;
        }

        .dark-theme :host ::ng-deep .car-details,
        .dark-theme :host ::ng-deep .car-detail {
            border-bottom: 1px solid #191919;
        }

        @media (max-width: 1024px) {
            .car-details img {
                width: 75px;
            }

            .filter-container {
                text-align: left;
            }
        }
    `],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({height: '0px', minHeight: '0'})),
            state('expanded', style({height: '*'})),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class ViewSeasonComponent implements OnInit {

  seasonFormGroup: FormGroup;
  employee_no: number = 0;
  prog_no: number = 0;
  season_no: number = 0;

  newEpisode: AddNewEpisode = new AddNewEpisode();
  season: SeasonClass = new SeasonClass();
  programme: ProgrammeClass = new ProgrammeClass();

  chkLN = false;
  chkN = false;
  chkS = false;
  chkV = false;
  chkB = false;
  chkD = false;
  chkP = false;
  pgs: PGClass[] = [];
  users: UserClass[] = [];

  genericSynopsis: String = '';
  parentalG: String = '';
  titile: String = '';

  display_array: EpisodeClass[] = [];
  displayedColumns: string[] = ['select', 'Programme','pg','censorship', 'season','episode','length','title','synopsis','material'];
  dataSource = new MatTableDataSource(this.display_array);
  expandedElement: EpisodeClass | null;
  selection = new SelectionModel<EpisodeClass>(true, []);

  //Material
  material_status: string = 'All';
  material_type: string = 'All';
  material_Sidebar: boolean = false;
  material_infor_Sidebar: boolean = false;
  material_array: MaterialClass[] = [];
  available_array: MaterialClass[] = [];

  //Assign
  selected_progNo: number = 0;
  selected_seasNo: number = 0;
  selected_epiNo: number = 0;
  selected_segm_no: number = 0;
  material: MaterialClass = new MaterialClass();

  available_displayedColumns: string[] = ['MATERIAL_ID', 'MATERIAL_TITLE','DURATION','ACTION'];
  available_dataSource = new MatTableDataSource(this.available_array);
  available_selection = new SelectionModel<any>(true, []);
  @ViewChild(MatSort, {static: true}) sort3: MatSort;
  @ViewChild('paginator3') paginator3: MatPaginator;


  constructor(private alertService: AlertService,route:ActivatedRoute,private router: Router,private formBuilder: FormBuilder,private httpClient: HttpClient,private loadingCtrl: LoadingController,private navCtrl: NavController,public dialog: MatDialog,private _location: Location) 
  {
    this.employee_no = parseInt(JSON.parse(localStorage.getItem('employee_no')));
    this.prog_no = parseInt(JSON.parse(localStorage.getItem('prog_no')));
    this.season_no = parseInt(JSON.parse(localStorage.getItem('season_no')));

    this.newEpisode.prog_no = this.prog_no;
    this.newEpisode.seas_no = this.season_no;

    this.onReadProgOptions();
    this.onReadProgSeasons();
    this.onReadSeasonEpisodes();
    this.onReadMaterials();
  }

  ngOnInit() 
  {
    this.seasonFormGroup = this.formBuilder.group({
      epiLengthCtrl: [this.programme.length,Validators.required],
      segmentsCtrl: [this.season.segments,Validators.required],
      parentalGCtrl: [this.parentalG],
      chkLNCtrl: [this.chkLN],
      chkNCtrl: [this.chkN],
      chkSCtrl: [this.chkS],
      chkVCtrl: [this.chkV],
      chkBCtrl: [this.chkB],
      chkDCtrl: [this.chkD],
      chkPCtrl: [this.chkP],
      genericSynopsisCtrl: [this.genericSynopsis],
      year_of_releaseCtrl: [this.season.year_of_release],
      producerCtrl: [this.season.producer],
      directorCtrl: [this.season.directors],
      actorsCtrl: [this.season.actors],
      titileCtrl: [this.titile],
    });
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  } 

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: EpisodeClass): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.idNo + 1}`;
  }

   //Apply filter when using search box
  applyFilter(filterValue: string) {
    this.selection.clear();
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;


  onClickRow(expandedElement, element) { 

    //this.dataSeasonSource.data = element.season_data;
    this.expandedElement = this.expandedElement === element ? null : element
  }

  selectedEpisodes(): void {

    this.selection.selected.forEach(episode => {
      this.editEpisode(episode.epi_no);
    });

    this.selection.clear();
  }

  async editEpisode(episodeNo)
  {
      
    const response = this.httpClient.post(`https://mojalove.mmsafrica.co.za/api/schedule/programme/edit_episodes.php`,
    {

      employee_no: this.employee_no,
      exhibition: 0,
      old_sea_no: this.season_no,
      sea_no: this.season.seas_no,
      epi_seg: this.season.segments,
      prog_no: this.prog_no,
      start_date: moment(this.season.sst_date.toString()).format("YYYY-MM-DD"),
      end_date: moment(this.season.end_date.toString()).format("YYYY-MM-DD"),
      parental_g: this.parentalG,
      synop: this.genericSynopsis,
      title: this.titile,
      epi_length: this.programme.length,
      producer: this.season.producer,
      directors: this.season.directors,
      actors: this.season.actors,
      year_of_release: this.season.year_of_release,
      episodeNo: episodeNo,
 
      chkLN: this.chkLN,
      chkN: this.chkN,
      chkS: this.chkS,
      chkV: this.chkV,
      chkB: this.chkB,
      chkD: this.chkD,
      chkP: this.chkP,

    },{ responseType: 'json'}).pipe( )
    .subscribe(
      (data:any) => {

        if(data.message == 'success')
        {
          this.alertService.success('Selected Episodes successfully updated');
          this.onReadProgSeasons();
          this.onReadSeasonEpisodes();
        }
        else
        { 
          this.alertService.error(data.message);
        }
      } 
    ) 
  } 

  async editSeason(){ 
   
    const response = this.httpClient.post(`https://mojalove.mmsafrica.co.za/api/schedule/programme/edit_season.php`,
    {

      employee_no: this.employee_no,
      exhibition: 0,
      old_sea_no: this.season_no, 
      sea_no: this.season.seas_no,
      epi_seg: this.season.segments,
      prog_no: this.prog_no,
      start_date: moment(this.season.sst_date.toString()).format("YYYY-MM-DD"),
      end_date: moment(this.season.end_date.toString()).format("YYYY-MM-DD"),
      parental_g: this.parentalG,
      synop: this.genericSynopsis,
      title: this.titile,
      epi_length: this.programme.length,
      producer: this.season.producer,
      directors: this.season.directors,
      actors: this.season.actors,
      year_of_release: this.season.year_of_release,

      chkLN: this.chkLN,
      chkN: this.chkN,
      chkS: this.chkS,
      chkV: this.chkV,
      chkB: this.chkB,
      chkD: this.chkD,
      chkP: this.chkP,

    },{ responseType: 'json'}).pipe()
    .subscribe(
      (data:any) => {

        if(data.message == 'success')
        {
          this.alertService.success('Season successfully saved');
          this.season_no = data.seasonNo;
          localStorage.setItem('season_no', JSON.stringify(this.season_no));

          this.selectedEpisodes();
        }
        else
        { 
          this.alertService.error(data.message);
        }
      } 
    ) 
  }




  //Material Starts Here
  //Apply filter when using search box
  applyFilter3(filterValue: string) {
    this.available_selection.clear();
    this.available_dataSource.filter = filterValue.trim().toLowerCase();
  }


  removeMaterial(progNo,seasNo,epiNo,segm_no,materialId): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '400px',
        data: 'Are you sure you want to remove material '+materialId+' ?'
    });
    
    dialogRef.afterClosed().subscribe(result => {
        if (result) {

            this.remMaterial(progNo,seasNo,epiNo,segm_no,materialId);
        }
    });
  }

  remMaterial(progNo,seasNo,epiNo,segm_no,materialId) {
    const response = this.httpClient.post(`https://mojalove.mmsafrica.co.za/api/schedule/material/remove_material.php`,
        {
          progNo: progNo,
          seasNo: seasNo,
          epiNo: epiNo,
          segm_no: segm_no,
          materialId: materialId
    
        }, {responseType: 'json'}).pipe()
        .subscribe(
            (data: any) => {
    
                if (data.message === 'success') {
                    this.alertService.success('Material successfully removed');
                    this.onReadSeasonEpisodes();

                    for(var i=0;i<this.material_array.length;i++)
                    {
                        if(this.material_array[i].MATERIAL_ID == materialId){
                          this.available_array.push(this.material_array[i]);

                          this.available_dataSource = new MatTableDataSource(this.available_array);
                          this.available_dataSource.sort = this.sort3;
                          this.available_dataSource.paginator = this.paginator3;

                        }
                    }

                } else {
                    this.alertService.error(data.message);
                }
            }
        );
 }

  viewMaterial(materialElement: MaterialClass) {

    this.material = materialElement;
    this.material_infor_Sidebar = true;
   }
  
  
   viewMaterialData(materialId) {
  
    for(var i=0;i<this.material_array.length;i++){
        if(this.material_array[i].MATERIAL_ID == materialId){
          this.material = this.material_array[i];
        }
    }
  
    this.material_infor_Sidebar = true;
   }
  
  
   assignMaterial(progNo,seasNo,epiNo,segm_no) {
  
    this.selected_progNo = progNo;
    this.selected_seasNo = seasNo;
    this.selected_epiNo = epiNo;
    this.selected_segm_no = segm_no;
    this.material_Sidebar = true;
   }
  
   addMaterial(materialId,object) {
  
        const response = this.httpClient.post(`https://mojalove.mmsafrica.co.za/api/schedule/material/add_material.php`,
        {
          progNo: this.selected_progNo,
          seasNo: this.selected_seasNo,
          epiNo: this.selected_epiNo,
          segm_no: this.selected_segm_no,
          materialId: materialId
  
        }, {responseType: 'json'}).pipe()
        .subscribe(
            (data: any) => {
  
                if (data.message === 'success') {
                    this.alertService.success('Material successfully saved');
                    this.material_Sidebar = false;
                    this.onReadSeasonEpisodes();
                    
                } else {
                    this.alertService.error(data.message);
                }
            }
        );
    }

  async onReadMaterials(){ 
      
    const response = this.httpClient.post(`https://mojalove.mmsafrica.co.za/api/schedule/material/read_materials.php`,
    {
      material_type: 'X',
      employee_no: this.employee_no,

    },{ responseType: 'json'}).pipe( )
    .subscribe(
      (data:any) => {
         
        this.material_array = [];
        this.available_array = [];
        let index = 0;

        for(var i=0;i<data.length;i++)
        {
            this.material_array[i] = {idNo: data[i].idNo,_NOT_ASSIGNED: data[i]._NOT_ASSIGNED,_NOT_ASSIGNED_TIME: data[i]._NOT_ASSIGNED_TIME,ABBREVIATED_TITLE: data[i].ABBREVIATED_TITLE,AGENCY_COPY_NUMBER: data[i].AGENCY_COPY_NUMBER,ASPECT_RATIO: data[i].ASPECT_RATIO,AUDIO1: data[i].AUDIO1,AUDIO2: data[i].AUDIO2,AUDIO3: data[i].AUDIO3,AUDIO4: data[i].AUDIO4,CHANGE_TIME: data[i].CHANGE_TIME,DESCRIPTION: data[i].DESCRIPTION,DURATION: data[i].DURATION,EMBARGO_DATE: data[i].EMBARGO_DATE,ENTRY_DATE: data[i].ENTRY_DATE,ENTRY_OPERATOR: data[i].ENTRY_OPERATOR,EXPIRE_DATE: data[i].EXPIRE_DATE,FRAME_RATE: data[i].FRAME_RATE,GROUP_ID: data[i].GROUP_ID,MATERIAL_EVENTS: data[i].MATERIAL_EVENTS,MATERIAL_ID: data[i].MATERIAL_ID,MATERIAL_TITLE: data[i].MATERIAL_TITLE,MATERIAL_TYPE: data[i].MATERIAL_TYPE,NOMINAL_DURATION: data[i].NOMINAL_DURATION,RELEASED: data[i].RELEASED,SEGMENTS: data[i].SEGMENTS,SOM: data[i].SOM,status: data[i].status};

            if(data[i].status == '' || data[i].status == null){
              this.available_array[index] = this.material_array[i];
              index = index + 1;
            }
        }

        this.available_dataSource = new MatTableDataSource(this.available_array);
        this.available_dataSource.sort = this.sort3;
        this.available_dataSource.paginator = this.paginator3;
      } 
    ) 
  }

  async onReadProgSeasons()
  {

    const response = this.httpClient.post(`https://mojalove.mmsafrica.co.za/api/schedule/programme/get_seasons.php`,
    {
        employee_no: this.employee_no,
        prog_no: this.prog_no,
        seasonNo: this.season_no,

     },{ responseType: 'json'}).pipe()
    .subscribe(
      (data:any) => {
        
          this.season.sst_date = data[0].sst_date;
          this.season.end_date = data[0].end_date;
          this.season.seas_no = data[0].seas_no;
          this.programme.length = data[0].length;
          this.season.segments = data[0].segments;
          this.season.producer = data[0].producer;
          this.season.directors = data[0].directors;
          this.season.actors = data[0].actors;
          this.season.year_of_release = data[0].year_of_release;

      } 
    )
  }

  async onReadSeasonEpisodes()
  {

    const response = this.httpClient.post(`https://mojalove.mmsafrica.co.za/api/schedule/programme/get_episodes.php`,
    {
        employee_no: this.employee_no,
        prog_no: this.prog_no,
        seasonNo: this.season_no,

     },{ responseType: 'json'}).pipe()
    .subscribe(
      (data:any) => {
        
        this.display_array = [];

        for(var i=0;i<data.length;i++)
        {
            this.display_array[i] = {epi_len: data[i].epi_len,seas_no: data[i].seas_no,title: data[i].title,b: data[i].b,break_price: data[i].break_price,d: data[i].d,day: data[i].day,epi_date: data[i].epi_date,epi_no: data[i].epi_no,exhibition_no: data[i].exhibition_no,idNo: data[i].idNo,n: data[i].n,nl: data[i].nl,omni_epi_no: data[i].omni_epi_no,p: data[i].p,pg: data[i].pg,prog_name: data[i].prog_name,prog_no: data[i].prog_no,rate_code: data[i].rate_code,replaced: data[i].replaced,run_no: data[i].run_no,s: data[i].s,synopsis: data[i].synopsis,v: data[i].v,material: data[i].material,segments_data: data[i].segments_data};

            this.parentalG = data[i].pg;
            this.newEpisode.programme_name = this.display_array[i].prog_name;
        }

        this.dataSource = new MatTableDataSource(this.display_array);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator; 
      } 
    )
  }

  openDeleteDialog(): void {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
          width: '400px',
          data: 'Are you sure you want to delete selected episodes ?'
      });

      dialogRef.afterClosed().subscribe(result => {
          if (result) {

              this.selection.selected.forEach(episode => {
                  this.deleteEpisode(episode.epi_no);
              });

              this.selection.clear();
          }
      });
  }


deleteEpisode(epis_no) {
  const response = this.httpClient.post(`https://mojalove.mmsafrica.co.za/api/schedule/programme/delete_episode.php`,
      {
          epis_no: epis_no,
          prog_no: this.prog_no,
          seas_no: this.season_no

      }, {responseType: 'json'}).pipe()
      .subscribe(
          (data: any) => {

              if (data.message == 'success') {
                  this.alertService.success('Episode successfully deleted');
                  this.onReadSeasonEpisodes(); 
              } else {
                  this.alertService.error(data.message);
              }
          }
      )
}

  addEpisodeDialog(): void {

    this.newEpisode.first_episode = 0;
    this.newEpisode.numer_of_episodes = 1;
    this.newEpisode.blnIncrement = false;

    const dialogRef = this.dialog.open(AddNewEpisodeDialogComponent, {
      width: '600px',
      data: this.newEpisode
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
  
        this.addNewEpisode();
      }
    });
  }

  async addNewEpisode(){ 
   
    const response = this.httpClient.post(`https://mojalove.mmsafrica.co.za/api/schedule/programme/add_new_episode.php`,
    {

      employee_no: this.employee_no,
      prog_no: this.newEpisode.prog_no,
      seas_no: this.newEpisode.seas_no,
      first_episode: this.newEpisode.first_episode,
      numer_of_episodes: this.newEpisode.numer_of_episodes,
      blnIncrement: this.newEpisode.blnIncrement,

    },{ responseType: 'json'}).pipe()
    .subscribe(
      (data:any) => {

        if(data.message == 'success')
        {
          this.alertService.success('Episodes successfully saved');
          this.onReadSeasonEpisodes();
        }
        else
        { 
          this.alertService.error(data.message);
        }
      } 
    ) 
  }

  async onReadProgOptions(){ 
      
    const response = this.httpClient.post(`https://mojalove.mmsafrica.co.za/api/schedule/programme/get_programme_options.php`,
    {
      employee_no: this.employee_no,

    },{ responseType: 'json'}).pipe()
    .subscribe(
      (data:any) => {
         
        for(var i=0;i<data[0].pgs.length;i++)
        {
            this.pgs[i] = {pg_id: data[0].pgs[i].pg_id,code: data[0].pgs[i].code,pg_name: data[0].pgs[i].pg_name};
        } 

        for(var i=0;i<data[0].user.length;i++)
        {
            this.users[i] = {name: data[0].user[i].name,
              bdcr_no: data[0].user[i].bdcr_no,
              paswd: data[0].user[i].paswd,
              email: data[0].user[i].email,
              role_id: data[0].user[i].role_id,
              atemps: data[0].user[i].atemps,
              pers_code: data[0].user[i].pers_code,
              accountType: data[0].user[i].accountType,
              phoneNumber: data[0].user[i].phoneNumber,
              status: data[0].user[i].status,
              surname: data[0].user[i].surname,
              analytics_delete: null,
              analytics_read: null,
              analytics_write:null,
              content_management_delete:null,
              content_management_read:null,
              content_management_write:null,
              content_sales_delete:null,
              content_sales_read:null,
              content_sales_write:null,
              elearning_delete:null,
              elearning_read:null,
              elearning_write:null,
              finance_delete:null,
              finance_read:null,
              finance_write:null,
              hr_delete:null,
              hr_read:null,
              hr_write:null,
              inventory_management_delete:null,
              inventory_management_read:null,
              inventory_management_write:null,
              market_place_delete:null,
              market_place_read:null,
              market_place_write:null,
              mat_man_delete:null,
              mat_man_read:null,
              mat_man_write:null,
              news_delete:null,
              news_read:null,
              news_write:null,
              production_services_delete:null,
              production_services_read:null,
              production_services_write:null,
              prog_delete:null,
              prog_read:null,
              prog_write:null,
              project_management_delete:null,
              project_management_read:null,
              project_management_write:null,
              revenue_management_delete:null,
              revenue_management_read:null,
              revenue_management_write:null,
              schedule_delete:null,
              schedule_read:null,
              schedule_write:null,
              web2app_delete:null,
              web2app_read:null,
              web2app_write:null};
        } 
      } 
    ) 
  }

  back(){
    this._location.back();
  }
}
