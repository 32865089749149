import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl,NgForm} from '@angular/forms';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {SelectionModel} from '@angular/cdk/collections';
import {MenuItem,TreeNode} from 'primeng/api';
import {TreeDragDropService} from 'primeng/api';
import {ScheduleClass} from '../../classes/schedule';
import { SeasonClass } from '../../classes/season';
import { EpisodeClass } from '../../classes/episode';
import {SareClass} from '../../classes/sare';
import {ProgrammeClass} from '../../classes/programme';
import { saveAs } from 'file-saver';
import {Location} from '@angular/common';
import * as $ from "jquery";

import { FullCalendarComponent } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';

//Import used to make HTTP get and post requests
import { HttpClient } from '@angular/common/http';
import { LoadingController } from '@ionic/angular';
import { finalize } from 'rxjs/operators';
 
//To use autcomplete field
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { NavController } from '@ionic/angular';

//Toasta
import {AlertService} from '../../services/alert/alert.service';
import { Router, ActivatedRoute } from '@angular/router';

import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MatDatepicker} from '@angular/material/datepicker';

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

import * as _moment from 'moment';
import {default as _rollupMoment, Moment} from 'moment';
import { FTPFiles } from 'src/app/classes/ftp.files';
const moment = _rollupMoment || _moment;

@Component({
  selector: 'app-imports',
  templateUrl: './imports.component.html',
  styleUrls: ['./imports.component.scss']
})
export class ImportsComponent implements OnInit {

  isLinear: true;
  selectedFile: FileList  = null;
  channels: SareClass[] = [];
  formData = new FormData();
  spotsFormGroup: FormGroup;
  asRunFormGroup: FormGroup;
  materialFormGroup: FormGroup;
  programmeFormGroup: FormGroup;
  scheduleFormGroup: FormGroup;

  abcdFormGroup: FormGroup;
  database: string = '';
  
  employee_no: number = 0;
  selected_channel:string = "1";
  chk_save = false;
  chk_vertical = false;
  chk_horizontal = false;
  chk_email = false;
  chk_export = false;
  chk_download = false;
  chk_importfromFTP = false;
  start_date: String = moment().format("YYYY-MM-DD");
  end_date: String = moment().format("YYYY-MM-DD");
  start_time: String = "00:00";
  end_time: String = "23:59";
  import_format: String = 'fullformat'; 
  blnOveride_schedule: boolean = false;

  prog_skipped: number = 0;
  prog_saved: number = 0;
  sea_skipped: number = 0;
  sea_saved: number = 0;
  epi_skipped: number = 0;
  epi_saved: number = 0;

  //FTP
  ftpHost = 'ftp.mmsafrica.co.za';
  ftpUsername = 'mojaftp@mmsafrica.co.za';
  ftpPassword = 'pfnmms#2022';
  //FTP Folders
  Asrun_folder = 'Ftp-In/As-run/';
  spots_folder = 'Ftp-In/Spots/';
  material_folder = 'Ftp-In/Material/';

  loading: string = "";
  list_files: FTPFiles[] = [];
  list_folders: FTPFiles[] = [];

  displayedColumns: string[] = ['select','file'];
  dataSource = new MatTableDataSource(this.list_files);
  selection = new SelectionModel<FTPFiles>(true, []);
 
  constructor(private alertService: AlertService,route:ActivatedRoute,private router: Router,private formBuilder: FormBuilder,private httpClient: HttpClient,private loadingCtrl: LoadingController,private navCtrl: NavController,public dialog: MatDialog,private _location: Location) 
  {
    this.employee_no = parseInt(JSON.parse(localStorage.getItem('employee_no')));

    this.onChannels();
  }

  ngOnInit() {

    this.programmeFormGroup = this.formBuilder.group({
      selected_channelCtrl: [this.selected_channel,Validators.required],
    });

    this.scheduleFormGroup = this.formBuilder.group({
      selected_channelCtrl: [this.selected_channel,Validators.required],
      import_formatCtrl: [this.import_format,Validators.required],
      blnOveride_scheduleCtrl: [this.blnOveride_schedule],
    });

    this.asRunFormGroup = this.formBuilder.group({
      chk_downloadCtrl: [this.chk_download,Validators.required],
      chk_emailCtrl: [this.chk_email,Validators.required],
      chk_exportCtrl: [this.chk_export,Validators.required],
      selected_channelCtrl: [this.selected_channel,Validators.required],
      start_dateCtrl: [this.start_date,Validators.required],
      end_dateCtrl: [this.end_date,Validators.required],
      start_timeCtrl: [this.start_time,Validators.required],
      end_timeCtrl: [this.end_time,Validators.required],
      chk_importfromFTPCtrl: [this.chk_importfromFTP,Validators.required],
    });

    this.materialFormGroup = this.formBuilder.group({
      chk_downloadCtrl: [this.chk_download],
      selected_channelCtrl: [this.selected_channel,Validators.required],
      chk_importfromFTPCtrl: [this.chk_importfromFTP],
    });

    this.abcdFormGroup = this.formBuilder.group({
      databaseCtrl: [this.database,Validators.required],
    });
    
    this.spotsFormGroup = this.formBuilder.group({
      chk_saveCtrl: [this.chk_save,Validators.required],
      chk_verticalCtrl: [this.chk_vertical,Validators.required],
      //chk_horizontalCtrl: [this.chk_horizontal,Validators.required],
      chk_emailCtrl: [this.chk_email,Validators.required],
      chk_exportCtrl: [this.chk_export,Validators.required],
      selected_channelCtrl: [this.selected_channel,Validators.required],
      start_dateCtrl: [this.start_date,Validators.required],
      //end_dateCtrl: [this.end_date,Validators.required],
      start_timeCtrl: [this.start_time,Validators.required],
      end_timeCtrl: [this.end_time,Validators.required],
      chk_importfromFTPCtrl: [this.chk_importfromFTP,Validators.required],
    });
  }

  ngAfterViewInit(){
    
    //File import
    $("form").on("change", ".file-upload-field", function(){ 
      $(this).parent(".file-upload-wrapper").attr("data-text",         $(this).val().replaceAll(/.*(\/|\\)/, '') );
    });

  }

   /** Whether the number of selected elements matches the total number of rows. */
   isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  } 

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: FTPFiles): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.idNo + 1}`;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }


  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;


  //Reading FTP files
  async onReadFTPFiles(dir){ 

    this.loading = 'wait';
      
    const response = this.httpClient.post(`https://mojalove.mmsafrica.co.za/api/filemanager/file_manager.php`,
    {
      employee_no: this.employee_no,
      dir: dir,
      ftpHost: this.ftpHost,
      ftpUsername: this.ftpUsername,
      ftpPassword: this.ftpPassword

    },{ responseType: 'json'}).pipe( )
    .subscribe(
      (data:any) => {
         
        this.list_files = [];
        this.list_folders = [];

        for(var i=0;i<data[0].list_files.length;i++)
        {
          this.list_files[i] = {check: false,doc_name: data[0].list_files[i],path: '',idNo: i};
        }

        for(var i=0;i<data[0].list_folders.length;i++)
        {
          this.list_folders[i] = {check: false,doc_name: data[0].list_folders[i],path: '',idNo: i};         
        }

        this.dataSource = new MatTableDataSource(this.list_files);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;

        this.loading = 'done';
      } 
    ) 
  }


  async importEpisodic(){
    
    if(this.selectedFile != null)
    {
      for (var x = 0; x < this.selectedFile.length; x++) {
        this.formData.append('fileToUpload[]',this.selectedFile[x],this.selectedFile[x].name);
      }
    }

    this.formData.append('employee_no',<any>this.employee_no);
    this.formData.append('selected_channel',<any>this.selected_channel);

    this.httpClient.post('https://mojalove.mmsafrica.co.za/api/schedule/schedule_files/import_episodic.php',this.formData,{ responseType: 'json'})
    .subscribe((res_data:any) => {
      

      if(res_data.message == 'success')
      {
        this.alertService.success('Schedule successfully imported !');
        
        this.prog_skipped = res_data.prog_skipped;
        this.prog_saved = res_data.prog_saved;
        this.sea_skipped = res_data.sea_skipped;
        this.sea_saved = res_data.sea_saved; 
        this.epi_skipped = res_data.epi_skipped;
        this.epi_saved = res_data.epi_saved;

        var files = res_data.download_files.split(";");

        for(var i=0;i<(files.length-1);i++)
        {
          this.export(files[i]).subscribe(data => saveAs(data, 'Skipped Report.xlsx')); 
        }
      }
      else
      {
        this.alertService.error(res_data.message);
      }

      this.formData = new FormData();

    });
  }

  async importSchedule(){
  
    
    if(this.selectedFile != null)
    {
      for (var x = 0; x < this.selectedFile.length; x++) {
        this.formData.append('fileToUpload[]',this.selectedFile[x],this.selectedFile[x].name);
      }
    }

    this.formData.append('employee_no',<any>this.employee_no);
    this.formData.append('selected_channel',<any>this.selected_channel);
    this.formData.append('import_format',<any>this.import_format);
    this.formData.append('blnOveride',<any>this.blnOveride_schedule);

    this.httpClient.post('https://mojalove.mmsafrica.co.za/api/schedule/schedule_files/import_schedule.php',this.formData,{ responseType: 'json'})
    .subscribe((res_data:any) => {
      
      if(res_data.message == 'success')
      {
        this.alertService.success('Schedule successfully imported !');
        
        this.prog_skipped = res_data.prog_skipped;
        this.prog_saved = res_data.prog_saved;
        this.sea_skipped = res_data.sea_skipped;
        this.sea_saved = res_data.sea_saved;
        this.epi_skipped = res_data.epi_skipped;
        this.epi_saved = res_data.epi_saved;

        var files = res_data.download_files.split(";");

        for(var i=0;i<(files.length-1);i++)
        {
          this.export(files[i]).subscribe(data => saveAs(data, 'Skipped Report.xlsx')); 
        }
      }
      else
      {
        this.alertService.error(res_data.message);
      }

      this.formData = new FormData();

    });
  }

  async importProgrammes(){
     
    if(this.selectedFile != null) 
    {
      for (var x = 0; x < this.selectedFile.length; x++) {
        this.formData.append('fileToUpload[]',this.selectedFile[x],this.selectedFile[x].name);
      }
    }

    this.formData.append('employee_no',<any>this.employee_no);
    this.formData.append('selected_channel',<any>this.selected_channel);

    this.httpClient.post('https://mojalove.mmsafrica.co.za/api/schedule/schedule_files/import_programme.php',this.formData,{ responseType: 'json'})
    .subscribe((res_data:any) => {
      

      if(res_data.message == 'success')
      {
        this.alertService.success('Programmes successfully imported !');

        this.prog_skipped = res_data.prog_skipped;
        this.prog_saved = res_data.prog_saved;
        this.sea_skipped = res_data.sea_skipped;
        this.sea_saved = res_data.sea_saved;
        this.epi_skipped = res_data.epi_skipped;
        this.epi_saved = res_data.epi_saved;

        var files = res_data.download_files.split(";");

        for(var i=0;i<(files.length-1);i++)
        {
          this.export(files[i]).subscribe(data => saveAs(data, 'Skipped Report.xlsx')); 
        }
      }
      else
      {
        this.alertService.error(res_data.message);
      }

      this.formData = new FormData();
    });
  }

  async importAsRun(){
    
    if(this.selectedFile != null)
    {
      for (var x = 0; x < this.selectedFile.length; x++) {
        this.formData.append('fileToUpload[]',this.selectedFile[x],this.selectedFile[x].name);
      }
    }
    
    var strstart_date = moment(this.start_date.toString()).format("YYYY/MM/DD");
    var strend_date = moment(this.end_date.toString()).format("YYYY/MM/DD");

    var strstart_time = this.start_time.toString()+":00";
    var strend_time = this.end_time.toString()+":00";

    this.formData.append('employee_no',<any>this.employee_no);
    this.formData.append('selected_channel',<any>this.selected_channel);
    this.formData.append('chk_download',<any>this.chk_download);
    this.formData.append('chk_email',<any>this.chk_email);
    this.formData.append('chk_export',<any>this.chk_export);
    this.formData.append('start_date',<any>strstart_date);
    this.formData.append('end_date',<any>strend_date);
    this.formData.append('start_time',<any>strstart_time);
    this.formData.append('end_time',<any>strend_time);
    this.formData.append('chk_importfromFTP',<any>this.chk_importfromFTP);
    this.formData.append('ftpHost',<any>this.ftpHost);
    this.formData.append('ftpUsername',<any>this.ftpUsername);
    this.formData.append('ftpPassword',<any>this.ftpPassword);

    for (let file of this.selection.selected) {
      this.formData.append('ftpFiles[]', file['doc_name']);
    }

    this.httpClient.post('https://mojalove.mmsafrica.co.za/api/schedule/schedule_files/import_asrun.php',this.formData,{ responseType: 'json'})
    .subscribe((res_data:any) => {
      
      if(res_data.message == 'success')
      {
        this.alertService.success('As-Run successfully saved');
        var files = res_data.download_files.split(";");

        for(var i=0;i<(files.length-1);i++)
        {
          var strName = files[i];
          this.export(strName).subscribe(data2 => saveAs(data2, 'As-Run Export.xlsx'));
        }
 
      }
      else
      {
        this.alertService.error(res_data.message);
      }

      this.formData = new FormData();

    });
  }

  async importABCD(){
    
    if(this.selectedFile != null)
    {
      for (var x = 0; x < this.selectedFile.length; x++) {
        this.formData.append('fileToUpload[]',this.selectedFile[x],this.selectedFile[x].name);
      }
    }

    this.formData.append('employee_no',<any>this.employee_no);
    this.formData.append('database',<any>this.database);
   
    this.httpClient.post('https://mojalove.mmsafrica.co.za/api/schedule/material/import_abcd_material.php',this.formData,{ responseType: 'json'})
    .subscribe((res_data:any) => {
      
      if(res_data.message == 'success')
      {
        this.alertService.success('Material successfully saved');
 
      }
      else
      {
        this.alertService.error(res_data.message);
      }

      this.formData = new FormData();

    });
  }

  async importMaterial(){
    
    if(this.selectedFile != null)
    {
      for (var x = 0; x < this.selectedFile.length; x++) {
        this.formData.append('fileToUpload[]',this.selectedFile[x],this.selectedFile[x].name);
      }
    }

    this.formData.append('employee_no',<any>this.employee_no);
    this.formData.append('selected_channel',<any>this.selected_channel);
    this.formData.append('chk_download',<any>this.chk_download);
    this.formData.append('chk_importfromFTP',<any>this.chk_importfromFTP);
    this.formData.append('ftpHost',<any>this.ftpHost);
    this.formData.append('ftpUsername',<any>this.ftpUsername);
    this.formData.append('ftpPassword',<any>this.ftpPassword);

    for (let file of this.selection.selected) {
      this.formData.append('ftpFiles[]', file['doc_name']);
    }

    this.httpClient.post('https://mojalove.mmsafrica.co.za/api/schedule/material/import_material.php',this.formData,{ responseType: 'json'})
    .subscribe((res_data:any) => {
      
      if(res_data.message == 'success')
      {
        this.alertService.success('Material successfully saved');
        var files = res_data.download_files.split(";");

        for(var i=0;i<(files.length-1);i++)
        {
          var strName = files[i];
          this.export(strName).subscribe(data2 => saveAs(data2, 'Material Export.xlsx'));
        }
 
      }
      else
      {
        this.alertService.error(res_data.message);
      }

      this.formData = new FormData();

    });
  }

  async importSpots(){   
    
    if(this.selectedFile != null)
    {
      for (var x = 0; x < this.selectedFile.length; x++) {
        this.formData.append('fileToUpload[]',this.selectedFile[x],this.selectedFile[x].name);
      }
    }
    
    var strstart_date = moment(this.start_date.toString()).format("YYYY/MM/DD");
    //var strend_date = moment(this.end_date.toString()).format("YYYY/MM/DD");

    var strstart_time = this.start_time.toString()+":00";
    var strend_time = this.end_time.toString()+":00";

    
    this.formData.append('employee_no',<any>this.employee_no);
    this.formData.append('selected_channel',<any>this.selected_channel);
    this.formData.append('chk_save',<any>this.chk_save);
    this.formData.append('chk_vertical',<any>this.chk_vertical);
    //this.formData.append('chk_horizontal',<any>this.chk_horizontal);
    this.formData.append('chk_email',<any>this.chk_email);
    this.formData.append('chk_export',<any>this.chk_export);
    this.formData.append('start_date',<any>strstart_date);
    //this.formData.append('end_date',<any>strend_date);
    this.formData.append('start_time',<any>strstart_time);
    this.formData.append('end_time',<any>strend_time);
    this.formData.append('chk_importfromFTP',<any>this.chk_importfromFTP);
    this.formData.append('ftpHost',<any>this.ftpHost);
    this.formData.append('ftpUsername',<any>this.ftpUsername);
    this.formData.append('ftpPassword',<any>this.ftpPassword);

    for (let file of this.selection.selected) {
      this.formData.append('ftpFiles[]', file['doc_name']);
    }

    //https://mojalove.mmsafrica.co.za/api/schedule/schedule_files/import_spots.php
    this.httpClient.post('https://mojalove.mmsafrica.co.za/api/schedule/schedule_files/ad_tracking_schedule.php',this.formData,{ responseType: 'json'})
    .subscribe((data:any) => {

      if(data.message == 'success')
      {
        this.alertService.success('Spots successfully saved');
    
        var files = data.download_files.split(";");

        for(var i=0;i<(files.length-1);i++)
        {
          this.export(files[i]).subscribe(data => saveAs(data, 'Ad Tracking Schedule.xlsx')); 
        }
 
      }
      else
      {
        this.alertService.error(data.message);
      }

      this.formData = new FormData();

    });
  }


  export(name) {
    return this.httpClient.get(`https://mojalove.mmsafrica.co.za/api/schedule/schedule_files/download_file.php?file_name=${name}`, 
      {responseType: 'blob'});
  }

  onChangeInportType(dir){
    if(this.chk_importfromFTP){this.onReadFTPFiles(dir);}
  }

  onTabChanged(event){

    let clickedIndex = event.index;
    this.selectedFile  = null;
    this.chk_save = false;
    this.chk_vertical = false;
    this.chk_horizontal = false;
    this.chk_email = false;
    this.chk_export = false;
    this.chk_download = false;
    this.blnOveride_schedule = false;
    this.prog_skipped = 0;
    this.prog_saved = 0;
    this.sea_skipped = 0;
    this.sea_saved = 0;
    this.epi_skipped = 0;
    this.epi_saved = 0;

    if(clickedIndex == 0){ 

      //File import
      $("form").on("change", ".file-upload-field", function(){ 
        $(this).parent(".file-upload-wrapper").attr("data-text",         $(this).val().replaceAll(/.*(\/|\\)/, '') );
      });

      if(this.chk_importfromFTP){this.onReadFTPFiles(this.spots_folder);}
    }
    else
    if(clickedIndex == 1){ 

      //File import
      $("form").on("change", ".file-upload-field1", function(){ 
        $(this).parent(".file-upload-wrapper").attr("data-text",         $(this).val().replaceAll(/.*(\/|\\)/, '') );
      });

      if(this.chk_importfromFTP){this.onReadFTPFiles(this.Asrun_folder);}
    }
    else
    if(clickedIndex == 2){

      //File import
      $("form").on("change", ".file-upload-field4", function(){ 
        $(this).parent(".file-upload-wrapper").attr("data-text",         $(this).val().replaceAll(/.*(\/|\\)/, '') );
      });

      if(this.chk_importfromFTP){this.onReadFTPFiles(this.material_folder);}
    }
    else
    if(clickedIndex == 3){ 

      //File import
      $("form").on("change", ".file-upload-field2", function(){ 
        $(this).parent(".file-upload-wrapper").attr("data-text",         $(this).val().replaceAll(/.*(\/|\\)/, '') );
      });
    }
    else
    if(clickedIndex == 4){ 

      //File import
      $("form").on("change", ".file-upload-field3", function(){ 
        $(this).parent(".file-upload-wrapper").attr("data-text",         $(this).val().replaceAll(/.*(\/|\\)/, '') );
      });
    }
    else
    if(clickedIndex == 4){ 

      //File import
      $("form").on("change", ".file-upload-field4", function(){ 
        $(this).parent(".file-upload-wrapper").attr("data-text",         $(this).val().replaceAll(/.*(\/|\\)/, '') );
      });
    }
  }

  onFileSelected(event){
    
    this.selectedFile  = null;
    this.selectedFile = <FileList>event.target.files;
  }

  async onChannels(){ 
      
    const response = this.httpClient.post(`https://mojalove.mmsafrica.co.za/api/schedule/get_channels.php`,
    {
      channes: this.selected_channel,
      date: moment().format("YYYY/MM/DD"),
      employee_no: this.employee_no,

    },{ responseType: 'json'}).pipe( )
    .subscribe(
      (data:any) => {
         
        for(var i=0;i<data.length;i++)
        {
            this.channels[i] = {bdcr_no: data[i].bdcr_no,genre: data[i].genre,platform: data[i].platform,sare_name: data[i].sare_name,sare_no: data[i].sare_no};
        }

      } 
    ) 
  }

  back(){
    this._location.back();
  }
}
