import { Component, OnInit, ViewChild } from '@angular/core';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import { FormGroup, FormBuilder, Validators, FormControl,NgForm} from '@angular/forms';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {SelectionModel} from '@angular/cdk/collections';
import {MenuItem,TreeNode} from 'primeng/api';
import {TreeDragDropService} from 'primeng/api';
import { SeasonClass } from '../../classes/season';
import { EpisodeClass } from '../../classes/episode';
import {CampaignSpotModel} from '../../models/campaign-spot.model';
import {SareClass} from '../../classes/sare';
import {BreakClass} from '../../classes/breaks';
import {Location} from '@angular/common';
 
//Import used to make HTTP get and post requests
import { HttpClient } from '@angular/common/http';
import { LoadingController } from '@ionic/angular';
import { finalize } from 'rxjs/operators';
 
//To use autcomplete field
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { NavController } from '@ionic/angular';

//Toasta
import {AlertService} from '../../services/alert/alert.service';
import { Router, ActivatedRoute } from '@angular/router';

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

import * as _moment from 'moment';
import {default as _rollupMoment, Moment} from 'moment';
import { AddSpotDialogComponent } from '../add-spot-dialog/add-spot-dialog.component';
import { CampaignSpotService } from 'src/app/services/campaignSpot/campaign-spot.service';
const moment = _rollupMoment || _moment;

@Component({
  selector: 'app-breaks',
  templateUrl: './breaks.component.html',
  styleUrls: ['./breaks.component.scss'],
})
export class BreaksComponent implements OnInit {

  selectedFile: FileList  = null;
  formData = new FormData();
  reconFormGroup: FormGroup;
  employee_no: number = 0;
  selected_channel; 

  selected_channels: number[] = [];
  channels: SareClass[] = [];

  start_date: String = moment().format("YYYY-MM-DD");
  end_date: String = moment().format("YYYY-MM-DD");
  start_time: String = "00:00";
  end_time: String = "23:59";

  display_array: BreakClass[] = [];
  displayedColumns: string[] = ['select','date','start_time', 'availability','programme','break_type','topDemographic','ratting','rch','breakPrice'];
  dataSource = new MatTableDataSource(this.display_array);
  selection = new SelectionModel<BreakClass>(true, []);

  //Book spots
  campaignSpots: CampaignSpotModel[];
  campaign: any;

  constructor(private alertService: AlertService,route:ActivatedRoute,private router: Router,private formBuilder: FormBuilder,private httpClient: HttpClient,private loadingCtrl: LoadingController,private navCtrl: NavController,public dialog: MatDialog,private _location: Location,private campaignSpotService: CampaignSpotService) 
  {
    this.employee_no = parseInt(JSON.parse(localStorage.getItem('employee_no')));
    this.onChannels();
  }

  ngOnInit() {

    this.reconFormGroup = this.formBuilder.group({
      selected_channelCtrl: [this.selected_channel,Validators.required],
      start_dateCtrl: [this.start_date,Validators.required],
      end_dateCtrl: [this.end_date,Validators.required],
      start_timeCtrl: [this.start_time,Validators.required],
      end_timeCtrl: [this.end_time,Validators.required],
    });

  }


  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  } 

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: BreakClass): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.break_id + 1}`;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }


  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;


  openAddDialog(): void {
    const dialogRef = this.dialog.open(AddSpotDialogComponent, {
      width: '600px',
      data: new CampaignSpotModel()
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        result.campaignId = this.campaign.campaignId;
        this.campaignSpots.push(result);

        /*this.dataSource.data = this.campaignSpots;
        this.table.renderRows(); */

        this.campaignSpotService.addSpot(result).subscribe(success =>
          this.alertService.success('Spots successfully added'), error => this.alertService.error('Spot not saved'));
      }
    });
  }


  async onReadBreak(){ 

    var strstart_date = moment(this.start_date.toString()).format("YYYY/MM/DD");
    var strend_date = moment(this.end_date.toString()).format("YYYY/MM/DD");
    var strstart_time = this.start_time.toString()+":00";
    var strend_time = this.end_time.toString()+":00";
    this.selected_channels[0] = this.selected_channel;

    const response = this.httpClient.post(`https://mojalove.mmsafrica.co.za/api/schedule/transmission/read_breaks.php`,
    {
      channes: this.selected_channels[0],
      sDate: strstart_date,
      eDate: strend_date,
      end_time: strend_time,
      start_time: strstart_time,
      employee_no: this.employee_no,

    },{ responseType: 'json'}).pipe()
    .subscribe(
      (data:any) => {
         
        this.display_array = [];
        
        for(var i=0;i<data.length;i++)
        {
          this.display_array[i] = {actual_value: data[i].actual_value,availability: data[i].availability,break_date: data[i].break_date,break_id: data[i].break_id,break_target: data[i].break_target,break_type: data[i].break_type,end_time: data[i].end_time,episode: data[i].episode,exhibition: data[i].exhibition,length: data[i].length,prog_no: data[i].prog_no,rcrd_no: data[i].rcrd_no,run_no: data[i].run_no,sare_name: data[i].sare_name,sare_no: data[i].sare_no,season: data[i].season,start_time: data[i].start_time,prog_name: data[i].prog_name,breakPrice: Number(data[i].breakPrice).toFixed(2),ratting: Number(data[i].ratting),topDemographic: data[i].topDemographic,daily_rch: Number(data[i].daily_rch)};
        }

        this.dataSource = new MatTableDataSource(this.display_array);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      } 
    ) 
  }


  async onChannels(){ 
      
    const response = this.httpClient.post(`https://mojalove.mmsafrica.co.za/api/schedule/get_channels.php`,
    {
      channes: this.selected_channel,
      date: moment().format("YYYY/MM/DD"),
      employee_no: this.employee_no,

    },{ responseType: 'json'}).pipe()
    .subscribe(
      (data:any) => {
         
        for(var i=0;i<data.length;i++)
        {
            this.channels[i] = {bdcr_no: data[i].bdcr_no,genre: data[i].genre,platform: data[i].platform,sare_name: data[i].sare_name,sare_no: data[i].sare_no};

            this.selected_channel = data[i].sare_no;
        }

        this.onReadBreak();
      } 
    ) 
  }

  fixedSpots() {
    this.router.navigate(['home/createRateCardPackage']);
  }

  back(){
    this._location.back();
  }
 
}