import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from 'src/app/services/alert/alert.service';
import {Location} from '@angular/common';
import * as $ from "jquery";

import { DomSanitizer } from '@angular/platform-browser';
import { ProgrammeTV } from 'src/app/classes/abcd.programmetv';
import { LanguageClass } from 'src/app/classes/language';
import { SareClass } from 'src/app/classes/sare';
import { GenreClass } from 'src/app/classes/genre';
import { ContractListClass } from 'src/app/classes/contractlist';
import { PGClass } from 'src/app/classes/parentalguidance';
import { UserClass } from 'src/app/classes/users';
import { CategoryClass } from 'src/app/classes/categories';
import { SubGenreClass } from 'src/app/classes/subgenre';
import { RegionClass } from 'src/app/classes/regions';
import { DistributorClass } from 'src/app/classes/distributor';
import { Demographics } from 'src/app/classes/demographics.class';

import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MatDatepicker} from '@angular/material/datepicker';

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD-MMM-YYYY',
  },
  display: {
    dateInput: 'DD-MMM-YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

import * as _moment from 'moment';
import {default as _rollupMoment, Moment} from 'moment';
import { ConfirmationDialogComponent } from '../../confirmation-dialog/confirmation-dialog.component';
import { RecordType } from '../new-abcd-material/new-abcd-material.component';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { AddParticipantsComponent } from '../add-participants/add-participants.component';
const moment = _rollupMoment || _moment;

@Component({ 
  selector: 'app-view-abcd-material',
  templateUrl: './view-abcd-material.component.html',
  styleUrls: ['./view-abcd-material.component.scss'],
  providers: [
    { 
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
  
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class ViewAbcdMaterialComponent implements OnInit {

  //Options
  materialID: number = 0;
  channels: SareClass[] = [];
  genres: GenreClass[] = []; 
  languages: LanguageClass[] = [];
  contractTypes: ContractListClass[] = [];
  pgs: PGClass[] = [];
  users: UserClass[] = [];
  categories: CategoryClass[] = []; 
  subgenries: SubGenreClass[] = []; 
  selected_subgenries: SubGenreClass[] = [];
  regions: RegionClass[] = [];
  type_of_rights: CategoryClass[] = [];
  media_types: CategoryClass[] = [];
  distributors_array: DistributorClass[] = [];
  demographics_array: Demographics[] = [];
  //End of options


  abcd_database: string = "";
  employee_no: number = 0;
  materialFormGroup: FormGroup;
  material: any  = {};
  //material: ProgrammeTV  = new ProgrammeTV();

  formatOptions: any[] = [{label: 'Betacam', value: 'Betacam'}, {label: 'DVCPRO', value: 'DVCPRO'}, {label: 'DVCAM', value: 'DVCAM'}, {label: 'MiniDV', value: 'MiniDV'}, {label: 'One-inch', value: 'One-inch'}, {label: 'U-matic', value: 'U-matic'}, {label: 'VHS', value: 'VHS'}];

  format_soundOptions: any[] = [{label: 'Compact flash', value: 'Compact flash'}, {label: 'Minidisc', value: 'Minidisc'}, {label: 'Tape', value: 'Tape'}, {label: 'Cassette', value: 'Cassette'}];

  statusOptions: any[] = [{label: 'Available', value: 'Available'}, {label: 'Missing', value: 'Missing'}, {label: 'Damaged', value: 'Damaged'}, {label: 'Lost', value: 'Lost'}];

  dubStatusOptions: any[] = [{label: 'Available', value: 'Available'}, {label: 'Moved to Prog', value: 'Moved to Prog'}, {label: 'Missing', value: 'Missing'}, {label: 'Lost', value: 'Lost'}, {label: 'Damaged', value: 'Damaged'}];

  temparc_statusOptions: any[] = [{label: 'Available', value: 'Available'}, {label: 'Missing', value: 'Missing'}, {label: 'Discarded', value: 'Discarded'}];

  locationOptions: any[] = [{label: 'Programme Archive', value: 'Programme Archive'}, {label: 'News Archive', value: 'News Archive'}, {label: 'National Archives', value: 'National Archives'}];

  typeOfRecordOptions: any[] = [{label: 'Book', value: 'Book'}, {label: 'Multimedia', value: 'Multimedia'}, {label: 'Cassette', value: 'Cassette'}, {label: 'Compact Disk', value: 'Compact Disk'}, {label: 'CD-ROM', value: 'CD-ROM'}, {label: 'Sheet music', value: 'Sheet music'}, {label: 'Video', value: 'Video'}];

  modeOfAcquisitionOptions: any[] = [{label: 'Donation', value: 'Donation'}, {label: 'Purchase', value: 'Purchase'}];

  origin_of_sourceOptions: any[] = [{label: 'Reference Library', value: 'Reference Library'}, {label: 'Internet', value: 'Internet'},{label: 'Radio Archive', value: 'Radio Archive'}, {label: 'Video Library', value: 'Video Library'},{label: 'TV Archive', value: 'TV Archive'}];

  language_of_sourceOptions: any[] = [{label: 'English', value: 'English'}, {label: 'Afrikaans', value: 'Afrikaans'},{label: 'German', value: 'German'}, {label: 'Otjiherero', value: 'Otjiherero'},{label: 'Khoekhoegowab', value: 'Khoekhoegowab'},{label: 'Oshiwambo', value: 'Oshiwambo'}, {label: 'Silozi', value: 'Silozi'},{label: 'Rukavango', value: 'Rukavango'}, {label: 'Setswana', value: 'Setswana'}];
  
  shelf_number_locationOptions: any[] = [{label: 'NBC clippings collection', value: 'NBC clippings collection'}, {label: 'In-house newsletter', value: 'In-house newsletter'},{label: 'NBC Annual Reports cabinet', value: 'NBC Annual Reports cabinet'}];

  genderOptions: any[] = [{label: 'Female', value: 'Female'}, {label: 'Male', value: 'Male'}];

  migrateOptions: any[] = [{label: 'Yes', value: 'Yes'}, {label: 'No', value: 'No'}];

  departmentOptions: any[] = [{label: 'DG', value: 'DG'}, {label: 'Commecial Services', value: 'Commecial Services'},{label: 'Finance', value: 'Finance'},{label: 'Human Recources and Administration', value: 'Human Recources and Administration'},{label: 'News and Programming', value: 'News and Programming'},{label: 'Technology', value: 'Technology'}];

  //Lists
  services_array: any[] = []; 
  moods_array: any[] = []; 
  advert_categorys_array: any[] = []; 

  //series Auto complete
  series_array: any[] = []; 
  myControl = new FormControl(); 
  filteredOptions: Observable<any[]>;

  //Name Auto complete 
  lastFilter: string = '';
  selectedFile: FileList  = null;
  

  //Selected Names list 
  participants_array: any[] = [];
  participantsControl = new FormControl(); 
  selectedParticipants: any[] = new Array<any>();
  participantsilteredOptions: Observable<any[]>;

  speakers_array: any[] = [];
  speakersControl = new FormControl(); 
  selectedSpeakers: any[] = new Array<any>();
  speakersfilteredOptions: Observable<any[]>;

  interviewees_array: any[] = [];
  intervieweesControl = new FormControl(); 
  selectedInterviewees: any[] = new Array<any>();
  intervieweesfilteredOptions: Observable<any[]>;

  names_array: any[] = [];
  namesControl = new FormControl(); 
  selectedNames: any[] = new Array<any>();
  namesfilteredOptions: Observable<any[]>;

  specialNames_array: any[] = [];
  specialNamesControl = new FormControl(); 
  selectedSpecialNames: any[] = new Array<any>();
  specialNamesfilteredOptions: Observable<any[]>;

  referredTo_array: any[] = [];
  referredToControl = new FormControl(); 
  selectedReferredTo: any[] = new Array<any>();
  referredTofilteredOptions: Observable<any[]>;

  performers_array: any[] = [];
  performersControl = new FormControl(); 
  selectedPerformers: any[] = new Array<any>();
  performersfilteredOptions: Observable<any[]>;

  cast_array: any[] = [];
  castControl = new FormControl(); 
  selectedCast: any[] = new Array<any>();
  castfilteredOptions: Observable<any[]>;

  contestants_array: any[] = [];
  contestantsControl = new FormControl(); 
  selectedContestants: any[] = new Array<any>();
  contestantsfilteredOptions: Observable<any[]>;

  record_types: RecordType[] = [
    {name: 'Cover info', color: 'primary',key: 'cover'},
    {name: 'Track info', color: 'primary',key: 'track'},
    {name: 'Classic cover', color: 'accent',key: 'classic'},
    {name: 'Classic Track', color: 'warn',key: 'ctrack'},
  ];

  musicrecord_types: RecordType[] = [
    {name: 'Cover info', color: 'primary',key: 'C'},
    {name: 'Track info', color: 'primary',key: 'X'},
  ];

  constructor(private alertService: AlertService,route:ActivatedRoute,private router: Router,private formBuilder: FormBuilder,private httpClient: HttpClient,public dialog: MatDialog,private _location: Location,private sanitizer: DomSanitizer) { 

    this.materialID = parseInt(JSON.parse(localStorage.getItem('materialID')));
    this.abcd_database = localStorage.getItem('abcd_database');

    this.onReadABCDOptions();
    this.onReadABCDMaterials();
  }

  ngOnInit(): void {

    //this.material.tape_format = {label: 'Betacam', value: 'Betacam'};
    if(this.abcd_database != 'Videos Foreign Material'){this.material.status = {label: 'Available', value: 'Available'};}
    this.material.location_of_tape = {label: 'Programme Archive', value: 'Programme Archive'};
    if(this.abcd_database == 'Books'){this.material.mode_of_acquisition = {label: 'Purchase', value: 'Purchase'};}
    if(this.abcd_database != 'Videos Foreign Material'){this.material.type_of_record = {label: 'Book', value: 'Book'};}
    this.material.origin_of_source = {label: 'Reference Library', value: 'Reference Library'};
    this.material.language_of_source = {label: 'English', value: 'English'};
    this.material.department = {label: 'DG', value: 'DG'};
    this.material.gender = {label: 'Female', value: 'Female'};
    this.material.language_of_work = {label: 'English', value: 'English'};
    this.material.migrate = {label: 'No', value: 'No'};
    this.material.record_category = 'Cover info';

    this.materialFormGroup = this.formBuilder.group({
      //Programme TV
      tape_numberCtrl: [this.material.tape_number],
      tape_formatCtrl: [this.material.tape_format],
      statusCtrl: [this.material.status],
      location_of_tapeCtrl: [this.material.location_of_tape],
      language_of_itemCtrl: [this.material.language_of_item],
      series_titleCtrl: [this.material.series_title],
      introductionCtrl: [this.material.introduction],
      shotlistCtrl: [this.material.shotlist],
      broadcast_dateCtrl: [this.material.broadcast_date],
      re_broadcast_dateCtrl: [this.material.re_broadcast_date],
      durationCtrl: [this.material.duration],
      distributionCtrl: [this.material.distribution],
      physical_descriptionCtrl: [this.material.physical_description],
      notesCtrl: [this.material.notes],
      credits_notesCtrl: [this.material.credits_notes],
      pronunciationCtrl: [this.material.pronunciation],
      note_on_intended_audienceCtrl: [this.material.note_on_intended_audience],
      descriptive_infoCtrl: [this.material.descriptive_info],
      title_responsibilityCtrl: [this.material.title_responsibility],
      seriesCtrl: [this.material.series], 
      related_termsCtrl: [this.material.related_terms],
      participantsCtrl: [this.material.participants],
      speakersCtrl: [this.material.speakers],
      intervieweesCtrl: [this.material.interviewees],
      namesCtrl: [this.material.names],
      special_namesCtrl: [this.material.special_names],
      referred_toCtrl: [this.material.referred_to],
      performersCtrl: [this.material.performers],
      castCtrl: [this.material.cast],
      contestantsCtrl: [this.material.contestants],
      keywordsCtrl: [this.material.keywords],
      controlled_subjectsCtrl: [this.material.controlled_subjects],
      uncontrolled_subjectsCtrl: [this.material.uncontrolled_subjects],
      personal_name_as_subjectCtrl: [this.material.personal_name_as_subject],
      corporate_body_as_subjectCtrl: [this.material.corporate_body_as_subject],
      family_name_as_subjectCtrl: [this.material.family_name_as_subject],
      title_used_as_subjectCtrl: [this.material.title_used_as_subject],
      geographical_name_as_subjectCtrl: [this.material.geographical_name_as_subject],
      programme_categoryCtrl: [this.material.programme_category],
      presenterCtrl: [this.material.presenter],
      reporterCtrl: [this.material.reporter],
      producerCtrl: [this.material.producer],
      executive_producerCtrl: [this.material.executive_producer],
      senior_producerCtrl: [this.material.senior_producer],
      directorCtrl: [this.material.director],
      editorCtrl: [this.material.editor],
      translatorCtrl: [this.material.translator],
      cameraCtrl: [this.material.camera],
      soundCtrl: [this.material.sound],
      narratorCtrl: [this.material.narrator],
      commentatorCtrl: [this.material.commentator],
      interpreterCtrl: [this.material.interpreter],
      scriptwriterCtrl: [this.material.scriptwriter],
      productionCtrl: [this.material.production],
      date_receivedCtrl: [this.material.date_received],
      date_of_inputCtrl: [this.material.date_of_input],
      cataloguerCtrl: [this.material.cataloguer],
      data_captured_byCtrl: [this.material.data_captured_by],
      first_controlCtrl: [this.material.first_control],
      final_controlCtrl: [this.material.final_control],
      update_editedCtrl: [this.material.update_edited],
      stocktakingCtrl: [this.material.stocktaking],
      date_written_offCtrl: [this.material.date_written_off],
      barcodeCtrl: [this.material.barcode],
      control_noCtrl: [this.material.control_no],


      //Books
      titleCtrl: [this.material.title],
      edition_areaCtrl: [this.material.edition_area],
      publication_areaCtrl: [this.material.publication_area],
      series_areaCtrl: [this.material.series_area],
      general_noteCtrl: [this.material.general_note],
      contents_noteCtrl: [this.material.contents_note],
      summary_abstractCtrl: [this.material.summary_abstract],
      notes_on_identification_numberCtrl: [this.material.notes_on_identification_number],
      notes_on_coded_informationCtrl: [this.material.notes_on_coded_information],
      notes_descriptive_informationCtrl: [this.material.notes_descriptive_information],
      notes_title_and_responsibilityCtrl: [this.material.notes_title_and_responsibility],
      notes_ed_and_bibliographic_hisCtrl: [this.material.notes_ed_and_bibliographic_his],
      notes_public_distributionCtrl: [this.material.notes_public_distribution],
      notes_physical_descriptionCtrl: [this.material.notes_physical_description],
      notes_binding_availabilityCtrl: [this.material.notes_binding_availability],
      notes_linking_fieldsCtrl: [this.material.notes_linking_fields],
      notes_related_titlesCtrl: [this.material.notes_related_titles],
      notes_subject_accessCtrl: [this.material.notes_subject_access],
      notes_intellectual_responsibCtrl: [this.material.notes_intellectual_responsib],
      notes_type_of_publicationCtrl: [this.material.notes_type_of_publication],
      notes_donor_of_publicationCtrl: [this.material.notes_donor_of_publication],
      notes_Internal_bibliog_indexCtrl: [this.material.notes_Internal_bibliog_index],
      notes_ext_indexes_abstractsCtrl: [this.material.notes_ext_indexes_abstracts],
      credits_note_for_audiovisualCtrl: [this.material.credits_note_for_audiovisual],
      cast_noteCtrl: [this.material.cast_note],
      facsimile_noteCtrl: [this.material.facsimile_note],
      dissertation_thesis_noteCtrl: [this.material.dissertation_thesis_note],
      techn_details_for_computer_fiCtrl: [this.material.techn_details_for_computer_fi],
      acquisition_information_noteCtrl: [this.material.acquisition_information_note],
      uniform_titleCtrl: [this.material.uniform_title],
      collective_uniform_titleCtrl: [this.material.collective_uniform_title],
      parallel_title_properCtrl: [this.material.parallel_title_proper],
      cover_titleCtrl: [this.material.cover_title],
      added_title_page_titleCtrl: [this.material.added_title_page_title],
      caption_titleCtrl: [this.material.caption_title],
      running_titleCtrl: [this.material.running_title],
      spine_titleCtrl: [this.material.spine_title],
      other_variant_titlesCtrl: [this.material.other_variant_titles],
      former_title_serialsCtrl: [this.material.former_title_serials],
      key_title_serialsCtrl: [this.material.key_title_serials],
      abbreviated_title_serialsCtrl: [this.material.abbreviated_title_serials],
      expanded_titleCtrl: [this.material.expanded_title],
      additional_title_by_cataloguerCtrl: [this.material.additional_title_by_cataloguer],
      transl_title_by_cataloguerCtrl: [this.material.transl_title_by_cataloguer],
      subjectsCtrl: [this.material.subjects],
      name_of_author_and_title_used_as_subjectCtrl: [this.material.name_of_author_and_title_used_as_subject],
      corporate_name_as_subjectCtrl: [this.material.corporate_name_as_subject],
      ddcCtrl: [this.material.ddc],
      broad_subject_categoryCtrl: [this.material.broad_subject_category],
      personal_authorCtrl: [this.material.personal_author],
      secondary_authorsCtrl: [this.material.secondary_authors],
      organisation_as_authorCtrl: [this.material.organisation_as_author],
      secondary_corporate_authorsCtrl: [this.material.secondary_corporate_authors],
      family_nameCtrl: [this.material.family_name],
      isbnCtrl: [this.material.isbn],
      issnCtrl: [this.material.issn],
      languageCtrl: [this.material.language],
      country_of_publicationCtrl: [this.material.country_of_publication],
      shelf_numberCtrl: [this.material.shelf_number],
      accession_numberCtrl: [this.material.accession_number],
      type_of_recordCtrl: [this.material.type_of_record],
      mode_of_acquisitionCtrl: [this.material.mode_of_acquisition],
      price_valueCtrl: [this.material.price_value],
      division_ordered_forCtrl: [this.material.division_ordered_for],
      number_of_copies_to_orderCtrl: [this.material.number_of_copies_to_order],
      internal_requisitionCtrl: [this.material.internal_requisition],
      book_orderCtrl: [this.material.book_order],
      invoiceCtrl: [this.material.invoice],
      supplierCtrl: [this.material.supplier],
      updaterCtrl: [this.material.updater],

      //Biographies
      birthCtrl: [this.material.birth],
      deathCtrl: [this.material.death],
      person_as_subjectCtrl: [this.material.person_as_subject],
      profileCtrl: [this.material.profile],
      print_sourceCtrl: [this.material.print_source],
      shelf_number_locationCtrl: [this.material.shelf_number_location],
      electronic_sourceCtrl: [this.material.electronic_source],
      audio_visual_sourceCtrl: [this.material.audio_visual_source],
      origin_of_sourceCtrl: [this.material.origin_of_source],
      language_of_sourceCtrl: [this.material.language_of_source],
      name_and_title_as_subjectCtrl: [this.material.name_and_title_as_subject],
      data_entered_byCtrl: [this.material.data_entered_by],
      input_dateCtrl: [this.material.input_date],


      //NBC History
      dateCtrl: [this.material.date],
      date_2Ctrl: [this.material.date_2],
      description_of_eventCtrl: [this.material.description_of_event],
      additional_info_on_eventCtrl: [this.material.additional_info_on_event],
      corp_body_as_subjectCtrl: [this.material.corp_body_as_subject],
      geogr_name_as_subjectCtrl: [this.material.geogr_name_as_subject],
      controlCtrl: [this.material.control],

      //NBC History
      info_on_eventCtrl: [this.material.info_on_event],
      additional_infoCtrl: [this.material.additional_info],
      printed_sourceCtrl: [this.material.printed_source],
      info_for_processingCtrl: [this.material.info_for_processing],

      //NBC History
      brief_description_of_eventCtrl: [this.material.brief_description_of_event],

      //User loan
      user_bar_codeCtrl: [this.material.user_bar_code],
      user_typeCtrl: [this.material.user_type],
      expiration_dateCtrl: [this.material.expiration_date],
      iso_expiration_dateCtrl: [this.material.iso_expiration_date],
      nameCtrl: [this.material.name],
      genderCtrl: [this.material.gender],
      designation_job_titleCtrl: [this.material.designation_job_title],
      departmentCtrl: [this.material.department],
      division_and_sectionCtrl: [this.material.division_and_section],
      building_locationCtrl: [this.material.building_location],
      personnel_noCtrl: [this.material.personnel_no],
      telephone_workCtrl: [this.material.telephone_work],
      telephone_homeCtrl: [this.material.telephone_home],
      cell_phoneCtrl: [this.material.cell_phone],
      faxCtrl: [this.material.fax],
      emailCtrl: [this.material.email],
      physical_home_addressCtrl: [this.material.physical_home_address],
      city_townCtrl: [this.material.city_town],
      postal_addressCtrl: [this.material.postal_address],
      id_noCtrl: [this.material.id_no],
      loginCtrl: [this.material.login],
      passwordCtrl: [this.material.password],
      confirm_passwordCtrl: [this.material.confirm_password],

      //Magazine articles
      language_of_workCtrl: [this.material.language_of_work],
      journal_sourceCtrl: [this.material.journal_source],
      authorsCtrl: [this.material.authors],
      organisationsCtrl: [this.material.organisations],
      internal_bibliogCtrl: [this.material.internal_bibliog],
      table_of_contentsCtrl: [this.material.table_of_contents],
      name_as_subjectCtrl: [this.material.name_as_subject],
      organisation_as_subjectCtrl: [this.material.organisation_as_subject],
      monograph_sourceCtrl: [this.material.monograph_source],
      shelf_noCtrl: [this.material.shelf_no],
      url_of_on_line_articleCtrl: [this.material.url_of_on_line_article],

      //Sound OR Temparc
      call_numberCtrl: [this.material.call_number],
      contentsCtrl: [this.material.contents],
      annotationCtrl: [this.material.annotation],
      sub_series_titleCtrl: [this.material.sub_series_title],
      programme_titleCtrl: [this.material.programme_title],
      programme_sub_titleCtrl: [this.material.programme_sub_title],
      language_service_titleCtrl: [this.material.language_service_title],
      drama_titleCtrl: [this.material.drama_title],
      translated_drama_titleCtrl: [this.material.translated_drama_title],
      book_film_titleCtrl: [this.material.book_film_title],
      sketch_short_story_titleCtrl: [this.material.sketch_short_story_title],
      serial_titleCtrl: [this.material.serial_title],
      translated_serial_titleCtrl: [this.material.translated_serial_title],
      completeness_noteCtrl: [this.material.completeness_note],
      musiciansCtrl: [this.material.musicians],
      composerCtrl: [this.material.composer],
      conductorCtrl: [this.material.conductor],
      reportersCtrl: [this.material.reporters],
      presentersCtrl: [this.material.presenters],
      producersCtrl: [this.material.producers],
      compilersCtrl: [this.material.compilers],
      readersCtrl: [this.material.readers],
      translatorsCtrl: [this.material.translators],
      commentatorsCtrl: [this.material.commentators],
      adaptorsCtrl: [this.material.adaptors],
      narratorsCtrl: [this.material.narrators],
      news_summaryCtrl: [this.material.news_summary],
      biographyCtrl: [this.material.biography],
      multiple_broadcast_datesCtrl: [this.material.multiple_broadcast_dates],
      name_of_serviceCtrl: [this.material.name_of_service],
      parts_and_durationCtrl: [this.material.parts_and_duration],
      episodes_and_durationCtrl: [this.material.episodes_and_duration],
      tape_qualityCtrl: [this.material.tape_quality],
      speedCtrl: [this.material.speed],
      sound_effectsCtrl: [this.material.sound_effects],
      production_originCtrl: [this.material.production_origin],
      copyright_holderCtrl: [this.material.copyright_holder],
      processed_byCtrl: [this.material.processed_by],
      control_1Ctrl: [this.material.control_1],
      control_2Ctrl: [this.material.control_2],
      lang_controlCtrl: [this.material.lang_control],
      migrateCtrl: [this.material.migrate],
      material_typeCtrl: [this.material.material_type],


      //Sound Accessions
      formatCtrl: [this.material.format],
      submitted_byCtrl: [this.material.submitted_by],
      control_numberCtrl: [this.material.control_number],


      //Namibian Music
      record_categoryCtrl: [this.material.record_category],
      archive_numbersCtrl: [this.material.archive_numbers],
      music_library_nrsCtrl: [this.material.music_library_nrs],
      cover_subtitleCtrl: [this.material.cover_subtitle],
      cover_paralell_titleCtrl: [this.material.cover_paralell_title],
      cover_variant_titlesCtrl: [this.material.cover_variant_titles],
      cover_songwriterCtrl: [this.material.cover_songwriter],
      genreCtrl: [this.material.genre],
      place_country_of_releaseCtrl: [this.material.place_country_of_release],
      speed_rpm_ipsCtrl: [this.material.speed_rpm_ips],
      sound_channelsCtrl: [this.material.sound_channels],
      record_cd_nrCtrl: [this.material.record_cd_nr],
      year_of_releaseCtrl: [this.material.year_of_release],
      total_tracksCtrl: [this.material.total_tracks],
      labelCtrl: [this.material.label],
      link_to_library_numberCtrl: [this.material.link_to_library_number],
      sideCtrl: [this.material.side],
      item_numberCtrl: [this.material.item_number],
      track_numberCtrl: [this.material.track_number],
      track_titleCtrl: [this.material.track_title],
      track_variant_titlesCtrl: [this.material.track_variant_titles],
      track_parallel_titleCtrl: [this.material.track_parallel_title],
      medleyCtrl: [this.material.medley],
      track_languageCtrl: [this.material.track_language],
      track_artist_choir_orchestraCtrl: [this.material.track_artist_choir_orchestra],
      place_institutionCtrl: [this.material.place_institution],
      artist_infoCtrl: [this.material.artist_info],
      track_composerCtrl: [this.material.track_composer],
      track_authorCtrl: [this.material.track_author],
      track_conductorCtrl: [this.material.track_conductor],
      track_copyrightCtrl: [this.material.track_copyright],
      track_durationCtrl: [this.material.track_duration],
      track_subjectCtrl: [this.material.track_subject],
      adaptationCtrl: [this.material.adaptation],
      track_musical_instrumentsCtrl: [this.material.track_musical_instruments],
      track_castCtrl: [this.material.track_cast],
      general_notesCtrl: [this.material.general_notes],
      sound_trackCtrl: [this.material.sound_track],
      track_input_dateCtrl: [this.material.track_input_date],
      track_sourceCtrl: [this.material.track_source],
      mix_versionCtrl: [this.material.mix_version],
      track_release_dateCtrl: [this.material.track_release_date],
      track_cataloguerCtrl: [this.material.track_cataloguer],
      cover_musical_instruments_coverCtrl: [this.material.cover_musical_instruments_cover],
      country_of_originCtrl: [this.material.country_of_origin],
      cover_categoryCtrl: [this.material.cover_category],
      cover_artist_choir_orchestraCtrl: [this.material.cover_artist_choir_orchestra],
      cover_composerCtrl: [this.material.cover_composer],
      cover_producer_regisseurCtrl: [this.material.cover_producer_regisseur],
      cover_conductorCtrl: [this.material.cover_conductor],
      sopranoCtrl: [this.material.soprano],
      mezzo_sopranoCtrl: [this.material.mezzo_soprano],
      altCtrl: [this.material.alt],
      tenorCtrl: [this.material.tenor],
      baritonCtrl: [this.material.bariton],
      movementCtrl: [this.material.movement],
      tempoCtrl: [this.material.tempo],
      orchestraCtrl: [this.material.orchestra],
      number_of_copies_availCtrl: [this.material.number_of_copies_avail],
      recording_producer_opnameCtrl: [this.material.recording_producer_opname],
      cover_publisherCtrl: [this.material.cover_publisher],
      cover_copyrightCtrl: [this.material.cover_copyright],
      broad_subjectsCtrl: [this.material.broad_subjects],
      type_of_itemCtrl: [this.material.type_of_item],
      centreCtrl: [this.material.centre],
      cover_barcodeCtrl: [this.material.cover_barcode],
      marketed_byCtrl: [this.material.marketed_by],
      distributed_byCtrl: [this.material.distributed_by],
      cover_imageCtrl: [this.material.cover_image],

      //CD Music
      archive_numberCtrl: [this.material.archive_number],
      parallell_titleCtrl: [this.material.parallell_title],
      songs_in_medleyCtrl: [this.material.songs_in_medley],
      track_subjectsCtrl: [this.material.track_subjects],
      cover_producerCtrl: [this.material.cover_producer],
      incoming_statusCtrl: [this.material.incoming_status],
      album_typeCtrl: [this.material.album_type],
      moodCtrl: [this.material.mood],
      eraCtrl: [this.material.track_cast],
      music_for_use_forCtrl: [this.material.music_for_use_for],
      music_characterCtrl: [this.material.music_character],
      number_of_itemsCtrl: [this.material.number_of_items],
      track_commentsCtrl: [this.material.track_comments],
      library_numberCtrl: [this.material.library_number],

      //Draw
      reference_numberCtrl: [this.material.reference_number],
      project_numberCtrl: [this.material.project_number],
      project_descriptionCtrl: [this.material.project_description],
      project_locationCtrl: [this.material.project_location],
      erf_numberCtrl: [this.material.erf_number],
      drawing_categoryCtrl: [this.material.drawing_category],
      drawing_numberCtrl: [this.material.drawing_number],
      drawing_scaleCtrl: [this.material.drawing_scale],
      drawn_byCtrl: [this.material.drawn_by],
      revisionCtrl: [this.material.revision],
      sheet_sizeCtrl: [this.material.sheet_size],
      locationCtrl: [this.material.location],
      cabinet_box_numberCtrl: [this.material.cabinet_box_number],
      architectural_bureaCtrl: [this.material.architectural_burea],
      noteCtrl: [this.material.note],
      name_of_image_fileCtrl: [this.material.name_of_image_file],
      typeCtrl: [this.material.type],

      //Names Authority
      idCtrl: [this.material.id],
      type_of_entryCtrl: [this.material.type_of_entry],
      name_authorisedCtrl: [this.material.name_authorised],
      commentsCtrl: [this.material.comments],
      use_nameCtrl: [this.material.use_name],
      use_forCtrl: [this.material.use_for],
      familyCtrl: [this.material.family],
      childrenCtrl: [this.material.children],
      other_familyCtrl: [this.material.other_family],
      non_descriptorCtrl: [this.material.non_descriptor],

      //Namibian History

      //Subject Thesaurus
      termCtrl: [this.material.term],
      scope_noteCtrl: [this.material.scope_note],
      use_Ctrl: [this.material.use_],
      ufCtrl: [this.material.uf],
      btCtrl: [this.material.bt],
      ntCtrl: [this.material.nt],
      rtCtrl: [this.material.rt],

      //Mood
      description_definitionCtrl: [this.material.description_definition],

      //DUB TV
      dub_noCtrl: [this.material.dub_no],
      archiving_valueCtrl: [this.material.archiving_value],
      slug_archiveCtrl: [this.material.slug_archive],
      slug_reportCtrl: [this.material.slug_report],
      time_codeCtrl: [this.material.time_code],
      cameramanCtrl: [this.material.cameraman],
      subjectCtrl: [this.material.subject],
      shooting_dateCtrl: [this.material.shooting_date],
      contribution_centreCtrl: [this.material.contribution_centre],
      data_entryCtrl: [this.material.data_entry],
      captured_by_winisisCtrl: [this.material.captured_by_winisis],

      //News TV
      news_introCtrl: [this.material.news_intro],
      general_cataloguer_notesCtrl: [this.material.general_cataloguer_notes],
      credits_noteCtrl: [this.material.credits_note],
      notes_publication_distributionCtrl: [this.material.notes_publication_distribution],
      notes_availabilityCtrl: [this.material.notes_availability],
      notes_related_termsCtrl: [this.material.notes_related_terms],
      notes_on_intended_audienceCtrl: [this.material.notes_on_intended_audience],
      readerCtrl: [this.material.reader],
      sign_languageCtrl: [this.material.sign_language],
      compilerCtrl: [this.material.compiler],
      shootingCtrl: [this.material.shooting],
      time20_00_bulletinCtrl: [this.material.time20_00_bulletin],
      time22_00_bulletinCtrl: [this.material.time22_00_bulletin],
      time10_00_bulletinCtrl: [this.material.time10_00_bulletin],
      time13_00_bulletinCtrl: [this.material.time13_00_bulletin],
      local_newsCtrl: [this.material.local_news],
      international_newsCtrl: [this.material.international_news],
      bulletinCtrl: [this.material.bulletin],
      data_capturingCtrl: [this.material.data_capturing],

      //Adverts
      slugCtrl: [this.material.slug],
      categoryCtrl: [this.material.category],
      year_receivedCtrl: [this.material.year_received],
      codeCtrl: [this.material.code],

      //Sport TV
      

      //Videos Foreign Material
      sub_titleCtrl: [this.material.sub_title],
      publicity_material_receivedCtrl: [this.material.publicity_material_received],
      number_of_tapesCtrl: [this.material.number_of_tapes],
      number_of_episodesCtrl: [this.material.number_of_episodes],
      details_of_episodes_seriesCtrl: [this.material.details_of_episodes_series],
      notes_assessors_remarksCtrl: [this.material.notes_assessors_remarks],
      companyCtrl: [this.material.company],
      number_of_runsCtrl: [this.material.number_of_runs],
      broad_subject_genreCtrl: [this.material.broad_subject_genre],
      scheduling_dateCtrl: [this.material.scheduling_date],
      dispatch_addressCtrl: [this.material.dispatch_address],
      shipping_dateCtrl: [this.material.shipping_date],
      shipping_numberCtrl: [this.material.shipping_number],
      courierCtrl: [this.material.courier],
      order_dateCtrl: [this.material.order_date],
      date_of_transferCtrl: [this.material.date_of_transfer],
      contract_periodCtrl: [this.material.contract_period],
      contract_numberCtrl: [this.material.contract_number],
      cataloguer_initialsCtrl: [this.material.cataloguer_initials],

      //Programme Master
      introCtrl: [this.material.intro],
      scriptCtrl: [this.material.script],
      uncontrolled_termsCtrl: [this.material.uncontrolled_terms],
      title_as_subjectCtrl: [this.material.title_as_subject],
      family_as_subjectCtrl: [this.material.family_as_subject],
      origin_of_tapeCtrl: [this.material.origin_of_tape],
      date_of_eventCtrl: [this.material.date_of_event],

    });
  }


  filter(filter: string,field): any[] {
    this.lastFilter = filter;
    if (filter) {

      if(field == "Participants"){

        return this.participants_array.filter(option => {
          return option.name.toLowerCase().indexOf(filter.toLowerCase()) >= 0;
        })
      }
      else if(field == "Speakers"){

        return this.speakers_array.filter(option => {
          return option.name.toLowerCase().indexOf(filter.toLowerCase()) >= 0;
        })
      }
      else if(field == "Interviewees"){

        return this.interviewees_array.filter(option => {
          return option.name.toLowerCase().indexOf(filter.toLowerCase()) >= 0;
        })
      } 
      else if(field == "Names"){

        return this.names_array.filter(option => {
          return option.name.toLowerCase().indexOf(filter.toLowerCase()) >= 0;
        })
      }
      else if(field == "Special Names"){

        return this.specialNames_array.filter(option => {
          return option.name.toLowerCase().indexOf(filter.toLowerCase()) >= 0;
        })
      }
      else if(field == "Referred To"){

        return this.referredTo_array.filter(option => {
          return option.name.toLowerCase().indexOf(filter.toLowerCase()) >= 0;
        })
      }
      else if(field == "Performers"){

        return this.performers_array.filter(option => {
          return option.name.toLowerCase().indexOf(filter.toLowerCase()) >= 0;
        })
      }
      else if(field == "Cast"){

        return this.cast_array.filter(option => {
          return option.name.toLowerCase().indexOf(filter.toLowerCase()) >= 0;
        })
      }
      else if(field == "Contestants"){

        return this.contestants_array.filter(option => {
          return option.name.toLowerCase().indexOf(filter.toLowerCase()) >= 0;
        })
      }
      

    } else {

      if(field == "Participants")return this.participants_array.slice();
      else if(field == "Speakers")return this.speakers_array.slice();
      else if(field == "Interviewees")return this.interviewees_array.slice();
      else if(field == "Names")return this.names_array.slice();
      else if(field == "Special Names")return this.specialNames_array.slice();
      else if(field == "Referred To")return this.referredTo_array.slice();
      else if(field == "Performers")return this.performers_array.slice();
      else if(field == "Cast")return this.cast_array.slice();
      else if(field == "Contestants")return this.contestants_array.slice();

    }
  }

  displayFn(value: any[] | string): string | undefined {
    let displayValue: string;
    if (Array.isArray(value)) {

      value.forEach((name, index) => {
        if (index === 0) {
          displayValue = name.name;
        } else {
          displayValue += ', ' + name.name;
        }
      });
    } else {
      displayValue = value;
    }
    return displayValue;
  }

  toggleSelection(name: any,field) {

    name.selected = !name.selected;
    if (name.selected) {

      if(field == "Participants")this.selectedParticipants.push(name);
      else if(field == "Speakers")this.selectedSpeakers.push(name);
      else if(field == "Interviewees")this.selectedInterviewees.push(name);
      else if(field == "Names")this.selectedNames.push(name);
      else if(field == "Special Names")this.selectedSpecialNames.push(name);
      else if(field == "Referred To")this.selectedReferredTo.push(name);
      else if(field == "Performers")this.selectedPerformers.push(name);
      else if(field == "Cast")this.selectedCast.push(name);
      else if(field == "Contestants")this.selectedContestants.push(name);

    } else {

      if(field == "Participants"){
        let i = this.selectedParticipants.findIndex(value => value.name === name.name);
        this.selectedParticipants.splice(i, 1);
      }
      else
      if(field == "Speakers"){
        let i = this.selectedSpeakers.findIndex(value => value.name === name.name);
        this.selectedSpeakers.splice(i, 1);
      }
      else
      if(field == "Interviewees"){
        let i = this.selectedInterviewees.findIndex(value => value.name === name.name);
        this.selectedInterviewees.splice(i, 1);
      }
      else
      if(field == "Names"){
        let i = this.selectedNames.findIndex(value => value.name === name.name);
        this.selectedNames.splice(i, 1);
      }
      else
      if(field == "Special Names"){
        let i = this.selectedSpecialNames.findIndex(value => value.name === name.name);
        this.selectedSpecialNames.splice(i, 1);
      }
      else
      if(field == "Referred To"){
        let i = this.selectedReferredTo.findIndex(value => value.name === name.name);
        this.selectedReferredTo.splice(i, 1);
      }
      else
      if(field == "Performers"){
        let i = this.selectedPerformers.findIndex(value => value.name === name.name);
        this.selectedPerformers.splice(i, 1);
      }
      else
      if(field == "Cast"){
        let i = this.selectedCast.findIndex(value => value.name === name.name);
        this.selectedCast.splice(i, 1);
      }
      else
      if(field == "Contestants"){
        let i = this.selectedContestants.findIndex(value => value.name === name.name);
        this.selectedContestants.splice(i, 1);
      }
    }

    this.participantsControl.setValue(this.selectedParticipants);
    this.speakersControl.setValue(this.selectedSpeakers);
    this.intervieweesControl.setValue(this.selectedInterviewees);
    this.namesControl.setValue(this.selectedNames);
    this.specialNamesControl.setValue(this.selectedSpecialNames);
    this.referredToControl.setValue(this.selectedReferredTo);
    this.performersControl.setValue(this.selectedPerformers);
    this.castControl.setValue(this.selectedCast);
    this.contestantsControl.setValue(this.selectedContestants);
  }

  optionClicked(event: Event, name: any,field) {
    event.stopPropagation();
    this.toggleSelection(name,field);
  }

  private _filter(value: string): any[] {
    const filterValue = value.toLowerCase();

    return this.series_array.filter(option => option.name.toLowerCase().includes(filterValue));
  }

   addSeries(series)
  {
    this.material.series = series;
    this.material.notes_series = series;
  }

  addParticipantsDialog(): void {

    const dialogRef = this.dialog.open(AddParticipantsComponent, {
      width: '600px',
      data: this.material
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
  
        this.onReadABCDOptions();
      }
      else{
        //this.onReadABCDOptions();
      }
    });
  }

  onFileSelected(event){
    
    this.selectedFile  = null;
    this.selectedFile = <FileList>event.target.files;

    if (event.target.files && event.target.files[0]) {
      const file = event.target.files[0];

      const reader = new FileReader();
      reader.onload = e => this.material.photo = reader.result;
      reader.readAsDataURL(file);
  }
  }

  async onReadABCDOptions() {

    const response = this.httpClient.post(`https://mojalove.mmsafrica.co.za/api/schedule/material/read_abcd_options.php`, 
    {
        employee_no: this.employee_no,

    }, {responseType: 'json'}).pipe()
    .subscribe(
        (data: any) => {
  
          
          this.series_array = [];
          this.services_array = [];
          this.names_array = [];
          this.genres = [];
          this.moods_array = [];
          this.advert_categorys_array = [];

          for (let i = 0; i < data[0].genre.length; i++) {
              this.genres[i] = {
                bdcr_no: data[0].genre[i].bdcr_no,
                genre: data[0].genre[i].genre,
                genreId: data[0].genre[i].genreId,
                code: 0,
                color: "",
              };
          }


          for (let i = 0; i < data[0].series.length; i++) {
              this.series_array[i] = {
                  idNo: data[0].series[i].idNo,
                  name: data[0].series[i].name
              };
          }

          for (let i = 0; i < data[0].services.length; i++) {
              this.services_array[i] = {
                  idNo: data[0].services[i].idNo,
                  name: data[0].services[i].name
              };
          }

          for (let i = 0; i < data[0].moods.length; i++) {
            this.moods_array[i] = {
                idNo: data[0].moods[i].idNo, 
                name: data[0].moods[i].name
            };
        }

        for (let i = 0; i < data[0].advert_categorys.length; i++) {
          this.advert_categorys_array[i] = {
              idNo: data[0].advert_categorys[i].idNo, 
              name: data[0].advert_categorys[i].name
          };
      }

          for (let i = 0; i < data[0].names.length; i++) {
              this.names_array[i] = {
                  idNo: data[0].names[i].idNo,
                  name: data[0].names[i].name,
                  selected: data[0].names[i].selected,
              };

              this.participants_array[i] = {
                idNo: data[0].names[i].idNo,
                name: data[0].names[i].name,
                selected: data[0].names[i].selected,
            };
              this.speakers_array[i] = {
                idNo: data[0].names[i].idNo,
                name: data[0].names[i].name,
                selected: data[0].names[i].selected,
            };
              this.interviewees_array[i] = {
                idNo: data[0].names[i].idNo,
                name: data[0].names[i].name,
                selected: data[0].names[i].selected,
            };
              this.names_array[i] = {
                idNo: data[0].names[i].idNo,
                name: data[0].names[i].name,
                selected: data[0].names[i].selected,
            };
              this.specialNames_array[i] = {
                idNo: data[0].names[i].idNo,
                name: data[0].names[i].name,
                selected: data[0].names[i].selected,
            };
              this.referredTo_array[i] = {
                idNo: data[0].names[i].idNo,
                name: data[0].names[i].name,
                selected: data[0].names[i].selected,
            };
              this.performers_array[i] = {
                idNo: data[0].names[i].idNo,
                name: data[0].names[i].name,
                selected: data[0].names[i].selected,
            };
              this.cast_array[i] = {
                idNo: data[0].names[i].idNo,
                name: data[0].names[i].name,
                selected: data[0].names[i].selected,
            };
              this.contestants_array[i] = {
                idNo: data[0].names[i].idNo,
                name: data[0].names[i].name,
                selected: data[0].names[i].selected,
            };
          }

          this.participantsilteredOptions = this.participantsControl.valueChanges.pipe(
            startWith<string | any[]>(''),
            map(value => typeof value === 'string' ? value : this.lastFilter),
            map(filter => this.filter(filter,'Participants'))
          );

          this.speakersfilteredOptions = this.participantsControl.valueChanges.pipe(
            startWith<string | any[]>(''),
            map(value => typeof value === 'string' ? value : this.lastFilter),
            map(filter => this.filter(filter,'Speakers'))
          );

          this.intervieweesfilteredOptions = this.participantsControl.valueChanges.pipe(
            startWith<string | any[]>(''),
            map(value => typeof value === 'string' ? value : this.lastFilter),
            map(filter => this.filter(filter,'Interviewees'))
          );

          this.namesfilteredOptions = this.participantsControl.valueChanges.pipe(
            startWith<string | any[]>(''),
            map(value => typeof value === 'string' ? value : this.lastFilter),
            map(filter => this.filter(filter,'Names'))
          );

          this.specialNamesfilteredOptions = this.participantsControl.valueChanges.pipe(
            startWith<string | any[]>(''),
            map(value => typeof value === 'string' ? value : this.lastFilter),
            map(filter => this.filter(filter,'Special Names'))
          );

          this.referredTofilteredOptions = this.participantsControl.valueChanges.pipe(
            startWith<string | any[]>(''),
            map(value => typeof value === 'string' ? value : this.lastFilter),
            map(filter => this.filter(filter,'Referred To'))
          );

          this.performersfilteredOptions = this.participantsControl.valueChanges.pipe(
            startWith<string | any[]>(''),
            map(value => typeof value === 'string' ? value : this.lastFilter),
            map(filter => this.filter(filter,'Performers'))
          );

          this.castfilteredOptions = this.participantsControl.valueChanges.pipe(
            startWith<string | any[]>(''),
            map(value => typeof value === 'string' ? value : this.lastFilter),
            map(filter => this.filter(filter,'Cast'))
          );

          this.contestantsfilteredOptions = this.participantsControl.valueChanges.pipe(
            startWith<string | any[]>(''),
            map(value => typeof value === 'string' ? value : this.lastFilter),
            map(filter => this.filter(filter,'Contestants'))
          );

          this.filteredOptions = this.myControl.valueChanges.pipe( 
            startWith(''),
            map(value => this._filter(value)),
          );

        }
    );
 }


  openDeleteDialog(): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      data: 'Are you sure you want to delete the selected materials?'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {

        this.deleteABCDMaterial();
      }
    });
  }

  async deleteABCDMaterial()
  {
    this.httpClient.get(`https://mojalove.mmsafrica.co.za/api/schedule/material/delete_abcd_material.php?materialID=${this.materialID}`).pipe()
    .subscribe(
      (data:any) =>{
        
        this.alertService.success('Material successfully deleted');
        this.router.navigate(['/home/material-management']);

      },error => {
        this.alertService.error("Something went wrong, Try again");
      }
    )
  }


  async saveMaterial(){ 

    var broadcast_date = "";
    var re_broadcast_date = "";
    var date_received = "";
    var date_of_input = "";
    var first_control = "";
    var final_control = "";
    var date_written_off = "";

    if(this.material.broadcast_date != null && this.material.broadcast_date != ""){broadcast_date = moment(this.material.broadcast_date.toString()).format("YYYY-MM-DD");}

    if(this.material.re_broadcast_date != null && this.material.re_broadcast_date != ""){re_broadcast_date = moment(this.material.re_broadcast_date.toString()).format("YYYY-MM-DD");}

    if(this.material.date_received != null && this.material.date_received != ""){date_received = moment(this.material.date_received.toString()).format("YYYY-MM-DD");}

    if(this.material.date_of_input != null && this.material.date_of_input != ""){date_of_input = moment(this.material.date_of_input.toString()).format("YYYY-MM-DD");}

    if(this.material.first_control != null && this.material.first_control != ""){first_control = moment(this.material.first_control.toString()).format("YYYY-MM-DD");}

    if(this.material.final_control != null && this.material.final_control != ""){final_control = moment(this.material.final_control.toString()).format("YYYY-MM-DD");}

    if(this.material.date_written_off != null && this.material.date_written_off != ""){date_written_off = moment(this.material.date_written_off.toString()).format("YYYY-MM-DD");}


    const response = this.httpClient.post(`https://mojalove.mmsafrica.co.za/api/schedule/material/edit_abcd_material.php`,
    {
        //Tape Information
        employee_no: this.employee_no,
        materialID: this.materialID,
        tape_number: this.material.tape_number,
        tape_format: this.material.tape_format,
        status: this.material.status,
        location_of_tape: this.material.location_of_tape,
        language_of_item: this.material.language_of_item,
        series_title: this.material.series_title,
        introduction: this.material.introduction,
        shotlist: this.material.shotlist,
        broadcast_date: broadcast_date,
        re_broadcast_date: re_broadcast_date,
        duration: this.material.duration,
        distribution: this.material.distribution,
        physical_description: this.material.physical_description,

        //Notes
        notes: this.material.notes,
        credits_notes: this.material.credits_notes,
        pronunciation: this.material.pronunciation,
        note_on_intended_audience: this.material.note_on_intended_audience,
        descriptive_info: this.material.descriptive_info,
        title_responsibility: this.material.title_responsibility,
        series: this.material.series,
        related_terms: this.material.related_terms,

        //Names
        participants: this.material.participants,
        speakers: this.material.speakers,
        interviewees: this.material.interviewees,
        names: this.material.names,
        special_names: this.material.special_names,
        referred_to: this.material.referred_to,
        performers: this.material.performers,
        cast: this.material.cast,
        contestants: this.material.contestants,

        //Subjects
        keywords: this.material.keywords,
        controlled_subjects: this.material.controlled_subjects,
        uncontrolled_subjects: this.material.uncontrolled_subjects,
        personal_name_as_subject: this.material.personal_name_as_subject,
        corporate_body_as_subject: this.material.corporate_body_as_subject,
        family_name_as_subject: this.material.family_name_as_subject,
        title_used_as_subject: this.material.title_used_as_subject,
        geographical_name_as_subject: this.material.geographical_name_as_subject,
        programme_category: this.material.programme_category,

        //Production
        presenter: this.material.presenter,
        reporter: this.material.reporter,
        producer: this.material.producer,
        executive_producer: this.material.executive_producer,
        senior_producer: this.material.senior_producer,
        director: this.material.director,
        editor: this.material.editor,
        translator: this.material.translator,
        camera: this.material.camera,
        sound: this.material.sound,
        narrator: this.material.narrator,
        commentator: this.material.commentator,
        interpreter: this.material.interpreter,
        scriptwriter: this.material.scriptwriter,
        production: this.material.production,

        //Administration Information
        date_received: date_received,
        date_of_input: date_of_input,
        cataloguer: this.material.cataloguer,
        data_captured_by: this.material.data_captured_by,
        first_control: first_control,
        final_control: final_control,
        update_edited: this.material.update_edited, 
        stocktaking: this.material.stocktaking,
        date_written_off: date_written_off,
        barcode: this.material.barcode,
        control_no: this.material.control_no,

    },{ responseType: 'json'}).pipe()
    .subscribe(
      (data:any) => {
         
        if(data.message == 'success')
        {
          this.alertService.success("Material successfully saved");
          //this.router.navigate(['/home/material-management']);
        }
        else{

          this.alertService.error(data.message);
        }

      },error => {
        this.alertService.error("Something went wrong, Try again");
      } 
    ) 
  }

  async onReadABCDMaterials(){ 
      
    const response = this.httpClient.post(`https://mojalove.mmsafrica.co.za/api/schedule/material/read_one_abcd_material.php`,
    {
      material_type: this.abcd_database,
      employee_no: this.employee_no,
      materialID: this.materialID

    },{ responseType: 'json'}).pipe( )
    .subscribe(
      (data:any) => {
         
          //Tape Information
          this.material.tape_number = data[0].tape_number;
          this.material.tape_format = data[0].tape_format;
          this.material.status = data[0].status;
          this.material.location_of_tape = data[0].location_of_tape;
          this.material.language_of_item = data[0].language_of_item;
          this.material.series_title = data[0].series_title;
          this.material.introduction = data[0].introduction;
          this.material.shotlist = data[0].shotlist;
          this.material.broadcast_date = data[0].broadcast_date;
          this.material.re_broadcast_date = data[0].re_broadcast_date;
          this.material.duration = data[0].duration;
          this.material.distribution = data[0].distribution;
          this.material.physical_description = data[0].physical_description;

          //Notes
          this.material.notes = data[0].notes;
          this.material.credits_notes = data[0].credits_notes;
          this.material.pronunciation = data[0].pronunciation;
          this.material.note_on_intended_audience = data[0].note_on_intended_audience;
          this.material.descriptive_info = data[0].descriptive_info;
          this.material.title_responsibility = data[0].title_responsibility;
          this.material.series = data[0].series;
          this.material.related_terms = data[0].related_terms;

          //Names
          this.material.participants = data[0].participants;
          this.material.speakers = data[0].speakers;
          this.material.interviewees = data[0].interviewees;
          this.material.names = data[0].names;
          this.material.special_names = data[0].special_names;
          this.material.referred_to = data[0].referred_to;
          this.material.performers = data[0].performers;
          this.material.cast = data[0].cast;
          this.material.contestants = data[0].contestants;

          //Subjects
          this.material.keywords = data[0].keywords;
          this.material.controlled_subjects = data[0].controlled_subjects;
          this.material.uncontrolled_subjects = data[0].uncontrolled_subjects;
          this.material.personal_name_as_subject = data[0].personal_name_as_subject;
          this.material.corporate_body_as_subject = data[0].corporate_body_as_subject;
          this.material.family_name_as_subject = data[0].family_name_as_subject;
          this.material.title_used_as_subject = data[0].title_used_as_subject;
          this.material.geographical_name_as_subject = data[0].geographical_name_as_subject;
          this.material.programme_category = data[0].programme_category;

          //Production
          this.material.presenter = data[0].presenter;
          this.material.reporter = data[0].reporter;
          this.material.producer = data[0].producer;
          this.material.executive_producer = data[0].executive_producer;
          this.material.senior_producer = data[0].senior_producer;
          this.material.director = data[0].director;
          this.material.editor = data[0].editor;
          this.material.translator = data[0].translator;
          this.material.camera = data[0].camera;
          this.material.sound = data[0].sound;
          this.material.narrator = data[0].narrator;
          this.material.commentator = data[0].commentator;
          this.material.interpreter = data[0].interpreter;
          this.material.scriptwriter = data[0].scriptwriter;
          this.material.production = data[0].production;

          //Administration Information
          this.material.date_received = data[0].date_received;
          this.material.date_of_input = data[0].date_of_input;
          this.material.cataloguer = data[0].cataloguer;
          this.material.data_captured_by = data[0].data_captured_by;
          this.material.first_control = data[0].first_control;
          this.material.final_control = data[0].final_control;
          this.material.update_edited = data[0].update_edited;
          this.material.stocktaking = data[0].stocktaking;
          this.material.date_written_off = data[0].date_written_off;
          this.material.barcode = data[0].barcode;
          this.material.control_no = data[0].control_no;


          this.material.tape_format = {label: 'Betacam', value: 'Betacam'};
          if(data[0].tape_format == "DVCPRO"){
            this.material.tape_format = {label: 'DVCPRO', value: 'DVCPRO'};
          }
          else
          if(data[0].tape_format == "DVCAM"){
            this.material.tape_format = {label: 'DVCAM', value: 'DVCAM'};
          }
          else
          if(data[0].tape_format == "MiniDV"){
            this.material.tape_format = {label: 'MiniDV', value: 'MiniDV'};
          }
          else
          if(data[0].tape_format == "One-inch"){
            this.material.tape_format = {label: 'One-inch', value: 'One-inch'};
          }
          else
          if(data[0].tape_format == "U-matic"){
            this.material.tape_format = {label: 'U-matic', value: 'U-matic'};
          }
          else
          if(data[0].tape_format == "VHS"){
            this.material.tape_format = {label: 'VHS', value: 'VHS'};
          }



          this.material.status = {label: 'Available', value: 'Available'};
          if(data[0].status == "Missing"){
            this.material.status = {label: 'Missing', value: 'Missing'};
          }
          else
          if(data[0].status == "Damaged"){
            this.material.status = {label: 'Damaged', value: 'Damaged'};
          }
          else
          if(data[0].status == "Lost"){
            this.material.status = {label: 'Lost', value: 'Lost'};
          }


          this.material.location_of_tape = {label: 'Programme Archive', value: 'Programme Archive'};
          if(data[0].location_of_tape == "News Archive"){
            this.material.location_of_tape = {label: 'News Archive', value: 'News Archive'};
          }
          else
          if(data[0].location_of_tape == "National Archives"){
            this.material.location_of_tape = {label: 'National Archives', value: 'National Archives'};
          }
          
      } 
    ) 
  }

  back(){
    this._location.back();
  }
}
