import {Component, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {AlertService} from '../../services/alert/alert.service';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {environment} from '../../../environments/environment';
import {BehaviorSubject} from 'rxjs';
import {SelectionModel} from '@angular/cdk/collections';
import {ConfirmationDialogComponent} from '../confirmation-dialog/confirmation-dialog.component';
import {Location} from '@angular/common';

//Import used to make HTTP get and post requests
import {HttpClient} from '@angular/common/http';
import {LoadingController, NavController} from '@ionic/angular';
import {finalize} from 'rxjs/operators';
import { UserClass } from 'src/app/classes/users';
import { FormBuilder, FormGroup,Validators, FormControl } from '@angular/forms';

@Component({ 
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent implements OnInit {

  userId: number;
  baseUrl = environment.serverUrl;

  userFormGroup: FormGroup;
  user: UserClass = new UserClass();

  password1: string = '';
  password2: string = '';
  changePassword: boolean = false;

  constructor(private router: Router, private alertService: AlertService,private _location: Location,private httpClient: HttpClient,public dialog: MatDialog,private formBuilder: FormBuilder) {

    this.userId = parseInt(JSON.parse(localStorage.getItem('employee_no')));
    this.onReadOneUser();
  }

  ngOnInit() {
   
    this.userFormGroup = this.formBuilder.group({ 
      emailCtrl: [this.user.email, Validators.required],
      nameCtrl: [this.user.name, Validators.required],
      surnameCtrl: [this.user.surname, Validators.required],
      phoneNumberCtrl: [this.user.phoneNumber],
      accountTypeCtrl: [this.user.accountType, Validators.required],
      statusCtrl: [this.user.status, Validators.required],
      changePasswordCtrl: [this.changePassword],
      password1Ctrl: [this.password1],
      password2Ctrl: [this.password2],
    });
  }


  async onReadOneUser() {

    const response = this.httpClient.post(`https://mojalove.mmsafrica.co.za/api/admin/read_one_employee.php`,
    {
      userId: this.userId,

    }, {responseType: 'json'}).pipe()
    .subscribe(
        (data: any) => {

            this.user.email = data[0].email;
            this.user.name = data[0].name;
            this.user.surname = data[0].surname;
            this.user.phoneNumber = data[0].phoneNumber;
            this.user.accountType = data[0].accountType;
            this.user.status = data[0].status;
        }
    )
}

  async saveUser(){ 

    const response = this.httpClient.post(`https://mojalove.mmsafrica.co.za/api/admin/update_user.php`,
    {
      userId: this.userId,
      name: this.user.name,
      surname: this.user.surname,
      email: this.user.email,
      phoneNumber: this.user.phoneNumber,
      accountType: this.user.accountType,
      status: this.user.status,
      password1: this.password1,
      password2: this.password2,
      changePassword: this.changePassword,

    },{ responseType: 'json'}).pipe()
    .subscribe(
      (data:any) => {
         
        if(data.message == 'success')
        {
          this.changePassword = false;
          this.alertService.success("Account successfully saved");
        }
        else{

          this.alertService.error(data.message);
        }

      },error => {
        this.alertService.error("Something went wrong, Try again");
      } 
    ) 
  }

  returnToDashboard() {
    this.router.navigate(['/home']);
  }

  back(){
    this._location.back();
  }

}
