import {Component, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {AlertService} from '../../services/alert/alert.service';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {environment} from '../../../environments/environment';
import {BehaviorSubject} from 'rxjs';
import {SelectionModel} from '@angular/cdk/collections';
import {ConfirmationDialogComponent} from '../confirmation-dialog/confirmation-dialog.component';
import {Location} from '@angular/common';

//Import used to make HTTP get and post requests
import {HttpClient} from '@angular/common/http';
import {LoadingController, NavController} from '@ionic/angular';
import {finalize} from 'rxjs/operators';
import { FormBuilder, FormGroup,Validators, FormControl } from '@angular/forms';
import { GenreClass } from 'src/app/classes/genre';
import { ClashClass } from 'src/app/classes/clash';
import { SareClass } from 'src/app/classes/sare';

@Component({
  selector: 'app-new-clash',
  templateUrl: './new-clash.component.html',
  styleUrls: ['./new-clash.component.scss'],
})
export class NewClashComponent implements OnInit {

  campaign: any;
  baseUrl = environment.serverUrl;

  categorieFormGroup: FormGroup;
  clash: ClashClass = new ClashClass();
  channel = "1";

  channels: SareClass[] = [];

  constructor(private router: Router, private alertService: AlertService,private _location: Location,private httpClient: HttpClient,public dialog: MatDialog,private formBuilder: FormBuilder) {

  }

  ngOnInit() {
   
    this.categorieFormGroup = this.formBuilder.group({ 
      clashCtrl: [this.clash.clash, Validators.required],
      channelNoCtrl: [this.channel, Validators.required],
    });
    
    this.onChannels();
  }

  async onChannels(){ 
      
    const response = this.httpClient.post(`https://mojalove.mmsafrica.co.za/api/schedule/get_channels.php`,
    {
      channes: 0,
      date: '',
      employee_no: 0,

    },{ responseType: 'json'}).pipe()
    .subscribe(
      (data:any) => {
         
		 this.channels = [];
        for(var i=0;i<data.length;i++)
        {
            this.channels[i] = {bdcr_no: data[i].bdcr_no,genre: data[i].genre,platform: data[i].platform,sare_name: data[i].sare_name,sare_no: data[i].sare_no};
        }

      } 
    ) 
  }

  async saveClash(){ 

    const response = this.httpClient.post(`https://mojalove.mmsafrica.co.za/api/schedule/save_clash.php`,
    {
      channel: this.channel,
      clash: this.clash.clash,

    },{ responseType: 'json'}).pipe()
    .subscribe(
      (data:any) => {
         
        if(data.message == 'success')
        {
          this.categorieFormGroup.reset();
          this.alertService.success("Category successfully saved");
          this.router.navigate(['/home/category-list']);
        }
        else{

          this.alertService.error(data.message);
        }

      },error => {
        this.alertService.error("Something went wrong, Try again");
      } 
    ) 
  }

  returnToDashboard() {
    this.router.navigate(['/home']);
  }

  back(){
    this._location.back();
  }
}