import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog'; 
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import { ClientClass } from 'src/app/classes/client';
import { HttpClient } from '@angular/common/http';
import { AlertService } from 'src/app/services/alert/alert.service';
import { ClashClass } from 'src/app/classes/clash';

@Component({
  selector: 'app-add-product-dialog',
  templateUrl: './add-product-dialog.component.html',
  styleUrls: ['./add-product-dialog.component.scss']
})
export class AddProductDialogComponent implements OnInit {
  
  productAddFormGroup: FormGroup; 
  formData = new FormData();
  display_array: ClientClass[] = [];

  product: string = '';
  advertiser: number = 0;
  category: string = '';

  clash_list: ClashClass[];  

  constructor(public dialogRef: MatDialogRef<AddProductDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any, private _formBuilder: FormBuilder,private httpClient: HttpClient,private  alertService: AlertService,private formBuilder: FormBuilder) {

                this.onReadClashes();
  }

  ngOnInit() {
    this.productAddFormGroup = this._formBuilder.group({
      newProduct: ['', [<any>Validators.required]]
    });

    this.productAddFormGroup = this.formBuilder.group({
      productCtrl: [this.product, Validators.required],
      advertiserCtrl: [this.advertiser, Validators.required],
      categoryCtrl: [this.category, Validators.required]});

    this.onReadAdvertisers();
  } 

  async onReadClashes(){ 

    const response = this.httpClient.post(`https://mojalove.mmsafrica.co.za/api/schedule/read_clashes.php`,
    {
      employee_no: 0,

    },{ responseType: 'json'}).pipe()
    .subscribe(
      (data:any) => {
         
        this.clash_list = [];
        
        for(var i=0;i<data.length;i++)
        {
            this.clash_list[i] = {idNo: data[i].idNo,clash: data[i].clash,channel: data[i].channel};
        } 
        
      },error => {
        this.alertService.error("Something went wrong, Try again");
      } 
    ) 
  }

  async saveProduct(){

    this.formData.append('employee_no',<any>0);
    this.formData.append('product_name',<any>this.product);
    this.formData.append('description',<any>'');
    this.formData.append('quantity',<any>0);
    this.formData.append('unit_price',<any>0);
    this.formData.append('discount',<any>0);
    this.formData.append('turnVat',<any>false);
    this.formData.append('vat_type',<any>{label: 'On top of price', value: 'On top of price'});
    this.formData.append('vat_rate',<any>0);
    this.formData.append('clientNo',<any>this.advertiser);
    this.formData.append('category',<any>this.category);

    this.httpClient.post('https://mojalove.mmsafrica.co.za/api/sales/product/save_product.php',this.formData)
    .subscribe((message:any) => {
      
      if(message.message == 'success'){
        this.alertService.success('Product successfully saved !');
      } 
      else{  
        this.alertService.error(message.message);
      } 
    },error => {
   
      this.alertService.error("Something went wrong, Try again");
    }); 
  }

  async onReadAdvertisers()
  {
    const response = this.httpClient.post(`https://mojalove.mmsafrica.co.za/api/sales/client/filter_clients.php`,
    {
        employee_no: 0,

    },{ responseType: 'json'}).pipe()
    .subscribe(
      (data:any) => {
        
        this.display_array = [];

        for(var i=0;i<data.length;i++)
        { 
            var strLate_fee_value = 0;
            this.display_array[i] = {type: data[i].type,idNo: data[i].idNo,employee_no: data[i].employee_no,company: data[i].company,first_name: data[i].first_name,last_name: data[i].last_name,email: data[i].email,phone: data[i].phone,address: data[i].address,city: data[i].city,state: data[i].state,zip_code: data[i].zip_code,vat_number: data[i].vat_number,tax_number: data[i].tax_number, currency: data[i].currency,rem_days: data[i].rem_days,rem_position: data[i].rem_position,late_fee_percentage: data[i].late_fee_percentage,late_fee_option: data[i].late_fee_option,late_fee_value: strLate_fee_value,late_fee_days: data[i].late_fee_days,late_fee_tax_rate: data[i].late_fee_tax_rate,late_fee_tax_name: data[i].late_fee_tax_name,late_fee_tax_number: data[i].late_fee_tax_number,company_logo: data[i].company_logo,date_time: data[i].date_time,checked: false,ship_address: data[i].ship_address,ship_city: data[i].ship_city,ship_state: data[i].ship_state,ship_zip_code: data[i].ship_zip_code,agencyId: data[i].agencyId,agency: ''};
        }
        
      },error => {

        this.alertService.error("Something went wrong, Try again");
      } 
    )
  }

  cancel(): void {
    this.dialogRef.close();
  }

}
