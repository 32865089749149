import {Component, OnInit, ViewChild} from '@angular/core';
import {PackageService} from '../../services/package/package.service';
import {CampaignSpotService} from '../../services/campaignSpot/campaign-spot.service';
import {CampaignService} from '../../services/campaign/campaign.service';
import {Router} from '@angular/router';
import {CampaignSpotModel} from '../../models/campaign-spot.model';
import {AlertService} from '../../services/alert/alert.service';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {AllocateFlightingCodeDialogComponent} from '../allocate-flighting-code-dialog/allocate-flighting-code-dialog.component';
import {FlightingCodeService} from '../../services/flighting-code/flighting-code.service';
import {environment} from '../../../environments/environment';
import {BehaviorSubject, Observable} from 'rxjs'; 
import {SelectionModel} from '@angular/cdk/collections';
import {ConfirmationDialogComponent} from '../confirmation-dialog/confirmation-dialog.component';
import {Location} from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { FormGroup, Validators,FormBuilder,FormControl } from '@angular/forms';
import { CampaignClass } from 'src/app/classes/campaign';
import { ClientClass } from 'src/app/classes/client'; 
import { ProductClass } from 'src/app/classes/product';
import { WebHeaderClass } from 'src/app/classes/web.header';
import { WebSlideShowClass } from 'src/app/classes/web.slideshow';
import { ProgrammeClass } from 'src/app/classes/programme';
import { map, startWith } from 'rxjs/operators';
import { WebProgCategoryClass } from 'src/app/classes/web.prog_categories';
import moment from 'moment';
import { WebProgScheduleClass } from 'src/app/classes/web.schedule';
import { saveAs } from 'file-saver';
import * as $ from "jquery";
import { SareClass } from 'src/app/classes/sare';
import { WebArticleClass } from 'src/app/classes/web.article';
import { AppBannersClass } from 'src/app/classes/app.banners';
import { AppChannelsClass } from 'src/app/classes/app.channels';
import { WebUserClass } from 'src/app/classes/web.users';

@Component({
  selector: 'app-website-admin',
  templateUrl: './website-admin.component.html',
  styleUrls: ['./website-admin.component.scss'],
})
export class WebsiteAdminComponent implements OnInit {

  campaign: any; 
  baseUrl = environment.serverUrl; 

  //Programms
  myControl = new FormControl(); 
  filteredOptions: Observable<ProgrammeClass[]>;
  display_array: ProgrammeClass[] = [];
  channels: SareClass[] = [];
  selected_channels: string = "1";

  //Streaming APP
  imageSrc: string | ArrayBuffer;
  formData = new FormData();
  selectedImage: File = null;
  imageSrc_logo: string | ArrayBuffer;
  imageSrc_background: string | ArrayBuffer;

  bannerList: AppBannersClass[] = [];
  displayedColumns_banner: string[] = ['title','image','position', 'status', 'remove'];
  dataSource_banner = new BehaviorSubject([]);
  selection_banner = new SelectionModel<AppBannersClass>(true, [], true);
  @ViewChild(MatTable) banner_table: MatTable<any>;
  @ViewChild('banner_paginator') banner_paginator: MatPaginator;

  tv_channelList: AppChannelsClass[] = [];
  displayedColumns_tv_channel: string[] = ['name', 'url','logo','status', 'remove'];
  dataSource_tv_channel = new BehaviorSubject([]);
  selection_tv_channel = new SelectionModel<AppChannelsClass>(true, [], true);
  @ViewChild(MatTable) tv_channel_table: MatTable<any>;
  @ViewChild('tv_channel_paginator') tv_channel_paginator: MatPaginator;

  radio_stationList: AppChannelsClass[] = [];
  displayedColumns_radio_station: string[] = ['name', 'url','logo','status', 'remove'];
  dataSource_radio_station = new BehaviorSubject([]);
  selection_radio_station = new SelectionModel<AppChannelsClass>(true, [], true);
  @ViewChild(MatTable) radio_station_table: MatTable<any>;
  @ViewChild('radio_station_paginator') radio_station_paginator: MatPaginator;


  //Headers
  headerList: WebHeaderClass[] = [];
  displayedColumns: string[] = ['title', 'channel', 'status', 'remove'];
  dataSource = new BehaviorSubject([]);
  selection = new SelectionModel<WebHeaderClass>(true, [], true);
  @ViewChild(MatTable) competition_table: MatTable<any>;
  @ViewChild('competition_paginator') competition_paginator: MatPaginator;

  //Slide Show
  slideShowList: WebSlideShowClass[] = [];
  slideShow_displayedColumns: string[] = ['programe_name', 'channel', 'status', 'remove'];
  slideShow_dataSource = new BehaviorSubject([]);
  slideShow_selection = new SelectionModel<WebSlideShowClass>(true, [], true);

  //Programme
  progCat_display_array: WebProgCategoryClass[] = [];
  progCat_displayedColumns: string[] = ['select', 'prog_name', 'channel', 'category','youtubepromo','status'];
  progCat_dataSource: any;
  progCat_selection = new SelectionModel<WebProgCategoryClass>(true, [], true);
  @ViewChild(MatTable) progCat_table: MatTable<any>;
  @ViewChild('prog_paginator') prog_paginator: MatPaginator;

  //Schedule
  sched_display_array: WebProgScheduleClass[] = [];
  sched_displayedColumns: string[] = ['select', 'programe_name', 'channel', 'date','time','season','episode'];
  sched_dataSource: any;
  sched_selection = new SelectionModel<WebProgScheduleClass>(true, [], true);
  @ViewChild(MatTable) sched_table: MatTable<any>;
  @ViewChild('schedule_paginator') schedule_paginator: MatPaginator;

  //Users
  user_display_array: WebUserClass[] = [];
  user_displayedColumns: string[] = ['select', 'firstName', 'lastName','email', 'age','phoneNumber','country','videoQuality'];
  user_dataSource: any;
  user_selection = new SelectionModel<WebUserClass>(true, [], true);
  @ViewChild(MatTable) user_table: MatTable<any>;
  @ViewChild('users_paginator') users_paginator: MatPaginator;

  selectedFile: FileList  = null;
  landingPageFormGroup: FormGroup;
  header_class: WebHeaderClass = new WebHeaderClass();
  slideshow_class: WebSlideShowClass = new WebSlideShowClass();


  constructor(private packageService: PackageService,private campaignService: CampaignService,
              private router: Router, private alertService: AlertService, public dialog: MatDialog,
              private flightingCodeService: FlightingCodeService,private _location: Location,private httpClient: HttpClient,private formBuilder: FormBuilder) {

       
        
  }

  ngOnInit() {

    this.landingPageFormGroup = this.formBuilder.group({
      channelCtrl: [this.header_class.channel, Validators.required],
      statusCtrl: [this.header_class.status, Validators.required],
      titleCtrl: [this.header_class.title, Validators.required]});

      this.filteredOptions = this.myControl.valueChanges.pipe( 
        startWith(''),
        map(value => this._filter(value)),
      );

      this.onChannels();

      //Website
      this.readSlideshow();
      this.readHeadings();
      this.onReadProgrammes();
      this.onReadSchedule();
    
      //App
      this.onReadImages();
      this.onReadBanners();
      this.onReadTVChannel(); 
      this.onReadRadioStation();
      this.onReadUsers();
  }

  ngAfterViewInit(){
    
    //File import
    $("form").on("change", ".file-upload-field", function(){ 
      $(this).parent(".file-upload-wrapper").attr("data-text",         $(this).val().replaceAll(/.*(\/|\\)/, '') );
    });

  }

  private _filter(value: string): ProgrammeClass[] {
    const filterValue = value.toLowerCase();

    return this.display_array.filter(option => option.prog_name.toLowerCase().includes(filterValue));
  }

  applyFilter(event: Event) {
    this.user_selection.clear();
    const filterValue = (event.target as HTMLInputElement).value;
    this.user_dataSource.filter = filterValue.trim().toLowerCase();
  }

  user_isAllSelected() {
    const numSelected = this.user_selection.selected.length;
    const numRows = this.user_dataSource.data.length;
    return numSelected === numRows;
  }

  user_masterToggle() {
    this.user_isAllSelected() ?
      this.user_selection.clear() :
      this.user_dataSource.data.forEach(row => this.user_selection.select(row));
  }


  addSlideShow(): void {

    if(this.slideShowList.length < 5){
      const programme = new WebSlideShowClass();

      var myControl = new FormControl(); 
      programme.myControl = myControl;
      programme.status = "Draft";
      programme.filteredOptions = programme.myControl.valueChanges.pipe( 
        startWith(''),
        map(value => this._filter(value)),
      );

      this.slideShowList.push(programme);
      this.slideShow_dataSource.next(this.slideShowList);
    }
    else{
      this.alertService.error("You can only add up to 5 programmes !");
    }
  }


  removeSlideShow(programme: WebSlideShowClass): void {

      if(this.slideShowList.length > 1){
        this.slideShowList.splice(this.slideShowList.indexOf(programme), 1);
        this.slideShow_dataSource.next(this.slideShowList);
      }
      else{
        this.alertService.error("Add at least one programme !");
      }
  }

  selectProgramme(programme: ProgrammeClass,element: WebSlideShowClass){

    let index = this.slideShowList.indexOf(element);

    this.slideShowList[index].programme_no = programme.prog_no;
    this.slideShowList[index].programe_name = programme.prog_name;
  }

  addHeader(): void {
      const header = new WebHeaderClass();
      header.status = "Draft";

      this.headerList.push(header);
      this.dataSource.next(this.headerList);
  }

  addBanner(): void {
      const banner = new AppBannersClass();
      banner.status = "Draft";

      this.bannerList.push(banner);
      this.dataSource_banner.next(this.bannerList);
  }

  addTVChannel(): void {
      const channel = new AppChannelsClass();
      channel.status = "Draft";

      this.tv_channelList.push(channel);
      this.dataSource_tv_channel.next(this.tv_channelList);
  }


  addRadioStation(): void {
    const channel = new AppChannelsClass();
    channel.status = "Draft";

    this.radio_stationList.push(channel);
    this.dataSource_radio_station.next(this.radio_stationList);
  }


  removeHeader(header: WebHeaderClass): void {

      if(this.headerList.length > 1){
        this.headerList.splice(this.headerList.indexOf(header), 1);
        this.dataSource.next(this.headerList);
      }
      else{
        this.alertService.error("Add at least one header !");
      }
  }

  removeBanner(banner: AppBannersClass): void {

    this.bannerList.splice(this.bannerList.indexOf(banner), 1);
    this.dataSource_banner.next(this.bannerList);
  }

removeTVChannel(channel: AppChannelsClass): void {

  this.tv_channelList.splice(this.tv_channelList.indexOf(channel), 1);
  this.dataSource_tv_channel.next(this.tv_channelList);
}

removeRadioStation(channel: AppChannelsClass): void {

  this.radio_stationList.splice(this.radio_stationList.indexOf(channel), 1);
  this.dataSource_radio_station.next(this.radio_stationList);
}


selectBannerImage(event,banner: AppBannersClass){

  let index = this.bannerList.indexOf(banner);
  this.bannerList[index].upload = <FileList>event.target.files;

  if (event.target.files && event.target.files[0]) {

      let file = event.target.files[0];
      this.bannerList[index].reader.onload = e => this.bannerList[index].url = this.bannerList[index].reader.result;
      this.bannerList[index].reader.readAsDataURL(file);
  }
}
  
selectTVChannelImage(event,channel: AppChannelsClass){

  let index = this.tv_channelList.indexOf(channel);
  this.tv_channelList[index].upload = <FileList>event.target.files;

  if (event.target.files && event.target.files[0]) {

      let file = event.target.files[0];
      this.tv_channelList[index].reader.onload = e => this.tv_channelList[index].logo = this.tv_channelList[index].reader.result;
      this.tv_channelList[index].reader.readAsDataURL(file);
  }
}


  selectRadioStationImage(event,channel: AppChannelsClass){

    let index = this.radio_stationList.indexOf(channel);
    this.radio_stationList[index].upload = <FileList>event.target.files;

    if (event.target.files && event.target.files[0]) {

        let file = event.target.files[0];
        this.radio_stationList[index].reader.onload = e => this.radio_stationList[index].logo = this.radio_stationList[index].reader.result;
        this.radio_stationList[index].reader.readAsDataURL(file);
    }
  }

  isAllSelected() {
    const numSelected = this.progCat_selection.selected.length;
    const numRows = this.progCat_dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.progCat_selection.clear() :
      this.progCat_dataSource.data.forEach(row => this.progCat_selection.select(row));
  }


  sched_isAllSelected() {
    const numSelected = this.sched_selection.selected.length;
    const numRows = this.sched_dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  sched_masterToggle() {
    this.sched_isAllSelected() ?
      this.sched_selection.clear() :
      this.sched_dataSource.data.forEach(row => this.sched_selection.select(row));
  }


  async onReadSchedule(){
 
    var start_date = moment().format("YYYY/MM/DD");
    var end_date = moment().format("YYYY/MM/DD");
    
    const response = this.httpClient.post(`https://mojalove.mmsafrica.co.za/api/schedule/schedule/read_schedule.php`,
    {
      channes: this.selected_channels,
      start_date: start_date,
      end_date: end_date,

    },{ responseType: 'json'}).pipe()
    .subscribe(
      (data:any) => {

        this.sched_display_array = [];

        for(var i=0;i<data.length;i++)
        {
            this.sched_display_array[i] = {channel: '',date: data[i].date,episode: data[i].episode,programe_name: data[i].title,programme_no: data[i].prog_no,season: data[i].season,time: data[i].start_time};
        } 

        this.sched_dataSource = new MatTableDataSource<WebProgScheduleClass>(this.sched_display_array);
        this.sched_dataSource.paginator = this.schedule_paginator;
      }  
    ) 
  }

  async saveProgramme()
  {

    let proceed = true;

    for(var i=0;i<this.progCat_display_array.length;i++)
    { 
      if(this.progCat_display_array[i].web_category == null){this.progCat_display_array[i].web_category = "";}
      if(this.progCat_display_array[i].web_status == null){this.progCat_display_array[i].web_status = "";}
      if(this.progCat_display_array[i].web_youtubepromo == null){this.progCat_display_array[i].web_youtubepromo = "";}
    }
    
    if(proceed){

      const response = this.httpClient.post(`https://mojalove.mmsafrica.co.za/api/web-admin/save_programme.php`,
      {
          employee_no: 1,
          progCat_display_array: this.progCat_display_array

      },{ responseType: 'json'}).pipe()
      .subscribe(
        (data:any) => {
          
          if(data.message == 'success')
          {
            this.alertService.success("Website programmes successfully saved");
          }
          else{

            this.alertService.error(data.message);
          }
          
        },error => {

          this.alertService.error("Something went wrong, Try again");
        } 
      )
    }
    else{
    
      this.alertService.error("Complete table form to proceed !");
  }
  }

  async onReadProgrammes(){ 
      
    const response = this.httpClient.post(`https://mojalove.mmsafrica.co.za/api/web-admin/get_programme.php`,
    {
      channes: this.selected_channels,
      employee_no: 0,

    },{ responseType: 'json'}).pipe( )
    .subscribe(
      (data:any) => {
         
        this.display_array = [];
        this.progCat_display_array = [];

        for(var i=0;i<data.length;i++)
        {
            this.display_array[i] = {genre: data[i].genre,image: data[i].image,language: data[i].language,length: data[i].length,prod_house: data[i].prod_house,producer: data[i].producer,prog_name: data[i].prog_name,prog_no: Number(data[i].prog_no),bdcr_no: data[i].bdcr_no,type: data[i].type,category: data[i].category,rcrd_no: data[i].rcrd_no,repeat_name: data[i].repeat_name,sare_no: data[i].sare_no,subgenre: data[i].subgenre,season_data: data[i].season_data,genre_sentech: data[i].genre_sentech,subgenre_sentech: data[i].subgenre_sentech,
              contract_no: data[i].contract_no,
              contract_type: data[i].contract_type,
              distributors: data[i].distributors};

              this.progCat_display_array[i] = {programme_no: Number(data[i].prog_no),channel: data[i].channel,programe_name: data[i].prog_name,web_category: data[i].web_category,web_status: data[i].web_status,web_youtubepromo: data[i].web_youtubepromo};
        }

        this.progCat_dataSource = new MatTableDataSource<WebProgCategoryClass>(this.progCat_display_array);
        this.progCat_dataSource.paginator = this.prog_paginator;
      } 
    ) 
  }

  async saveSlideShow()
  {

    let proceed = true;

    for(var i=0;i<this.slideShowList.length;i++)
    { 
        if(this.slideShowList[i].channel == null || this.slideShowList[i].programe_name == null){
          proceed = false;
        }

        this.slideShowList[i].myControl = null;
        this.slideShowList[i].filteredOptions = null;
    }
    
    if(proceed){

      console.log(this.slideShowList);

      const response = this.httpClient.post(`https://mojalove.mmsafrica.co.za/api/web-admin/save_slideshow.php`,
      {
          employee_no: 1,
          slideShowList: this.slideShowList

      },{ responseType: 'json'}).pipe()
      .subscribe(
        (data:any) => {
          
          if(data.message == 'success')
          {
            this.alertService.success("Website slide show successfully saved");
            this.readSlideshow();
          }
          else{

            this.alertService.error(data.message);
          }
          
        },error => {
          this.alertService.error("Something went wrong, Try again");
        } 
      )
    }
    else{
    
      this.alertService.error("Complete table form to proceed !");
  }
  }


  async readSlideshow()
  {
    const response = this.httpClient.post(`https://mojalove.mmsafrica.co.za/api/web-admin/read_headings.php`,
    {
        employee_no: 1,

    },{ responseType: 'json'}).pipe()
    .subscribe(
      (data:any) => {
        
        this.slideShowList = [];

        for(var i=0;i<data.length;i++)
        { 
            var myControl = new FormControl(); 
            myControl.setValue(data[i].programe_name);

            var filteredOptions = myControl.valueChanges.pipe( 
              startWith(''),
              map(value => this._filter(value)),
            );

            this.slideShowList[i] = {idNo: data[i].idNo,channel: data[i].channel,status: data[i].status,programe_name: data[i].programe_name,programme_no: data[i].programme_no,filteredOptions: filteredOptions,myControl: myControl};
        }

        if(this.slideShowList.length == 0){
          this.addSlideShow();
        }
        else{
          this.slideShow_dataSource.next(this.slideShowList);
        }
        
      },error => {

        this.alertService.error("Something went wrong, Try again");
      } 
    )
  }

  async saveHeadings()
  {

    let proceed = true;

    for(var i=0;i<this.headerList.length;i++)
    { 
        if(this.headerList[i].channel == null || this.headerList[i].title == null){
          proceed = false;
        }
    }
    
    if(proceed){

      const response = this.httpClient.post(`https://mojalove.mmsafrica.co.za/api/web-admin/save_headings.php`,
      {
          employee_no: 1,
          headerList: this.headerList

      },{ responseType: 'json'}).pipe()
      .subscribe(
        (data:any) => {
          
          if(data.message == 'success')
          {
            this.alertService.success("Website headings successfully saved");
            this.readHeadings();
          }
          else{

            this.alertService.error(data.message);
          }
          
        },error => {

          this.alertService.error("Something went wrong, Try again");
        } 
      )
    }
    else{
    
      this.alertService.error("Complete table form to proceed !");
  }
  }


  async readHeadings()
  {
    const response = this.httpClient.post(`https://mojalove.mmsafrica.co.za/api/web-admin/read_headings.php`,
    {
        employee_no: 1,

    },{ responseType: 'json'}).pipe()
    .subscribe(
      (data:any) => {
        
        this.headerList = [];

        for(var i=0;i<data.length;i++)
        { 
            this.headerList[i] = {idNo: data[i].idNo,channel: data[i].channel,status: data[i].status,title: data[i].title};
        }

        if(this.headerList.length == 0){
          this.addHeader();
        }
        else{
          this.dataSource.next(this.headerList);
        }
        
      },error => {

        this.alertService.error("Something went wrong, Try again");
      } 
    )
  }
 

  openDeleteCampaignDialog(): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      data: 'Are you sure you want to delete campaign ?'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {

        this.deleteCampaign(this.campaign.campaignId);
      }
    });
  }

  async deleteCampaign(campaignNo)
  {
    this.httpClient.get(`https://mojalove.mmsafrica.co.za/api/sales/delete_campaign.php?campaignNo=${campaignNo}`).pipe()
    .subscribe(
      (data:any) =>{

        this.campaignService.getCampaignsFromRemote().subscribe(campaigns => {

        }); 
        
        this.alertService.success("Campaign successfully deleted !");
        localStorage.removeItem('selectedCampaign');
        localStorage.removeItem('createdPackage');
        this.router.navigate(['/home/sales']);
        
      },error => {
        this.alertService.error("Something went wrong, Try again");
      }
    )
  }
  
  onFileSelected(event){
    
    this.selectedFile  = null;
    this.selectedFile = <FileList>event.target.files;
  }

  async onChannels(){ 
      
    const response = this.httpClient.post(`https://mojalove.mmsafrica.co.za/api/schedule/get_channels.php`,
    {
      channes: this.selected_channels,
      date: moment().format("YYYY/MM/DD"),
      employee_no: 0,

    },{ responseType: 'json'}).pipe( )
    .subscribe(
      (data:any) => {
         
        for(var i=0;i<data.length;i++)
        {
            this.channels[i] = {bdcr_no: data[i].bdcr_no,genre: data[i].genre,platform: data[i].platform,sare_name: data[i].sare_name,sare_no: data[i].sare_no};
        }

      } 
    ) 
  }

  //APP
  async onReplaceDefaultImage(event,position)
  {
    this.selectedImage = <File>event.target.files[0];

    const formData = new FormData();
    formData.append('file',this.selectedImage,this.selectedImage.name);
    formData.append('position',<any>position);

    this.httpClient.post('https://mojalove.mmsafrica.co.za/api/web-admin/app/upload_image.php',formData).subscribe((message:any) => {
      
     
      if(message.message == 'success')
      {
        this.onReadImages(); 
      }
      else
      { 
        this.alertService.error(message.message);
      }

    },error => {
        this.alertService.error("Something went wrong, Try again");
    });
  }

  openDeleteDialog(): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      data: 'Are you sure you want to delete the selected account(s) ?'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {

        this.user_selection.selected.forEach(user => {          
          this.deleteUser(user.idNo);
        });
        
        this.user_selection.clear(); 
        this.alertService.success('Account(s) successfully deleted')
      }
    });
  }

  async deleteUser(userId)
  {
    this.httpClient.get(`https://mojalove.mmsafrica.co.za/api/web-admin/app/delete_user.php?userId=${userId}`).pipe()
    .subscribe(
      (data:any) =>{

        this.onReadUsers();
      },error => {
        this.alertService.error("Something went wrong, Try again");
      }
    )
  }

  async onReadUsers()
  {
    this.httpClient.get(`https://mojalove.mmsafrica.co.za/api/web-admin/app/read_app_users.php`).pipe()
    .subscribe(
      (data:any) =>{

        this.user_display_array = [];

        for(var i=0;i<data.length;i++)
        {
            this.user_display_array[i] = {age: data[i].age,country: data[i].country,email: data[i].email,firstName: data[i].firstName,idNo: data[i].idNo,lastName: data[i].lastName,new_email: '',password: '',phoneNumber: data[i].phoneNumber,validation_key: '',videoQuality: data[i].videoQuality};
        }
        
        this.user_dataSource = new MatTableDataSource<WebUserClass>(this.user_display_array);
        this.user_dataSource.paginator = this.users_paginator;

      },error => {
        this.alertService.error("Something went wrong, Try again");
      }
    )
  }

  async onReadImages()
  {
    this.httpClient.get(`https://mojalove.mmsafrica.co.za/api/web-admin/app/read_app_images.php`).pipe()
    .subscribe(
      (data:any) =>{

        if(data[0].position == 'logo'){

          this.imageSrc_logo = data[0].url;
          this.imageSrc_background = data[1].url;
        }
        else{

          this.imageSrc_logo = data[1].url;
          this.imageSrc_background = data[0].url;
        }
        
        
      },error => {
        this.alertService.error("Something went wrong, Try again");
      }
    )
  }

  async saveBanners(){

    for (var i = 0; i < this.bannerList.length; i++) {

      this.bannerList[i].reader = null;
      if(this.bannerList[i].upload != null)
      {
        for (var x = 0; x < this.bannerList[i].upload.length; x++) {
          this.formData.append('fileToUpload[]',this.bannerList[i].upload[x],this.bannerList[i].upload[x].name);
        }
      }
    }

    this.bannerList.forEach((object, index) => {
        for (const key in object) {
          if (object.hasOwnProperty(key)) {
            this.formData.append(`bannerList[${index}][${key}]`, object[key]);
          }
        }
      });

    this.httpClient.post('https://mojalove.mmsafrica.co.za/api/web-admin/app/save_banners.php',this.formData,{ responseType: 'json'})
    .subscribe((data:any) => {

      if(data.message == 'success')
      {
        this.alertService.success("Banner successfully saved");
        this.formData = new FormData();
        this.onReadBanners(); 
      }
      else{

        this.alertService.error(data.message);
      } 

    });
  }

  async saveTVChannel(){

    for (var i = 0; i < this.tv_channelList.length; i++) {

      this.tv_channelList[i].reader = null;
      if(this.tv_channelList[i].upload != null)
      {
        for (var x = 0; x < this.tv_channelList[i].upload.length; x++) {
          this.formData.append('fileToUpload[]',this.tv_channelList[i].upload[x],this.tv_channelList[i].upload[x].name);
        }
      }
    }

    this.tv_channelList.forEach((object, index) => {
        for (const key in object) {
          if (object.hasOwnProperty(key)) {
            this.formData.append(`tv_channelList[${index}][${key}]`, object[key]);
          }
        }
      });

    this.httpClient.post('https://mojalove.mmsafrica.co.za/api/web-admin/app/save_tv_channel.php',this.formData,{ responseType: 'json'})
    .subscribe((data:any) => {

      if(data.message == 'success')
      {
        this.alertService.success("Channel successfully saved");
        this.formData = new FormData();
        this.onReadTVChannel(); 
      }
      else{

        this.alertService.error(data.message);
      } 

    });
  }


  async saveRadioStation(){

    for (var i = 0; i < this.radio_stationList.length; i++) {

      this.radio_stationList[i].reader = null;
      if(this.radio_stationList[i].upload != null)
      {
        for (var x = 0; x < this.radio_stationList[i].upload.length; x++) {
          this.formData.append('fileToUpload[]',this.radio_stationList[i].upload[x],this.radio_stationList[i].upload[x].name);
        }
      }
    }

    this.radio_stationList.forEach((object, index) => {
        for (const key in object) {
          if (object.hasOwnProperty(key)) {
            this.formData.append(`radio_stationList[${index}][${key}]`, object[key]);
          }
        }
      });

    this.httpClient.post('https://mojalove.mmsafrica.co.za/api/web-admin/app/save_radion_station.php',this.formData,{ responseType: 'json'})
    .subscribe((data:any) => {

      if(data.message == 'success')
      {
        this.alertService.success("Channel successfully saved");
        this.formData = new FormData();
        this.onReadRadioStation(); 
      }
      else{

        this.alertService.error(data.message);
      } 

    });
  }

  async onReadRadioStation()
  {
    this.httpClient.get(`https://mojalove.mmsafrica.co.za/api/web-admin/app/read_app_radiostation.php`).pipe()
    .subscribe(
      (data:any) =>{

        this.radio_stationList = [];

        for (var i = 0; i < data.length; i++) {

          this.radio_stationList[i] = {idNo: data[i].idNo,reader: new FileReader(),status: data[i].status,upload: null,url: data[i].url,logo: data[i].logo,name: data[i].name}
        }

        if(this.radio_stationList.length == 0){
          this.addRadioStation();
        }
        else{
          this.dataSource_radio_station.next(this.radio_stationList);
        }
        
      },error => {
        this.alertService.error("Something went wrong, Try again");
      }
    )
  }

  async onReadTVChannel()
  {
    this.httpClient.get(`https://mojalove.mmsafrica.co.za/api/web-admin/app/read_app_tvchannel.php`).pipe()
    .subscribe(
      (data:any) =>{

        this.tv_channelList = [];

        for (var i = 0; i < data.length; i++) {

          this.tv_channelList[i] = {idNo: data[i].idNo,reader: new FileReader(),status: data[i].status,upload: null,url: data[i].url,logo: data[i].logo,name: data[i].name}
        }

        if(this.tv_channelList.length == 0){
          this.addTVChannel();
        }
        else{
          this.dataSource_tv_channel.next(this.tv_channelList);
        }
        
      },error => {
        this.alertService.error("Something went wrong, Try again");
      }
    )
  }


  async onReadBanners()
  {
    this.httpClient.get(`https://mojalove.mmsafrica.co.za/api/web-admin/app/read_app_banners.php`).pipe()
    .subscribe(
      (data:any) =>{

        this.bannerList = [];

        for (var i = 0; i < data.length; i++) {

          this.bannerList[i] = {idNo: data[i].idNo,position: data[i].position,reader: new FileReader(),status: data[i].status,title: data[i].title,upload: null,url: data[i].url}
        }

        if(this.bannerList.length == 0){
          this.addBanner();
        }
        else{
          this.dataSource_banner.next(this.bannerList);
        }
        
      },error => {
        this.alertService.error("Something went wrong, Try again");
      }
    )
  }

  returnToDashboard() {

    this.router.navigate(['/home']);
  }

  back(){
    this._location.back();
  }
}
