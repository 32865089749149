import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl,NgForm} from '@angular/forms';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {SelectionModel} from '@angular/cdk/collections';
import {MenuItem,TreeNode} from 'primeng/api';
import {TreeDragDropService} from 'primeng/api';
import {ScheduleClass} from '../../classes/schedule';
import { SeasonClass } from '../../classes/season';
import { EpisodeClass } from '../../classes/episode';
import {SareClass} from '../../classes/sare';
import {ProgrammeClass} from '../../classes/programme';
import { saveAs } from 'file-saver';
import {Location} from '@angular/common';

import { FullCalendarComponent } from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin, { Draggable } from '@fullcalendar/interaction'; 

//Import used to make HTTP get and post requests
import { HttpClient } from '@angular/common/http';
import { LoadingController } from '@ionic/angular'; 
import { finalize } from 'rxjs/operators';
 
//To use autcomplete field
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { NavController } from '@ionic/angular';

//Toasta
import {AlertService} from '../../services/alert/alert.service';
import { Router, ActivatedRoute } from '@angular/router';

import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MatDatepicker} from '@angular/material/datepicker';

export const MY_FORMATS = {
  parse: {
    dateInput: 'MMM-YYYY',
  },
  display: {
    dateInput: 'MMM-YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

import * as _moment from 'moment';
import {default as _rollupMoment, Moment} from 'moment';
const moment = _rollupMoment || _moment;

@Component({
  selector: 'app-exports',
  templateUrl: './exports.component.html',
  styleUrls: ['./exports.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
  
    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class ExportsComponent implements OnInit {

  isLinear: true;
  channels: SareClass[] = [];
  formData = new FormData();

  spotsFormGroup: FormGroup;
  breaksFormGroup: FormGroup;
  programmeFormGroup: FormGroup;
  scheduleFormGroup: FormGroup;
  episodicFormGroup: FormGroup;
  playlistFormGroup: FormGroup;
  icasaFormGroup: FormGroup;
  
  employee_no: number = 0;
  selected_channel:string = "1";

  chk_email = true;
  chk_export = true;
  chk_download = true;
  chk_attach = false;
  chk_highlight = false;
  start_date: String = moment().format("YYYY-MM-DD");
  end_date: String = moment().format("YYYY-MM-DD");
  start_time: String = "00:00";
  end_time: String = "23:59";
  export_format: String = 'excel';
  search_text: String = '';

  chk_sentech: boolean = false;
  chk_multichoice: boolean = false;
  chk_inxprime: boolean = false;

  prog_skipped: number = 0; 
  prog_saved: number = 0;
  sea_skipped: number = 0;
  sea_saved: number = 0;
  epi_skipped: number = 0;
  epi_saved: number = 0;

  //Icasa
  icasa_form_type: String = 'form8a';
  as_of_date = new FormControl(moment());

  constructor(private alertService: AlertService,route:ActivatedRoute,private router: Router,private formBuilder: FormBuilder,private httpClient: HttpClient,private loadingCtrl: LoadingController,private navCtrl: NavController,public dialog: MatDialog,private _location: Location) 
  {
    this.employee_no = parseInt(JSON.parse(localStorage.getItem('employee_no')));
    this.onChannels();
  }
 
  ngOnInit() {

    this.icasaFormGroup = this.formBuilder.group({
      selected_channelCtrl: [this.selected_channel,Validators.required],
      end_dateCtrl: [this.end_date,Validators.required],
      start_timeCtrl: [this.start_time,Validators.required],
      end_timeCtrl: [this.end_time,Validators.required],
      icasa_form_typeCtrl: [this.icasa_form_type],
    });


    this.breaksFormGroup = this.formBuilder.group({
      chk_downloadCtrl: [this.chk_download,Validators.required],
      chk_emailCtrl: [this.chk_email,Validators.required],
      chk_exportCtrl: [this.chk_export,Validators.required],
      chk_attachCtrl: [this.chk_attach,Validators.required],
      selected_channelCtrl: [this.selected_channel,Validators.required],
      start_dateCtrl: [this.start_date,Validators.required],
      end_dateCtrl: [this.end_date,Validators.required],
      start_timeCtrl: [this.start_time,Validators.required],
      end_timeCtrl: [this.end_time,Validators.required],
      export_formatCtrl: [this.export_format],
    });

    this.programmeFormGroup = this.formBuilder.group({
      chk_downloadCtrl: [this.chk_download,Validators.required],
      chk_emailCtrl: [this.chk_email,Validators.required],
      chk_exportCtrl: [this.chk_export,Validators.required],
      chk_attachCtrl: [this.chk_attach,Validators.required],
      selected_channelCtrl: [this.selected_channel,Validators.required],
      search_textCtrl: [this.search_text],
    });
    
    this.scheduleFormGroup = this.formBuilder.group({
      chk_downloadCtrl: [this.chk_download,Validators.required],
      chk_emailCtrl: [this.chk_email,Validators.required],
      chk_exportCtrl: [this.chk_export,Validators.required],
      chk_attachCtrl: [this.chk_attach,Validators.required],
      selected_channelCtrl: [this.selected_channel,Validators.required],
      chk_highlightCtrl: [this.chk_highlight,Validators.required],
      export_formatCtrl: [this.export_format,Validators.required],
      start_dateCtrl: [this.start_date,Validators.required],
      end_dateCtrl: [this.end_date,Validators.required],
      start_timeCtrl: [this.start_time,Validators.required],
      end_timeCtrl: [this.end_time,Validators.required],
      chk_sentechCtrl: [this.chk_sentech],
      chk_multichoiceCtrl: [this.chk_multichoice],
      chk_inxprimeCtrl: [this.chk_inxprime],
    });
    
  }


  //As Of dates
  chosenYearHandler(normalizedYear: Moment) {
    const ctrlValue = this.as_of_date.value;
    ctrlValue.year(normalizedYear.year());
    this.as_of_date.setValue(ctrlValue);
  }

  chosenMonthHandler(normalizedMonth: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.as_of_date.value;
    ctrlValue.month(normalizedMonth.month());
    this.as_of_date.setValue(ctrlValue);
    datepicker.close();
  }

  async exportIcasaReport(){

    var strstart_date = moment(this.as_of_date.value.toString()).format("YYYY-MM")+'-01';
    var strstart_time = this.start_time.toString()+":00";
    var strend_time = this.end_time.toString()+":00";

    this.formData.append('employee_no',<any>this.employee_no);
    this.formData.append('selected_channel',<any>this.selected_channel);
    this.formData.append('strstart_date',<any>strstart_date);
    this.formData.append('strend_date',<any>strstart_date);
    this.formData.append('strstart_time',<any>strstart_time);
    this.formData.append('strend_time',<any>strend_time);
    this.formData.append('export_format',<any>this.icasa_form_type);

    this.httpClient.post('https://mojalove.mmsafrica.co.za/api/schedule/schedule_files/export_icasa_report.php',this.formData,{ responseType: 'json'})
    .subscribe((data:any) => {

      if(data.message == 'success')
      {
        this.alertService.success('Icasa Report successfully exported');

        var files = data.download_files.split(";");

        for(var i=0;i<(files.length-1);i++)
        {
          var fileName = files[i].split("/")[1];
          this.export(files[i]).subscribe(data => saveAs(data, fileName)); 
        }
 
      }
      else
      {
        this.alertService.error(data.message);
      }

    });
  }

  async exportSpots(){

    var strstart_date = moment(this.start_date.toString()).format("YYYY/MM/DD");
    var strend_date = moment(this.end_date.toString()).format("YYYY/MM/DD");

    var strstart_time = this.start_time.toString()+":00";
    var strend_time = this.end_time.toString()+":00";

    this.formData.append('employee_no',<any>this.employee_no);
    this.formData.append('selected_channel',<any>this.selected_channel);
    this.formData.append('chk_download',<any>this.chk_download);
    this.formData.append('chk_email',<any>this.chk_email);
    this.formData.append('chk_export',<any>this.chk_export);
    this.formData.append('chk_attach',<any>this.chk_attach);
    this.formData.append('strstart_date',<any>strstart_date);
    this.formData.append('strend_date',<any>strend_date);
    this.formData.append('strstart_time',<any>strstart_time);
    this.formData.append('strend_time',<any>strend_time);

    this.httpClient.post('https://mojalove.mmsafrica.co.za/api/schedule/schedule_files/export_spots.php',this.formData,{ responseType: 'json'})
    .subscribe((data:any) => {


      if(data.message == 'success')
      {
        this.alertService.success('Schedule spots successfully exported');

        var files = data.download_files.split(";");

        for(var i=0;i<(files.length-1);i++)
        {
          this.export(files[i]).subscribe(data => saveAs(data, 'Spots Export.xlsx')); 
        }
 
      }
      else
      {
        this.alertService.error(data.message);
      }

    });
  }

  exportPlaylist(){

    if(this.export_format == 'excel'){
      this.exportPlaylistExcel();
  }
  else{
      this.exportPlaylistExcel();
  }
  }

  async exportPlaylistExcel(){

    var strstart_date = moment(this.start_date.toString()).format("YYYY/MM/DD");
    var strstart_time = this.start_time.toString()+":00";
    var strend_time = this.end_time.toString()+":00";

    this.formData.append('employee_no',<any>this.employee_no);
    this.formData.append('selected_channel',<any>this.selected_channel);
    this.formData.append('chk_download',<any>this.chk_download);
    this.formData.append('chk_email',<any>this.chk_email);
    this.formData.append('chk_export',<any>this.chk_export);
    this.formData.append('chk_attach',<any>this.chk_attach);
    this.formData.append('strstart_date',<any>strstart_date);
    this.formData.append('strend_date',<any>strstart_date);
    this.formData.append('strstart_time',<any>strstart_time);
    this.formData.append('strend_time',<any>strend_time);
    this.formData.append('export_format',<any>this.export_format);

    this.httpClient.post('https://mojalove.mmsafrica.co.za/api/schedule/transmission/export_playlist.php',this.formData,{ responseType: 'json'})
    .subscribe((data:any) => {
      

      if(data.message == 'success')
      {
        this.alertService.success('Playlist successfully exported');

        var files = data.download_files.split(";");

        for(var i=0;i<(files.length-1);i++)
        {
          var fileName = files[i].split("/")[1];
          this.export_plst(files[i]).subscribe(data => saveAs(data, fileName)); 
        }
 
      }
      else
      {
        this.alertService.error(data.message);
      }

    });
  }

  async exportBreaks(){

    var strstart_date = moment(this.start_date.toString()).format("YYYY/MM/DD");
    var strend_date = moment(this.end_date.toString()).format("YYYY/MM/DD");

    var strstart_time = this.start_time.toString()+":00";
    var strend_time = this.end_time.toString()+":00";

    this.formData.append('employee_no',<any>this.employee_no);
    this.formData.append('selected_channel',<any>this.selected_channel);
    this.formData.append('chk_download',<any>this.chk_download);
    this.formData.append('chk_email',<any>this.chk_email);
    this.formData.append('chk_export',<any>this.chk_export);
    this.formData.append('chk_attach',<any>this.chk_attach);
    this.formData.append('strstart_date',<any>strstart_date);
    this.formData.append('strend_date',<any>strend_date);
    this.formData.append('strstart_time',<any>strstart_time);
    this.formData.append('strend_time',<any>strend_time);

    this.httpClient.post('https://mojalove.mmsafrica.co.za/api/schedule/schedule_files/export_breaks.php',this.formData,{ responseType: 'json'})
    .subscribe((data:any) => {
     
      if(data.message == 'success')
      {
        this.alertService.success('Schedule breaks successfully exported');

        var files = data.download_files.split(";");

        for(var i=0;i<(files.length-1);i++)
        {
          this.export(files[i]).subscribe(data => saveAs(data, 'Break Export.xlsx')); 
        }
 
      }
      else
      {
        this.alertService.error(data.message);
      }

    });
  }

  exportSchedule(){

    if(this.export_format == 'excel'){
        this.exportScheduleExcel();
    }
    else{
      
        if(this.chk_multichoice == true || this.chk_sentech == true || this.chk_inxprime == true){
          if(this.chk_multichoice == true){
            this.exportScheduleXML('export_schedule_xml.php');
          }
  
          if(this.chk_sentech == true){
            this.exportScheduleXML('export_sentech_schedule_xml.php');
          }

          if(this.chk_inxprime == true){
            this.exportScheduleXML('export_app_schedule_xml.php');
          }
        } 
        else{
          this.alertService.error("Select at least one media type !");
        }
    }
  }

  async exportScheduleXML(apiFile){

    var strstart_date = moment(this.start_date.toString()).format("YYYY/MM/DD");
    var strend_date = moment(this.end_date.toString()).format("YYYY/MM/DD");

    var strstart_time = this.start_time.toString()+":00";
    var strend_time = this.end_time.toString()+":00";

    this.formData.append('employee_no',<any>this.employee_no);
    this.formData.append('selected_channel',<any>this.selected_channel);
    this.formData.append('chk_download',<any>this.chk_download);
    this.formData.append('chk_email',<any>this.chk_email);
    this.formData.append('chk_export',<any>this.chk_export);
    this.formData.append('chk_attach',<any>this.chk_attach);
    this.formData.append('chk_highlight',<any>this.chk_highlight);
    this.formData.append('export_format',<any>this.export_format);
    this.formData.append('strstart_date',<any>strstart_date);
    this.formData.append('strend_date',<any>strend_date);
    this.formData.append('strstart_time',<any>strstart_time);
    this.formData.append('strend_time',<any>strend_time);

    this.httpClient.post('https://mojalove.mmsafrica.co.za/api/schedule/schedule_files/'+apiFile,this.formData,{ responseType: 'json'})
    .subscribe((data:any) => {
      

      if(data.message == 'success')
      {
        this.alertService.success('Programme schedule successfully exported');

        var files = data.download_files.split(";");

        for(var i=0;i<(files.length-1);i++)
        {
          this.export(files[i]).subscribe(data => saveAs(data, 'programme schedule.xml')); 
        }
 
      }
      else
      {
        this.alertService.error(data.message);
      }

    });
  }

  async exportScheduleExcel(){

    var strstart_date = moment(this.start_date.toString()).format("YYYY/MM/DD");
    var strend_date = moment(this.end_date.toString()).format("YYYY/MM/DD");

    var strstart_time = this.start_time.toString()+":00";
    var strend_time = this.end_time.toString()+":00";

    this.formData.append('employee_no',<any>this.employee_no);
    this.formData.append('selected_channel',<any>this.selected_channel);
    this.formData.append('chk_download',<any>this.chk_download);
    this.formData.append('chk_email',<any>this.chk_email);
    this.formData.append('chk_export',<any>this.chk_export);
    this.formData.append('chk_attach',<any>this.chk_attach);
    this.formData.append('chk_highlight',<any>this.chk_highlight);
    this.formData.append('export_format',<any>this.export_format);
    this.formData.append('strstart_date',<any>strstart_date);
    this.formData.append('strend_date',<any>strend_date);
    this.formData.append('strstart_time',<any>strstart_time);
    this.formData.append('strend_time',<any>strend_time);

    this.httpClient.post('https://mojalove.mmsafrica.co.za/api/schedule/schedule_files/export_schedule.php',this.formData,{ responseType: 'json'})
    .subscribe((data:any) => {
      

      if(data.message == 'success')
      {
        this.alertService.success('Programme schedule successfully exported');

        var files = data.download_files.split(";");

        for(var i=0;i<(files.length-1);i++)
        {
          this.export(files[i]).subscribe(data => saveAs(data, 'Schedule Export.xlsx')); 
        }
 
      }
      else
      {
        this.alertService.error(data.message);
      }

    });
  }

  async exportEpisodic(){

    this.formData.append('employee_no',<any>this.employee_no);
    this.formData.append('selected_channel',<any>this.selected_channel);
    this.formData.append('chk_download',<any>this.chk_download);
    this.formData.append('chk_email',<any>this.chk_email);
    this.formData.append('chk_export',<any>this.chk_export);
    this.formData.append('chk_attach',<any>this.chk_attach);
    this.formData.append('search_text',<any>this.search_text);

    this.httpClient.post('https://mojalove.mmsafrica.co.za/api/schedule/schedule_files/export_episodic.php',this.formData,{ responseType: 'json'})
    .subscribe((data:any) => {
      
      if(data.message == 'success')
      {
        this.alertService.success('Episodic successfully exported');

        var files = data.download_files.split(";");

        for(var i=0;i<(files.length-1);i++)
        {
          this.export(files[i]).subscribe(data => saveAs(data, 'Episodic Export.xlsx')); 
        }
 
      }
      else
      {
        this.alertService.error(data.message);
      }

    });
  }

  async exportProgrammes(){
   
    this.formData.append('employee_no',<any>this.employee_no);
    this.formData.append('selected_channel',<any>this.selected_channel);
    this.formData.append('chk_download',<any>this.chk_download);
    this.formData.append('chk_email',<any>this.chk_email);
    this.formData.append('chk_export',<any>this.chk_export);
    this.formData.append('chk_attach',<any>this.chk_attach);
    this.formData.append('search_text',<any>this.search_text);

    this.httpClient.post('https://mojalove.mmsafrica.co.za/api/schedule/schedule_files/export_programmes.php',this.formData,{ responseType: 'json'})
    .subscribe((data:any) => {
      
      if(data.message == 'success')
      {
        this.alertService.success('Programmes successfully exported');

        var files = data.download_files.split(";");

        for(var i=0;i<(files.length-1);i++)
        {
          this.export(files[i]).subscribe(data => saveAs(data, 'Programme Export.xlsx')); 
        }
 
      }
      else
      {
        this.alertService.error(data.message);
      }

    });
  }

  export(name) {
    return this.httpClient.get(`https://mojalove.mmsafrica.co.za/api/schedule/schedule_files/download_file.php?file_name=${name}`, 
      {responseType: 'blob'});
  }

  export_plst(name) {
    return this.httpClient.get(`https://mojalove.mmsafrica.co.za/api/schedule/transmission/download_file.php?file_name=${name}`, 
      {responseType: 'blob'});
  }

  async onChannels(){ 

      
    const response = this.httpClient.post(`https://mojalove.mmsafrica.co.za/api/schedule/get_channels.php`,
    {
      channes: this.selected_channel,
      date: moment().format("YYYY/MM/DD"),
      employee_no: this.employee_no,

    },{ responseType: 'json'}).pipe()
    .subscribe(
      (data:any) => {
         
        for(var i=0;i<data.length;i++)
        {
            this.channels[i] = {bdcr_no: data[i].bdcr_no,genre: data[i].genre,platform: data[i].platform,sare_name: data[i].sare_name,sare_no: data[i].sare_no};
        }

      } 
    ) 
  }

  back(){
    this._location.back();
  }

}
