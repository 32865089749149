import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { EpisodeDialogClass } from 'src/app/classes/episode.dialog';

//Import used to make HTTP get and post requests
import { HttpClient } from '@angular/common/http';
import {AlertService} from '../../services/alert/alert.service';

import * as _moment from 'moment';
import {default as _rollupMoment, Moment} from 'moment';
import { SegmentClass } from 'src/app/classes/segment';
import { AddNewEpisode } from 'src/app/classes/addNewEpisodes';
const moment = _rollupMoment || _moment;


@Component({
  selector: 'app-add-new-episode-dialog',
  templateUrl: './add-new-episode-dialog.component.html',
  styleUrls: ['./add-new-episode-dialog.component.scss'],
})
export class AddNewEpisodeDialogComponent implements OnInit {

  editFormGroup: FormGroup; 
  episodeData: AddNewEpisode = new AddNewEpisode();

  constructor(private formBuilder: FormBuilder,public dialogRef: MatDialogRef<AddNewEpisodeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AddNewEpisode,
    private httpClient: HttpClient,
    private alertService: AlertService) { 

      this.episodeData = this.data;
    }

  ngOnInit() {

    this.editFormGroup = this.formBuilder.group({
      first_episodeCtrl: [this.episodeData.first_episode,Validators.required],
      programme_nameCtrl: [this.episodeData.programme_name,Validators.required],
      numer_of_episodesCtrl: [this.episodeData.numer_of_episodes,Validators.required],
      prog_noCtrl: [this.episodeData.prog_no,Validators.required],
      seas_noCtrl: [this.episodeData.seas_no,Validators.required],
      blnIncrementCtrl: [this.episodeData.blnIncrement,Validators.required],
    });

  } 

  cancel(): void {
    this.dialogRef.close();
  }
}