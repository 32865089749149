import {Component, OnInit, ViewChild} from '@angular/core';
import {FormGroup, FormBuilder, Validators, FormControl, NgForm} from '@angular/forms';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {SelectionModel} from '@angular/cdk/collections';
import {MenuItem, TreeNode} from 'primeng/api';
import {TreeDragDropService} from 'primeng/api';
import {ScheduleClass} from '../../classes/schedule';
import {SareClass} from '../../classes/sare';
import {ProgrammeClass} from '../../classes/programme';
import {ContractClass} from '../../classes/acquisition';
import {LanguageClass} from '../../classes/language';
import {PGClass} from '../../classes/parentalguidance';
import {ContractListClass} from '../../classes/contractlist';
import {GenreClass} from '../../classes/genre';
import {SeasonClass} from '../../classes/season';
import {UserClass} from '../../classes/users';
import {CategoryClass} from '../../classes/categories';
import {SubGenreClass} from '../../classes/subgenre';
import {Location} from '@angular/common';
import * as currencies from '../../commom/currency.json';

// Import used to make HTTP get and post requests
import {HttpClient} from '@angular/common/http'; 
import {LoadingController} from '@ionic/angular';
import {finalize} from 'rxjs/operators';

// To use autcomplete field
import {BehaviorSubject, Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import {NavController} from '@ionic/angular';

// Toasta
import {Router, ActivatedRoute} from '@angular/router';
import {AlertService} from '../../services/alert/alert.service';

export const MY_FORMATS = {
    parse: {
        dateInput: 'DD/MM/YYYY',
    },
    display: {
        dateInput: 'DD/MM/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

import * as _moment from 'moment';
import {default as _rollupMoment} from 'moment';
import {RightsManagement} from '../../classes/rightsManagement';
import {BulkProgramme} from '../../classes/bulkProgramme';
import { RegionClass } from 'src/app/classes/regions';
import { DistributorClass } from 'src/app/classes/distributor';
import { Demographics } from 'src/app/classes/demographics.class';
import { SetupClass } from 'src/app/classes/setup';

const moment = _rollupMoment || _moment;

@Component({
  selector: 'app-bulk-programme',
  templateUrl: './bulk-programme.component.html',
  styleUrls: ['./bulk-programme.component.scss'],
})
export class BulkProgrammeComponent implements OnInit {

  employee_no = 0;
  isLinear = true;
  cur_obj: any = (currencies as any).default;
  setupClass:SetupClass = new SetupClass();
  currency: string[] = [];

  programmeFormGroup: FormGroup;

  selected_channel = 0;
  parentalG: '';
  channels: SareClass[] = [];
  genres: GenreClass[] = []; 
  languages: LanguageClass[] = [];
  contractTypes: ContractListClass[] = [];
  pgs: PGClass[] = [];
  users: UserClass[] = [];
  categories: CategoryClass[] = []; 
  subgenries: SubGenreClass[] = []; 
  selected_subgenries: SubGenreClass[] = [];
  regions: RegionClass[] = [];
  type_of_rights: CategoryClass[] = [];
  media_types: CategoryClass[] = [];
  distributors_array: DistributorClass[] = [];
  demographics_array: Demographics[] = [];

  sentech_genres: GenreClass[] = [];
  sentech_subgenries: SubGenreClass[] = [];
  selected_sentech_subgenries: SubGenreClass[] = [];

  programme: BulkProgramme = new BulkProgramme();
  contract: ContractClass = new ContractClass();
  season: SeasonClass = new SeasonClass();
  rightsManagement: RightsManagement = new RightsManagement();
  distributors = 0;
  internalCategory = '';
  targetDemographic = 0;
  scheduleTime = '';
  currency_code = 'ZAR';
  licence_fee_Sidebar: boolean = false;


  display_array: SeasonClass[] = [];
  displayedColumns: string[] = ['title', 'length', 'season', 'episodes','segments','rating', 'censorship','synopsis', 'genre',
      'subGenre','genre_sentech','subgenre_sentech', 'YOR', 'producer', 'actors', 'directors','hashtag', 'removeProgramme'];
  dataSource = new BehaviorSubject([]);
  selection = new SelectionModel<SeasonClass>(true, []);
  programmes: BulkProgramme[];
  seasons: SeasonClass[];

  formData = new FormData(); 

  constructor(private alertService: AlertService, route: ActivatedRoute,
              private router: Router, private formBuilder: FormBuilder,
              private httpClient: HttpClient, private loadingCtrl: LoadingController,
              private navCtrl: NavController, public dialog: MatDialog, private _location: Location) {
      this.employee_no = parseInt(JSON.parse(localStorage.getItem('employee_no')));
      this.contract.contract_no = moment().format('YYYY-MM-DD Hmmss');
      this.programmes = [];
      this.rightsManagement.exclusive = false;
      this.rightsManagement.unlimitedPlays = false;
      this.rightsManagement.unlimitedTxPerEpi = false;
      this.rightsManagement.boughtAt = 1;

      this.onChannels();
      this.onReadProgOptions();
      this.addProgramme();
  }

  ngOnInit() {
    
      this.programmeFormGroup = this.formBuilder.group({
          channelsCtrl: [this.selected_channel],
          distributorsCtrl: [this.distributors],
          internalCategoryCtrl: [this.internalCategory],
          targetDemographicCtrl: [this.targetDemographic],
          costPerEpisodeCtrl: [this.rightsManagement.costPerEpisode],
          costPerEpisode_InRandCtrl: [this.rightsManagement.costPerEpisode_InRand],
          scheduleTimeCtrl: [this.scheduleTime], 
          contractNoCtrl: [this.contract.contract_no],
          progNameCtrl: [this.programme.prog_name],
          prdHouseCtrl: [this.programme.prod_house],
          prdPartnerCtrl: [this.contract.prod_partner],
          progLengthCtrl: [this.programme.length],
          genreCtrl: [this.programme.genre],
          contTypeCtrl: [this.contract.contract_type],
          languageCtrl: [this.rightsManagement.language],
          subgenryCtrl: [this.programme.subgenre],
          categoryCtrl: [this.programme.category],
          start_dateCtrl: [this.rightsManagement.startDate, Validators.required],
          end_dateCtrl: [this.rightsManagement.endDate, Validators.required],
          producerCtrl: [this.season.producer],
          directorCtrl: [this.season.directors],
          actorsCtrl: [this.season.actors],
          parentalGCtrl: [this.parentalG],
          groupsChannelsCtrl: [this.rightsManagement.groupsChannels, Validators.required],
          regionCtrl: [this.rightsManagement.region],
          typesOfRightsCtrl: [this.rightsManagement.typesOfRights],
          mediaCtrl: [this.rightsManagement.media],
          platformCtrl: [this.rightsManagement.platform],
          typeOfRightsCtrl: [this.rightsManagement.typesOfRights],
          numberOfPlaysCtrl: [this.rightsManagement.numberOfPlays],
          totalTxPerEpCtrl: [this.rightsManagement.totalTxPerEp],
          exclusiveCtrl: [this.rightsManagement.exclusive],
          unlimitedPlaysCtrl: [this.rightsManagement.unlimitedPlays],
          unlimitedTxPerEpiCtrl: [this.rightsManagement.unlimitedTxPerEpi],
          currencyCtrl: [this.rightsManagement.currency],
          totalEpisodesCtrl: [this.rightsManagement.totalEpisodes],
          deliveryExpensesCtrl: [this.rightsManagement.deliveryExpenses],
          totalAmountCtrl: [this.rightsManagement.totalAmount],
          boughtAtCtrl: [this.rightsManagement.boughtAt],
      });


      for(var i=0;i<this.cur_obj.length;i++){
        this.currency[i] = this.cur_obj[i].cc+' - '+this.cur_obj[i].name;
    }
  }

  currecyCode(currency){

    let arrayData = currency.split(" - ");
    this.currency_code = arrayData[0];
  }

  openSidebar(){
    this.licence_fee_Sidebar = true;
    this.rightsManagement.totalEpisodes = 0;
    for (let i = 0; i < this.programmes.length; i++) {
        if ( this.programmes[i].episodes != null){
            this.rightsManagement.totalEpisodes = this.rightsManagement.totalEpisodes + this.programmes[i].episodes; 
        }   
    }
  }

  onTxsChange(){
    if(this.rightsManagement.unlimitedTxPerEpi){
        this.rightsManagement.totalTxPerEp = 0;
    }
  }

  onPlaysChange(){
    if(this.rightsManagement.unlimitedPlays){
        this.rightsManagement.numberOfPlays = 0;
    }
  }

  calculateAmount(){

    if(this.rightsManagement.totalEpisodes == null){this.rightsManagement.totalEpisodes = 0;}
    if(this.rightsManagement.deliveryExpenses == null){this.rightsManagement.deliveryExpenses = 0;}
    if(this.rightsManagement.boughtAt == null || this.currency_code == 'ZAR'){this.rightsManagement.boughtAt = 1;}
    if(this.rightsManagement.costPerEpisode == null){this.rightsManagement.costPerEpisode = 0;}
    if(this.rightsManagement.costPerEpisode_InRand == null){this.rightsManagement.costPerEpisode_InRand = 0;}
    
    this.rightsManagement.totalAmount = ((this.rightsManagement.costPerEpisode * this.rightsManagement.totalEpisodes) + (this.rightsManagement.totalEpisodes * this.rightsManagement.deliveryExpenses)) * this.rightsManagement.boughtAt;

    if(this.rightsManagement.totalAmount > 0 && this.rightsManagement.totalEpisodes > 0){this.rightsManagement.costPerEpisode_InRand = this.rightsManagement.totalAmount/this.rightsManagement.totalEpisodes;}
  }

  addProgramme(): void {
      const programme = new BulkProgramme();
      programme.prog_no = 0;
      this.programmes.push(programme);
      this.dataSource.next(this.programmes);
  }

  removeProgramme(program: BulkProgramme): void {

      if(this.programmes.length > 1){
        this.programmes.splice(this.programmes.indexOf(program), 1);
        this.dataSource.next(this.programmes);
      }
      else{
        this.alertService.error("Add at least one programme !");
      }
  }

  async createProgramme() {

      if(this.internalCategory == null){this.internalCategory = '';}
      if(this.contract.contract_no == null){this.contract.contract_no = '';}
      if(this.contract.contract_type == null){this.contract.contract_type = '';}
      if(this.rightsManagement.region == null){this.rightsManagement.region = [];}
      if(this.rightsManagement.language == null){this.rightsManagement.language = '';}
      if(this.rightsManagement.numberOfPlays == null){this.rightsManagement.numberOfPlays = 1;}
      if(this.rightsManagement.totalTxPerEp == null){this.rightsManagement.totalTxPerEp = 0;}
      if(this.rightsManagement.costPerEpisode == null){this.rightsManagement.costPerEpisode = 0;}
      if(this.rightsManagement.costPerEpisode_InRand == null){this.rightsManagement.costPerEpisode_InRand = 0;}
      if(this.rightsManagement.currency == null){this.rightsManagement.currency = '';}
      if(this.rightsManagement.totalEpisodes == null){this.rightsManagement.totalEpisodes = 0;}
      if(this.rightsManagement.deliveryExpenses == null){this.rightsManagement.deliveryExpenses = 0;}
      if(this.rightsManagement.boughtAt == null){this.rightsManagement.boughtAt = 1;}
      if(this.rightsManagement.totalAmount == null){this.rightsManagement.totalAmount = 0;}
      if(this.rightsManagement.typesOfRights == null){this.rightsManagement.typesOfRights = '';}
      if(this.rightsManagement.platform == null){this.rightsManagement.platform = '';}
      if(this.rightsManagement.media == null){this.rightsManagement.media = '';}
      this.calculateAmount();

      let blnValid = true;
      let message = '';
      for (let i = 0; i < this.programmes.length; i++) {
        if (this.programmes[i].prog_name == null || this.programmes[i].prog_name == ''){
            blnValid = false;
            message = 'Please enter programme name';
        }
        if (this.programmes[i].length == null || this.programmes[i].length == 0){
            blnValid = false;
            message = 'Please enter programme length';
        }
        if (this.programmes[i].seasons == null || this.programmes[i].seasons == 0){
            blnValid = false;
            message = 'Please enter season number';
        }
        if ( this.programmes[i].episodes == null || this.programmes[i].episodes == 0){
            blnValid = false;
            message = 'Please enter number of episodes';
        }
        if ( this.programmes[i].segments == null || this.programmes[i].segments == 0){
            blnValid = false;
            message = 'Please enter number of segments';
        } 

        if ( this.programmes[i].genre_sentech == null){
            this.programmes[i].genre_sentech = "";
        } 

        if ( this.programmes[i].subgenre_sentech == null){
            this.programmes[i].subgenre_sentech = "";
        }

        if ( this.programmes[i].sub_genre == null){
            this.programmes[i].sub_genre = "";
        } 

        if ( this.programmes[i].genre == null){
            this.programmes[i].genre = "";
        } 

        if ( this.programmes[i].pg_rating == null){
            this.programmes[i].pg_rating = "";
        } 

        if ( this.programmes[i].producer == null){
            this.programmes[i].producer = "";
        } 

        if ( this.programmes[i].synanopis == null){
            this.programmes[i].synanopis = "";
        } 

        if ( this.programmes[i].censorship == null){
            this.programmes[i].censorship = [];
        } 
      }

      if(blnValid){

        let regions = "";

        for(var i=0;i < this.rightsManagement.region.length;i++){
            regions = regions+this.rightsManagement.region[i]+";";
        }

      const response = this.httpClient.post(`https://mojalove.mmsafrica.co.za/api/schedule/programme/bulk_programme.php`,
      {
          employee_no: this.employee_no,
          selected_channel: this.rightsManagement.groupsChannels,
          programmes: this.programmes,
          internalCategory: this.internalCategory,
          contract_no: this.contract.contract_no,
          contract_type: this.contract.contract_type,
          description: this.rightsManagement.description,
          txPerPlay: this.rightsManagement.txPerPlay,
          exhibitionPeriod: this.rightsManagement.exhibitionPeriod,
          playsPer: this.rightsManagement.playsPer,
          language: this.rightsManagement.language,
          numberOfPlays: this.rightsManagement.numberOfPlays,
          region: regions,
          media: this.rightsManagement.media,
          totalTxPerEp: this.rightsManagement.totalTxPerEp,
          typesOfRights: this.rightsManagement.typesOfRights,
          platform: this.rightsManagement.platform,
          distributors: this.distributors,
          exclusive: this.rightsManagement.exclusive,
          targetDemographic: this.targetDemographic,
          costPerEpisode: this.rightsManagement.costPerEpisode,
          costPerEpisode_InRand: this.rightsManagement.costPerEpisode_InRand,
          unlimitedPlays: this.rightsManagement.unlimitedPlays,
          unlimitedTxPerEpi: this.rightsManagement.unlimitedTxPerEpi,

          currency: this.rightsManagement.currency,
          totalEpisodes: this.rightsManagement.totalEpisodes,
          deliveryExpenses: this.rightsManagement.deliveryExpenses,
          boughtAt: this.rightsManagement.boughtAt,
          totalAmount: this.rightsManagement.totalAmount,
          start_date: moment(this.rightsManagement.startDate.toString()).format('YYYY-MM-DD'),
          end_date: moment(this.rightsManagement.endDate.toString()).format('YYYY-MM-DD'),

          
      }, {responseType: 'json'}).pipe()
      .subscribe(
          (data: any) => {

              if (data.message === 'success') {

                this.alertService.success('Programmes successfully saved !');
                this.done();
                /*
                var files = res_data.download_files.split(";");
                for(var i=0;i<(files.length-1);i++)
                {
                  this.export(files[i]).subscribe(data => saveAs(data, 'Skipped Report.xlsx')); 
                }*/
                  
              } else {
                  this.alertService.error(data.message);
              }

            }
        );

        }else{
            this.alertService.error(message);
        }
  }

  done() {
      // this.programme = new ProgrammeClass();
      this.contract = new ContractClass();
      this.parentalG = '';
      this.router.navigate(['/home/programme-list']);
  }

  genreChanged(idNO) {
      this.selected_subgenries = [];

      for (let i = 0; i < this.subgenries.length; i++) {
          if (this.subgenries[i].genre === idNO) {
              this.selected_subgenries.push(this.subgenries[i]);
          }
      }
  }

  sentechGenreChanged(idNO) {
    this.selected_sentech_subgenries = [];

    for (var i = 0; i < this.sentech_subgenries.length; i++) {
        if (this.sentech_subgenries[i].genre == idNO) {
            this.selected_sentech_subgenries.push(this.sentech_subgenries[i]);
        }
    }
}

  async onReadProgOptions() {

      const response = this.httpClient.post(`https://mojalove.mmsafrica.co.za/api/schedule/programme/get_programme_options.php`, 
          {
              employee_no: this.employee_no,

          }, {responseType: 'json'}).pipe()
          .subscribe(
              (data: any) => {


                for (let i = 0; i < data[0].distributors.length; i++) {
                    this.distributors_array[i] = {
                        idNo: data[0].distributors[i].idNo,
                        distributor: data[0].distributors[i].distributor,
                        contact_person: data[0].distributors[i].contact_person,
                        phone: data[0].distributors[i].phone,
                        email: data[0].distributors[i].email
                    };
                }

                  for (let i = 0; i < data[0].genre.length; i++) {
                      this.genres[i] = {
                          bdcr_no: data[0].genre[i].bdcr_no,
                          genre: data[0].genre[i].genre,
                          genreId: data[0].genre[i].genreId,
                          color: data[0].genre[i].color,
                          code: 0
                      };
                  }

                  for (let i = 0; i < data[0].subgenries.length; i++) {
                    this.subgenries[i] = {genre: data[0].subgenries[i].genre,
                        idNo: data[0].subgenries[i].idNo,
                        subgenre: data[0].subgenries[i].subgenre,
                        code: 0
                    };
                 }

                 for (let i = 0; i < data[0].sentech_genre.length; i++) {
                    this.sentech_genres[i] = {
                        bdcr_no: data[0].sentech_genre[i].bdcr_no,
                        genre: data[0].sentech_genre[i].genre,
                        genreId: data[0].sentech_genre[i].genreId,
                        color: data[0].sentech_genre[i].color,
                        code: data[0].sentech_genre[i].code
                    };
                }

                for (let i = 0; i < data[0].sentech_subgenre.length; i++) {
                    this.sentech_subgenries[i] = {genre: data[0].sentech_subgenre[i].genre,
                        idNo: data[0].sentech_subgenre[i].idNo,
                        subgenre: data[0].sentech_subgenre[i].subgenre,
                        code: data[0].sentech_subgenre[i].code
                    };
                }

                  for (let i = 0; i < data[0].pgs.length; i++) {
                      this.pgs[i] = {
                          pg_id: data[0].pgs[i].pg_id,
                          code: data[0].pgs[i].code,
                          pg_name: data[0].pgs[i].pg_name
                      };
                  }

                  for (let i = 0; i < data[0].language.length; i++) {
                      this.languages[i] = {
                          idNo: data[0].language[i].idNo,
                          bdcr_no: data[0].language[i].bdcr_no,
                          language: data[0].language[i].language
                      };
                  }

                  for (let i = 0; i < data[0].contract.length; i++) {
                      this.contractTypes[i] = {
                          contract_id: data[0].contract[i].contract_id,
                          contract: data[0].contract[i].contract
                      };
                  }

                  for (let i = 0; i < data[0].user.length; i++) {
                      this.users[i] = {
                          name: data[0].user[i].name,
                          bdcr_no: data[0].user[i].bdcr_no,
                          paswd: data[0].user[i].paswd,
                          email: data[0].user[i].email,
                          role_id: data[0].user[i].role_id,
                          atemps: data[0].user[i].atemps,
                          pers_code: data[0].user[i].pers_code,
                          accountType: data[0].user[i].accountType,
                          phoneNumber: data[0].user[i].phoneNumber,
                          status: data[0].user[i].status,
                          surname: data[0].user[i].surname,
                          analytics_delete: null,
                          analytics_read: null,
                          analytics_write:null,
                          content_management_delete:null,
                          content_management_read:null,
                          content_management_write:null,
                          content_sales_delete:null,
                          content_sales_read:null,
                          content_sales_write:null,
                          elearning_delete:null,
                          elearning_read:null,
                          elearning_write:null,
                          finance_delete:null,
                          finance_read:null,
                          finance_write:null,
                          hr_delete:null,
                          hr_read:null,
                          hr_write:null,
                          inventory_management_delete:null,
                          inventory_management_read:null,
                          inventory_management_write:null,
                          market_place_delete:null,
                          market_place_read:null,
                          market_place_write:null,
                          mat_man_delete:null,
                          mat_man_read:null,
                          mat_man_write:null,
                          news_delete:null,
                          news_read:null,
                          news_write:null,
                          production_services_delete:null,
                          production_services_read:null,
                          production_services_write:null,
                          prog_delete:null,
                          prog_read:null,
                          prog_write:null,
                          project_management_delete:null,
                          project_management_read:null,
                          project_management_write:null,
                          revenue_management_delete:null,
                          revenue_management_read:null,
                          revenue_management_write:null,
                          schedule_delete:null,
                          schedule_read:null,
                          schedule_write:null,
                          web2app_delete:null,
                          web2app_read:null,
                          web2app_write:null
                      };
                  }

                  for (let i = 0; i < data[0].categories.length; i++) {
                      this.categories[i] = {idNo: data[0].categories[i].idNo, name: data[0].categories[i].name};
                  }


                  for (let i = 0; i < data[0].regions.length; i++) {
                      this.regions[i] = {reg_Id: data[0].regions[i].idNo,name: data[0].regions[i].name
                      };
                  }


                  for (let i = 0; i < data[0].type_of_rights.length; i++) {
                    this.type_of_rights[i] = {
                        idNo: data[0].type_of_rights[i].contract_id,name: data[0].type_of_rights[i].contract
                    };
                }


                for (let i = 0; i < data[0].media_types.length; i++) {
                    this.media_types[i] = {
                        idNo: data[0].media_types[i].idNo,name: data[0].media_types[i].name
                    };
                }

                for (let i = 0; i < data[0].demographics.length; i++) {
                    this.demographics_array[i] = {
                        audienceId: data[0].demographics[i].audienceId,bdcr_no: data[0].demographics[i].bdcr_no,defaultRating: data[0].demographics[i].defaultRating,target_audience: data[0].demographics[i].target_audience,totals: data[0].demographics[i].totals
                    };
                }

              }
          );
  }

  async onChannels() {

      const response = this.httpClient.post(`https://mojalove.mmsafrica.co.za/api/schedule/get_channels.php`,
          {
              channes: this.selected_channel,
              date: moment().format('YYYY/MM/DD'),
              employee_no: this.employee_no,

          }, {responseType: 'json'}).pipe()
          .subscribe(
              (data: any) => {

                  for (let i = 0; i < data.length; i++) {
                      this.channels[i] = {
                          bdcr_no: data[i].bdcr_no,
                          genre: data[i].genre,
                          platform: data[i].platform,
                          sare_name: data[i].sare_name,
                          sare_no: Number(data[i].sare_no)
                      }; 

                      this.rightsManagement.groupsChannels = Number(this.channels[i].sare_no);
                      this.selected_channel = Number(this.channels[i].sare_no);
                      this.distributors = Number(this.channels[i].sare_no);
                  }
              }
          )
  }

  back() {
      this._location.back();
  }

}