import {Component, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {AlertService} from '../../services/alert/alert.service';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {environment} from '../../../environments/environment';
import {BehaviorSubject} from 'rxjs';
import {SelectionModel} from '@angular/cdk/collections';
import {ConfirmationDialogComponent} from '../confirmation-dialog/confirmation-dialog.component';
import {Location} from '@angular/common';
import * as currencies from '../../commom/currency.json';

//Import used to make HTTP get and post requests
import {HttpClient} from '@angular/common/http';
import {LoadingController, NavController} from '@ionic/angular';
import {finalize} from 'rxjs/operators';
import { UserClass } from 'src/app/classes/users';
import { FormBuilder, FormGroup,Validators, FormControl } from '@angular/forms';
import { ClientClass } from 'src/app/classes/client';
import { SetupClass } from 'src/app/classes/setup';
import { DistributorClass } from 'src/app/classes/distributor';

@Component({
  selector: 'app-new-distributor',
  templateUrl: './new-distributor.component.html',
  styleUrls: ['./new-distributor.component.scss'],
})
export class NewDistributorComponent implements OnInit {

  campaign: any;
  baseUrl = environment.serverUrl;

  distributor:DistributorClass = new DistributorClass();
  formData = new FormData();
  distributorFormGroup: FormGroup;

  constructor(private router: Router, private alertService: AlertService,private _location: Location,private httpClient: HttpClient,public dialog: MatDialog,private formBuilder: FormBuilder) {

  
  }
 
  ngOnInit() {

    this.distributorFormGroup = this.formBuilder.group({
      distributorCtrl: [this.distributor.distributor, Validators.required],
      contact_personCtrl: [this.distributor.contact_person, Validators.required],
      phoneCtrl: [this.distributor.phone, Validators.required],
      emailCtrl: [this.distributor.email,Validators.required],
    });
    
  }

  async saveDistributor(){ 

    const response = this.httpClient.post(`https://mojalove.mmsafrica.co.za/api/schedule/save_distributor.php`,
    {
      distributor: this.distributor.distributor,
      contact_person: this.distributor.contact_person,
      email: this.distributor.email,
      phone: this.distributor.phone,

    },{ responseType: 'json'}).pipe()
    .subscribe(
      (data:any) => {
         
        if(data.message == 'success')
        {
          this.distributorFormGroup.reset();
          this.alertService.success("Distributor successfully saved");
          this.router.navigate(['/home/distributor-list']);
        }
        else{

          this.alertService.error(data.message);
        }

      },error => {
        this.alertService.error("Something went wrong, Try again");
      } 
    ) 
  }

  returnToDashboard() {
    this.router.navigate(['/home']);
  }

  back(){
    this._location.back();
  }

}
