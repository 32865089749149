import { Component, OnInit, ViewChild } from '@angular/core';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import { FormGroup, FormBuilder, Validators, FormControl,NgForm} from '@angular/forms';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {SelectionModel} from '@angular/cdk/collections';
import {MenuItem,TreeNode} from 'primeng/api';
import {TreeDragDropService} from 'primeng/api';
import {ScheduleClass} from '../../classes/schedule';
import { SeasonClass } from '../../classes/season';
import { EpisodeClass } from '../../classes/episode';
import {SareClass} from '../../classes/sare';
import {ProgrammeClass} from '../../classes/programme';
import {ConfirmationDialogComponent} from '../confirmation-dialog/confirmation-dialog.component';
import {Location} from '@angular/common';
import * as $ from "jquery";
import { timer } from 'rxjs';

//Import used to make HTTP get and post requests 
import { HttpClient } from '@angular/common/http';
import { LoadingController } from '@ionic/angular';
import { finalize } from 'rxjs/operators';
 
//To use autcomplete field
import {Observable} from 'rxjs'; 
import {map, startWith} from 'rxjs/operators';
import { NavController } from '@ionic/angular';

//Toasta
import {AlertService} from '../../services/alert/alert.service';
import { Router, ActivatedRoute } from '@angular/router';

import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';
import {MatDatepicker} from '@angular/material/datepicker';

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

import * as _moment from 'moment';
import {default as _rollupMoment, Moment} from 'moment';
import { SegmentClass } from 'src/app/classes/segment';
import { BreakClass } from 'src/app/classes/breaks';
import { LogeditorSegment } from 'src/app/classes/logeditor.segments';
import { MaterialClass } from 'src/app/classes/material';
import { ProductClass } from 'src/app/classes/product';
import { FlightingCode } from 'src/app/classes/flighting_code';
import { CampaignClass } from 'src/app/classes/campaign';
const moment = _rollupMoment || _moment;

@Component({
  selector: 'app-log-editor',
  templateUrl: './log-editor.component.html', 
  styleUrls: ['./log-editor.component.scss']
})
export class LogEditorComponent implements OnInit {
  employee_no: number = 0;
  selected_channels: number[] = [];
  editFormGroup: FormGroup; 
  channels: SareClass[] = [];
  start_date: String = moment().format("YYYY-MM-DD");
  today_date: String = moment().format("YYYY/MM/DD");
  channel_name: String = '1';
  start_time: String = '00:00';
  main_array: LogeditorSegment[] = [];
  products_array: ProductClass[] = [];
  flightingcode_list: FlightingCode[] = [];
  campaign_list: CampaignClass[] = [];

  editSeg_Sidebar: boolean = false;
  editBreak_Sidebar: boolean = false;
  seglength: number = 0;

  segment_object: any;
  break_object: any;
  spot_object: any;

  //Edit Segment
  segmentFormGroup: FormGroup; 
  segment_Sidebar: boolean = false;
  season_array: SeasonClass[] = [];
  episode_array: EpisodeClass[] = [];
  segmentData: SegmentClass = new SegmentClass();
  breakData: BreakClass = new BreakClass();

  //Add Promo
  promo_Sidebar: boolean = false;

  //Material
  material_status: string = 'All';
  material_type: string = 'All';
  material_Sidebar: boolean = false;
  spot_material_Sidebar: boolean = false;
  material_infor_Sidebar: boolean = false;
  spots_material_array: MaterialClass[] = [];
  material_array: MaterialClass[] = [];
  available_array: MaterialClass[] = [];
  material: MaterialClass = new MaterialClass();

  available_displayedColumns: string[] = ['MATERIAL_ID', 'MATERIAL_TITLE','DURATION','ACTION'];
  available_dataSource = new MatTableDataSource(this.available_array);
  available_selection = new SelectionModel<any>(true, []);
  @ViewChild(MatSort, {static: true}) sort3: MatSort;
  @ViewChild('paginator3') paginator3: MatPaginator;

  spots_material_displayedColumns: string[] = ['MATERIAL_ID', 'MATERIAL_TITLE','DURATION','ACTION'];
  spots_material_dataSource = new MatTableDataSource(this.spots_material_array);
  spots_material_selection = new SelectionModel<any>(true, []);
  @ViewChild(MatSort, {static: true}) sort4: MatSort;
  @ViewChild('paginator4') paginator4: MatPaginator;

  //Assign
  selected_progNo: number = 0;
  selected_seasNo: number = 0;
  selected_epiNo: number = 0;
  selected_segm_no: number = 0;
  selected_spotNo: number = 0;

  //Insert Element
  breakFormGroup: FormGroup; 
  promoFormGroup: FormGroup; 
  insertElement_Sidebar: boolean = false;
  el_start_time: string = "00:00";
  el_end_time: string = "00:00";
  el_date: string = "";
  el_length: number = 0;
  gap_length: number = 0;
  el_max_length: number = 0;
  el_prog_no: number = 0;
  el_seas_no: number = 0;
  el_epi_no: number = 0;
  el_product: number = 0;
  el_campaignNo: number = 0;
  el_flightingCode: string = '';
  duration_array: number[] = [5,10,15,20,25,30,35,40,45,50,55,60,65,70,75,80,85,90,95,100];

  el_new_campaign: boolean = true;
  el_new_flightingCode: boolean = false;
  el_start_date: String = moment().format("YYYY-MM-DD");
  el_end_date: String = moment().format("YYYY-MM-DD");

  myControl = new FormControl(); 
  filteredOptions: Observable<ProductClass[]>;

  myControl_code = new FormControl(); 
  filteredOptions_code: Observable<FlightingCode[]>;

  myControl_campaign = new FormControl(); 
  filteredOptions_campaign: Observable<CampaignClass[]>;

  //Video Player
  items: MenuItem[];
  image: string = 'https://mojalove.mmsafrica.co.za/api/schedule/schedule_files/logos/0723125119aljazeerainternationalnews.png';
  video_url: string = '';

  timeLeft: number = 0;
  subscribeTimer: any;
  disp_timer: any = "00:00:00";
  bln_rec: boolean = true;

  constructor(private alertService: AlertService,route:ActivatedRoute,private router: Router,private formBuilder: FormBuilder,private httpClient: HttpClient,private loadingCtrl: LoadingController,private navCtrl: NavController,public dialog: MatDialog,private _location: Location) 
  {
    
    this.employee_no = parseInt(JSON.parse(localStorage.getItem('employee_no')));
    this.selected_channels[0] = parseInt(JSON.parse(localStorage.getItem('log_channelId')));
    this.start_date = JSON.parse(localStorage.getItem('log_date'));

    this.onReadMaterials();
    this.onReadSpotsMaterials();
    this.onReadProducts();
    this.onReadCampaigns();
    this.oberserableTimer();
  }

  ngOnInit() {

      $(document).ready(function(){
          $('#my-video').bind('contextmenu',function() { return false; });
      });

      this.segmentFormGroup = this.formBuilder.group({
        seglengthCtrl: [this.segmentData.segm_len,Validators.required],
        start_timeCtrl: [this.segmentData.sst_time,Validators.required],
        seas_noCtrl: [this.segmentData.seas_no,Validators.required],
        epis_noCtrl: [this.segmentData.epis_no,Validators.required],
      });

      this.editFormGroup = this.formBuilder.group({
        seglengthCtrl: [this.seglength,Validators.required],
        start_timeCtrl: [this.start_time,Validators.required],
      });

      this.breakFormGroup = this.formBuilder.group({
        el_start_timeCtrl: [this.el_start_time,Validators.required],
        el_lengthCtrl: [this.el_length,Validators.required],
      });
      
      this.promoFormGroup = this.formBuilder.group({
        el_start_timeCtrl: [this.el_start_time,Validators.required],
        el_lengthCtrl: [this.el_length,Validators.required],
        el_productCtrl: [this.el_product,Validators.required],
        el_flightingCodeCtrl: [this.el_flightingCode,Validators.required],
        el_new_campaignCtrl: [this.el_new_campaign,Validators.required],
        el_start_dateCtrl: [this.el_start_date,Validators.required],
        el_end_dateCtrl: [this.el_end_date,Validators.required],
        el_new_flightingCodeCtrl: [this.el_new_flightingCode,Validators.required],
      });

      this.filteredOptions = this.myControl.valueChanges.pipe( 
        startWith(''),
        map(value => this._filter(value)),
      );

      this.filteredOptions_code = this.myControl_code.valueChanges.pipe( 
        startWith(''),
        map(value => this._filter_code(value)),
      );

      this.filteredOptions_campaign = this.myControl_campaign.valueChanges.pipe( 
        startWith(''),
        map(value => this._filter_campaign(value)),
      );
  } 

  ngAfterViewInit(){

    this.video_url = "https://mojalove.mmsafrica.co.za/api/test_video_new.mp4";
    document.getElementById("my-video").setAttribute("src", this.video_url);
    document.getElementById("my-video").setAttribute("muted", "true");

    this.video_url = "https://www.youtube.com/watch?v=21X5lGlDOfg";//Live channel
    document.getElementById("my-video3").setAttribute("src", this.video_url);
    document.getElementById("my-video3").setAttribute("muted", "true");


    this.onChannels();
  }

  oberserableTimer() {
    const source = timer(500, 1000);
    const abc = source.subscribe(val => {
      if(this.employee_no > 0){

        this.subscribeTimer = this.timeLeft + val;
        //this.disp_timer = new Date(this.subscribeTimer * 1000).toISOString().substr(11, 8);
        this.disp_timer=moment().format("HH:mm:ss");

        localStorage.setItem('timer', JSON.stringify(this.subscribeTimer));

        if(this.subscribeTimer % 2 == 0){
          this.bln_rec = true;
        }
        else{
          this.bln_rec = false;
        }

      }
    });
  }

  private _filter(value: string): ProductClass[] {
    const filterValue = value.toLowerCase();

    return this.products_array.filter(option => option.product_name.toLowerCase().includes(filterValue));
  }

  private _filter_code(value: string): FlightingCode[] {
    const filterValue = value.toLowerCase();

    return this.flightingcode_list.filter(option => option.indury_code.toLowerCase().includes(filterValue));
  }

  private _filter_campaign(value: string): CampaignClass[] {
    const filterValue = value.toLowerCase();

    return this.campaign_list.filter(option => String(option.camp_no+' - '+option.product_name).toLowerCase().includes(filterValue));
  }

  //Apply filter when using search box
  applyFilter3(filterValue: string) {
    this.available_selection.clear();
    this.available_dataSource.filter = filterValue.trim().toLowerCase();
  }

  //Apply filter when using search box
  applyFilter4(filterValue: string) {
    this.spots_material_selection.clear();
    this.spots_material_dataSource.filter = filterValue.trim().toLowerCase();
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
                        event.container.data,
                        event.previousIndex,
                        event.currentIndex);
    }
  }

  resetFlightingCode(){
    this.el_flightingCode = '';
  }

  addPromo(breakObj: any): void {

    this.promo_Sidebar = true;
    this.break_object = breakObj;
    this.breakData.length = Number(breakObj.brk_sec);
    this.breakData.start_time = breakObj.short_time;

    this.el_date = breakObj.break_date;
    this.el_start_time = breakObj.short_time;
    this.el_prog_no = Number(breakObj.prog_no);
    this.el_seas_no = Number(breakObj.season);
    this.el_epi_no = Number(breakObj.episode);
    this.el_end_time = breakObj.brk_end_time;
    let a = new Date('2022-01-01 '+this.el_start_time);
    let b = new Date('2022-01-01 '+this.el_end_time);
    var secondBetweenTwoDate = Math.abs((b.getTime() - a.getTime()) / 1000);

    this.el_length = 0;
    this.el_max_length = secondBetweenTwoDate;
    this.gap_length = secondBetweenTwoDate;
  }

  editSegment(segment: any,index): void {

    this.segment_object = segment;
    this.segment_Sidebar = true;
    this.segmentData.segm_len = Number(segment.length);
    this.segmentData.sst_time = segment.start_time;
    this.segmentData.brek_time = segment.date; //date
    this.segmentData.idNo = segment.idNo;
    this.segmentData.prog_no = Number(segment.prog_no);
    this.segmentData.seas_no = Number(segment.season);
    this.segmentData.epis_no = Number(segment.episode);
    //this.onReadSeasons(this.segmentData.prog_no);

    //Break creation data
    let nxtIndex = index + 1;
    this.el_max_length = 0;
    if(this.main_array.length > nxtIndex)
    {
      this.el_date = segment.date;
      this.el_start_time = segment.break_time;
      this.el_prog_no = this.segmentData.prog_no;
      this.el_seas_no = this.segmentData.seas_no;
      this.el_epi_no = this.segmentData.epis_no;
      this.el_end_time = this.main_array[nxtIndex].start_time;
      
      let a = new Date('2022-01-01 '+this.el_start_time);
      let b = new Date('2022-01-01 '+this.el_end_time);
      let secondBetweenTwoDate = Math.abs((b.getTime() - a.getTime()) / 1000);

      this.el_max_length = secondBetweenTwoDate;
      this.el_length = secondBetweenTwoDate;
      this.gap_length = secondBetweenTwoDate;
    }
    
  }
  
  
  openDeleteSegDialog(segment: any): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '700px',
      data: 'Are you sure you want to delete segment ?'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {

        this.segment_object = segment;
        this.deleteSegment();
      }
    });
  }

  openDeleteBreakDialog(breakOnj: any): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '700px',
      data: 'Are you sure you want to delete break ?'
    });

    console.log(breakOnj);

    dialogRef.afterClosed().subscribe(result => {
      if (result) {

        this.break_object = breakOnj;
        this.deleteBreak();
      }
    });
  }

  async deleteBreak(){ 
    
    const response = this.httpClient.post(`https://mojalove.mmsafrica.co.za/api/schedule/transmission/delete_break.php`,
    {
      break_id: this.break_object.break_id,

    },{ responseType: 'json'}).pipe()
    .subscribe(
      (data:any) => {
         
        if(data.message == 'success')
        { 
          this.alertService.success('Break successfully deleted');
          this.onReadLogeditor();
        }
        else
        {
          this.alertService.error(data.message);
        }

      } 
    ) 
  }

  async deleteSegment(){ 

    const response = this.httpClient.post(`https://mojalove.mmsafrica.co.za/api/schedule/transmission/delete_segment.php`,
    {
      prog_no: this.segment_object.prog_no,
      season: this.segment_object.season,
      segm_no: this.segment_object.segm_no,
      episode: this.segment_object.episode,
      exhibition_no: this.segment_object.exhibition_no,

    },{ responseType: 'json'}).pipe()
    .subscribe(
      (data:any) => {
         
        if(data.message == 'success')
        {
          this.alertService.success('Segment successfully deleted');
          this.onReadLogeditor();
        }
        else
        {
          this.alertService.error(data.message);
        }

      } 
    ) 
  }

  async saveBreak(){ 

    const response = this.httpClient.post(`https://mojalove.mmsafrica.co.za/api/schedule/transmission/edit_break.php`,
    {
      break_id: this.break_object.break_id,
      employee_no: this.employee_no,
      breklength: this.breakData.length,
      start_time: this.breakData.start_time,
      channel: this.selected_channels[0],
      date: this.break_object.break_date,
      strType: this.break_object.strType,
      
      
    },{ responseType: 'json'}).pipe( )
    .subscribe(
      (data:any) => {
         
        if(data.message == 'success')
        {
          this.alertService.success('Break successfully saved');
          this.onReadLogeditor();
        }
        else
        {
          this.alertService.error(data.message);
        }

      } 
    ) 
  }

  async saveSegment(){ 

    const response = this.httpClient.post(`https://mojalove.mmsafrica.co.za/api/schedule/transmission/edit_segment.php`,
    {
      prog_no: this.segment_object.prog_no,
      season: this.segment_object.season,
      segm_no: this.segment_object.segm_no,
      episode: this.segment_object.episode, 
      exhibition_no: this.segment_object.exhibition_no,
      date: this.segment_object.date,
      employee_no: this.employee_no,
      seglength: this.segmentData.segm_len, 
      start_time: this.segmentData.sst_time,
      change_seas_no: this.segmentData.seas_no, 
      change_epis_no: this.segmentData.epis_no,
      channel: this.selected_channels[0]

    },{ responseType: 'json'}).pipe( )
    .subscribe(
      (data:any) => {
         
        if(data.message == 'success')
        {
          this.alertService.success('Segment successfully saved');
          this.segment_Sidebar = false;
          this.onReadLogeditor();
        }
        else
        {
          this.alertService.error(data.message);
        }

      } 
    ) 
  }

  async onReadLogeditor(){ 

    var start_date = moment(this.start_date.toString()).format("YYYY/MM/DD");
    
    const response = this.httpClient.post(`https://mojalove.mmsafrica.co.za/api/schedule/transmission/read_logeditor.php`,
    {
      channes: this.selected_channels,
      start_date: start_date,

    },{ responseType: 'json'}).pipe()
    .subscribe(
      (data:any) => {

        this.main_array = [];
         
        for(var i=0;i<data.length;i++)
        { 
            this.main_array[i] = {idNo: data[i].idNo,break_data: data[i].break_data,date: data[i].date,ep_title: data[i].ep_title,episode: data[i].episode,exhibition_no: data[i].exhibition_no,genre: data[i].genre,length: data[i].length,pg: data[i].pg,prog_no: data[i].prog_no,season: data[i].season,segm_no: data[i].segm_no,start_time: data[i].start_time,title: data[i].title,total_segments: data[i].total_segments,status: data[i].status,validation: data[i].validation,break_time: data[i].break_time,material: data[i].material,disp_duration: data[i].disp_duration,color_code: data[i].color_code,material_type: data[i].material_type,isInsert: data[i].isInsert,clock: data[i].clock,material_drive: data[i].material_drive};
        }  
      }  
    )  
  } 

  async onChannels(){ 
      
    const response = this.httpClient.post(`https://mojalove.mmsafrica.co.za/api/schedule/get_channels.php`,
    {
      channes: this.selected_channels,
      date: moment().format("YYYY/MM/DD"), 
      employee_no: this.employee_no,

    },{ responseType: 'json'}).pipe()
    .subscribe(
      (data:any) => {
         
        for(var i=0;i<data.length;i++){

            this.channels[i] = {bdcr_no: data[i].bdcr_no,genre: data[i].genre,platform: data[i].platform,sare_name: data[i].sare_name,sare_no: data[i].sare_no};

            this.selected_channels[0] = data[i].sare_no;
            this.channel_name = data[i].sare_name;
        }

        this.onReadLogeditor();
      } 
    ) 
  }

  removeMaterial(): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '400px',
        data: 'Are you sure you want to remove material '+this.material.MATERIAL_ID+' ?'
    });
    
    dialogRef.afterClosed().subscribe(result => {
        if (result) {

            this.remMaterial();
        }
    });
  }

  remMaterial() {
    const response = this.httpClient.post(`https://mojalove.mmsafrica.co.za/api/schedule/material/remove_material.php`,
        {
          progNo: this.selected_progNo,
          seasNo: this.selected_seasNo,
          epiNo: this.selected_epiNo,
          segm_no: this.selected_segm_no,
          materialId: this.material.MATERIAL_ID 
    
        }, {responseType: 'json'}).pipe()
        .subscribe(
            (data: any) => {
    
                if (data.message === 'success') {
                    this.alertService.success('Material successfully removed');
                    this.material_infor_Sidebar = false;

                    this.onReadMaterials();
                    this.onReadLogeditor();
                    /*
                    for(var i=0;i<this.programmeMaterial_array.length;i++)
                    {
                        if(this.programmeMaterial_array[i].MATERIAL_ID == materialId){
                          this.available_array.push(this.programmeMaterial_array[i]);

                          this.available_dataSource = new MatTableDataSource(this.available_array);
                          this.available_dataSource.sort = this.sort3;
                          this.available_dataSource.paginator = this.paginator3;
                          

                          //Updating table after removing (USELESS CODE- JUST FOR VIEW)
                          for(var x=0;x<this.programme_array.length;x++)
                          {
                            if(this.programme_array[x].prog_no == progNo){
                              for(var x2=0;x2<this.programme_array[x].season_data.length;x2++)
                              {
                                if(this.programme_array[x].season_data[x2].seas_no == seasNo){
                                  for(var x3=0;x3<this.programme_array[x].season_data[x2].episode_data.length;x3++)
                                  {
                                    if(this.programme_array[x].season_data[x2].episode_data[x3].episode_no == epiNo){
                                      this.programme_array[x].season_data[x2].episode_data[x3].material = '';
                                      for(var x4=0;x4<this.programme_array[x].season_data[x2].episode_data[x3].segments_data.length;x4++)
                                      {
                                        if(this.programme_array[x].season_data[x2].episode_data[x3].segments_data[x4].segm_no == segm_no){
                                          this.programme_array[x].season_data[x2].episode_data[x3].segments_data[x4].material = '';
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                          //END--OF--UPDATING TABLE

                        }
                    }*/

                } else {
                    this.alertService.error(data.message);
                }
            }
        );
 }

  viewMaterial(prog_no, season, episode,segm_no,material) {

    for(var i=0;i<this.material_array.length;i++)
    {
      if(this.material_array[i].MATERIAL_ID == material){

        this.material = this.material_array[i]; 
        this.material_infor_Sidebar = true;
        this.selected_progNo = prog_no;
        this.selected_seasNo = season;
        this.selected_epiNo = episode;
        this.selected_segm_no = segm_no;
      }
    }
  }

   viewSpotMaterial(material) {

    for(var i=0;i<this.spots_material_array.length;i++)
    {
      if(this.spots_material_array[i].MATERIAL_ID == material){
        this.material = this.spots_material_array[i]; 
        this.material_infor_Sidebar = true;
      }
    }
   }


  assignMaterial(progNo,seasNo,epiNo,segm_no) {

    this.selected_progNo = progNo;
    this.selected_seasNo = seasNo;
    this.selected_epiNo = epiNo;
    this.selected_segm_no = segm_no;
    this.material_Sidebar = true;
   }

   assignSpotMaterial(spotNo) {

    this.selected_spotNo = spotNo;
    this.spot_material_Sidebar = true;
   }

   addSpotMaterial(materialId,object) {

    const response = this.httpClient.post(`https://mojalove.mmsafrica.co.za/api/schedule/material/add_spot_material.php`,
    {
      spot_no: this.selected_spotNo,
      materialId: materialId

    }, {responseType: 'json'}).pipe()
    .subscribe(
        (data: any) => {

            if (data.message === 'success') {
                this.alertService.success('Material successfully saved');
                this.spot_material_Sidebar = false;
                this.onReadLogeditor();
                
            } else {
                this.alertService.error(data.message);
            }
        }
    );
}
  
   addMaterial(materialId,object) {
  
        const response = this.httpClient.post(`https://mojalove.mmsafrica.co.za/api/schedule/material/add_material.php`,
        {
          progNo: this.selected_progNo,
          seasNo: this.selected_seasNo,
          epiNo: this.selected_epiNo,
          segm_no: this.selected_segm_no,
          materialId: materialId
  
        }, {responseType: 'json'}).pipe()
        .subscribe(
            (data: any) => {
  
                if (data.message === 'success') {
                    this.alertService.success('Material successfully saved');
                    this.material_Sidebar = false;
                    
                    const index = this.available_array.indexOf(object, 0);
                    if (index > -1) {
                      this.available_array.splice(index, 1);
                    }
  
                    this.available_dataSource = new MatTableDataSource(this.available_array);
                    this.available_dataSource.sort = this.sort3;
                    this.available_dataSource.paginator = this.paginator3;

                    this.onReadLogeditor();
                    
                } else {
                    this.alertService.error(data.message);
                }
            }
        );
    }

    async onReadSpotsMaterials(){ 
      
      const response = this.httpClient.post(`https://mojalove.mmsafrica.co.za/api/schedule/material/read_spots_materials.php`,
      {
        material_type: this.material_type,
        employee_no: this.employee_no,
  
      },{ responseType: 'json'}).pipe( )
      .subscribe(
        (data:any) => {
           
          this.spots_material_array = [];
          let index = 0;

          for(var i=0;i<data.length;i++)
          {
              this.spots_material_array[i] = {idNo: data[i].idNo,_NOT_ASSIGNED: data[i]._NOT_ASSIGNED,_NOT_ASSIGNED_TIME: data[i]._NOT_ASSIGNED_TIME,ABBREVIATED_TITLE: data[i].ABBREVIATED_TITLE,AGENCY_COPY_NUMBER: data[i].AGENCY_COPY_NUMBER,ASPECT_RATIO: data[i].ASPECT_RATIO,AUDIO1: data[i].AUDIO1,AUDIO2: data[i].AUDIO2,AUDIO3: data[i].AUDIO3,AUDIO4: data[i].AUDIO4,CHANGE_TIME: data[i].CHANGE_TIME,DESCRIPTION: data[i].DESCRIPTION,DURATION: data[i].DURATION,EMBARGO_DATE: data[i].EMBARGO_DATE,ENTRY_DATE: data[i].ENTRY_DATE,ENTRY_OPERATOR: data[i].ENTRY_OPERATOR,EXPIRE_DATE: data[i].EXPIRE_DATE,FRAME_RATE: data[i].FRAME_RATE,GROUP_ID: data[i].GROUP_ID,MATERIAL_EVENTS: data[i].MATERIAL_EVENTS,MATERIAL_ID: data[i].MATERIAL_ID,MATERIAL_TITLE: data[i].MATERIAL_TITLE,MATERIAL_TYPE: data[i].MATERIAL_TYPE,NOMINAL_DURATION: data[i].NOMINAL_DURATION,RELEASED: data[i].RELEASED,SEGMENTS: data[i].SEGMENTS,SOM: data[i].SOM,status: data[i].status};
          }
          
          this.spots_material_dataSource = new MatTableDataSource(this.spots_material_array);
          this.spots_material_dataSource.sort = this.sort4;
          this.spots_material_dataSource.paginator = this.paginator4;
        }  
      ) 
    }

    async onReadMaterials(){ 
      
      const response = this.httpClient.post(`https://mojalove.mmsafrica.co.za/api/schedule/material/read_materials.php`,
      {
        material_type: this.material_type,
        employee_no: this.employee_no,
  
      },{ responseType: 'json'}).pipe( )
      .subscribe(
        (data:any) => {
           
          this.available_array = [];
          let index = 0;

          for(var i=0;i<data.length;i++)
          {
              this.material_array[i] = {idNo: data[i].idNo,_NOT_ASSIGNED: data[i]._NOT_ASSIGNED,_NOT_ASSIGNED_TIME: data[i]._NOT_ASSIGNED_TIME,ABBREVIATED_TITLE: data[i].ABBREVIATED_TITLE,AGENCY_COPY_NUMBER: data[i].AGENCY_COPY_NUMBER,ASPECT_RATIO: data[i].ASPECT_RATIO,AUDIO1: data[i].AUDIO1,AUDIO2: data[i].AUDIO2,AUDIO3: data[i].AUDIO3,AUDIO4: data[i].AUDIO4,CHANGE_TIME: data[i].CHANGE_TIME,DESCRIPTION: data[i].DESCRIPTION,DURATION: data[i].DURATION,EMBARGO_DATE: data[i].EMBARGO_DATE,ENTRY_DATE: data[i].ENTRY_DATE,ENTRY_OPERATOR: data[i].ENTRY_OPERATOR,EXPIRE_DATE: data[i].EXPIRE_DATE,FRAME_RATE: data[i].FRAME_RATE,GROUP_ID: data[i].GROUP_ID,MATERIAL_EVENTS: data[i].MATERIAL_EVENTS,MATERIAL_ID: data[i].MATERIAL_ID,MATERIAL_TITLE: data[i].MATERIAL_TITLE,MATERIAL_TYPE: data[i].MATERIAL_TYPE,NOMINAL_DURATION: data[i].NOMINAL_DURATION,RELEASED: data[i].RELEASED,SEGMENTS: data[i].SEGMENTS,SOM: data[i].SOM,status: data[i].status};

              if(data[i].status == '' || data[i].status == null){
                this.available_array[index] = this.material_array[i];
                index = index + 1;
              }
          }
          
          this.available_dataSource = new MatTableDataSource(this.available_array);
          this.available_dataSource.sort = this.sort3;
          this.available_dataSource.paginator = this.paginator3;
        }  
      ) 
    }


    insertElement(element){

      this.insertElement_Sidebar = true;
      this.el_start_time = element.start_time;
      this.el_end_time = element.break_time;
      this.el_date = element.date;
      this.el_max_length = element.length;
      this.el_length = element.length;
      this.gap_length = element.length;
      this.el_prog_no = 0;
      this.el_seas_no = 0;
      this.el_epi_no = 0;
    }

    async insertBreak() {

      if(this.el_length > this.el_max_length || this.el_max_length == 0 || this.el_max_length == null){
        this.alertService.error("Break length is greater than available length ("+this.el_max_length+" seconds)");
      }
      else{
  
          const response = this.httpClient.post(`https://mojalove.mmsafrica.co.za/api/schedule/transmission/insert_break.php`,
          {
            channel: this.selected_channels[0],
            el_start_time: this.el_start_time,
            el_end_time: this.el_end_time,
            el_date: this.el_date,
            el_length: this.el_length
  
          }, {responseType: 'json'}).pipe()
          .subscribe(
              (data: any) => {

                  if (data.message === 'success') {
                      this.alertService.success('Break successfully saved');
                      this.insertElement_Sidebar = false; 
                      this.segment_Sidebar = false;

                      this.onReadLogeditor();
                      
                  } else {
                      this.alertService.error(data.message);
                  }
              }
          );
        }
  }


  async insertPromo() {

    /*if(this.el_length > this.el_max_length){  
      this.alertService.error("Promo length is greater than available length ("+this.el_max_length+" seconds)");
    }
    else*/
    if(this.el_product <= 0 || this.el_product == null){
      this.alertService.error("Select valid product name");
    }
    else
    if((this.el_flightingCode == null || this.el_flightingCode == '') && this.el_new_flightingCode == false){
      this.alertService.error("Select valid flighting code or enable create new");
    }
    else{

        if(this.el_flightingCode == null || this.el_flightingCode == ''){
          this.el_flightingCode = this.myControl_code.value;
        }

        //If entered code already exist to create it again
        for(var i=0;i<this.flightingcode_list.length;i++){
          if(this.el_flightingCode == this.flightingcode_list[i].indury_code){
            this.el_new_flightingCode = false;
          }
        }

        const response = this.httpClient.post(`https://mojalove.mmsafrica.co.za/api/schedule/transmission/insert_promo.php`,
        {
          channel: this.selected_channels[0],
          el_start_time: this.el_start_time,
          el_end_time: this.el_end_time,
          el_date: this.el_date,
          el_length: this.el_length,
          gap_length: this.gap_length,
          el_new_campaign: this.el_new_campaign,
          el_product: this.el_product,
          el_campaignNo: this.el_campaignNo,
          el_flightingCode: this.el_flightingCode,
          el_new_flightingCode: this.el_new_flightingCode,
          el_start_date: this.el_start_date,
          el_end_date: this.el_end_date,
          employee_no: this.employee_no
 
        }, {responseType: 'json'}).pipe()
        .subscribe(
            (data: any) => {

                if (data.message === 'success') {
                    this.alertService.success('Promo successfully saved');
                    this.insertElement_Sidebar = false; 
                    this.promo_Sidebar = false;

                    this.onReadLogeditor();
                    
                } else {
                    this.alertService.error(data.message);
                }
            }
        );
      }
}


  async onReadFlightingCodes()
  {

    this.httpClient.get(`https://mojalove.mmsafrica.co.za/api/sales/get_existing_codes.php?prod_code=${this.el_product}`).pipe()
    .subscribe(
      (data:any) =>{

        this.flightingcode_list = [];
      
        for(var i=0;i<data.length;i++)
        {
            this.flightingcode_list[i] = {ccpyId: data[i].ccpyId,duration: data[i].duration,indury_code: data[i].indury_code,prod_code: data[i].prod_code,actual_duration: data[i].actual_duration};
        } 
    
      },error => {
        this.alertService.error("Something went wrong, Try again");
      }
    )
  }

  selectFlightingCode(flightingCode,duration){

    this.el_flightingCode = flightingCode;
    this.el_length = duration;
  }

  selectProduct(productId){

    this.el_product = productId;
    this.onReadFlightingCodes();
  }

  selectCampaign(camp_no,prod_code){
    this.el_campaignNo = camp_no;
    this.el_product = prod_code;
    this.onReadFlightingCodes();
  }

  async onReadProducts()
  {

    const response = this.httpClient.post(`https://mojalove.mmsafrica.co.za/api/sales/product/read_products.php`,
    {
        employee_no: 0,

    },{ responseType: 'json'}).pipe()
    .subscribe(
      (data:any) => {
        
        this.products_array = [];

        for(var i=0;i<data.length;i++) 
        {
          this.products_array[i] = {idNo: data[i].idNo,product_name: data[i].product_name,description: data[i].description,quantity: data[i].quantity,discount: data[i].discount,turnVat: data[i].turnVat,unit_price: data[i].unit_price,vat_rate: data[i].vat_rate,vat_type: data[i].vat_type,date_time: data[i].date_time,image: data[i].image,position: data[i].position,category: data[i].category,company: data[i].company,clientNo: data[i].clientNo};
        }

      } ,error => {
        this.alertService.error("Something went wrong, Try again");
      }
    )
  }

  async onReadCampaigns()
  {

    this.httpClient.get(`https://mojalove.mmsafrica.co.za/api/sales/get_dashboard.php?userId=0&type=campaign`).pipe()
    .subscribe(
      (data:any) =>{

          this.campaign_list = [];
          let index =0;
          for(var i=0;i<data.length;i++) 
          {
            if(data[i].camp_type != "external"){
              this.campaign_list[index] = {advertiser_name: data[i].advertiser_name,agency_code: data[i].agency_code,budget: data[i].budget,business_type: data[i].business_type,camp_no: data[i].camp_no,camp_type: data[i].camp_type,copy_weeks: data[i].copy_weeks,discount: data[i].discount,end_date: data[i].end_date,no_of_spots: data[i].no_of_spots,owner_code: data[i].owner_code,pers_code: data[i].pers_code,prod_code: data[i].prod_code,product_name: data[i].product_name,sst_date: data[i].sst_date,status: data[i].status,total_nominal_price: data[i].total_nominal_price};

              index = index + 1;
            }
            
          }
    
      },error => {
        this.alertService.error("Something went wrong, Try again");
      }
    )
  }

  async onReadEpisodes(prog_no,season_no)
  {
    const response = this.httpClient.post(`https://mojalove.mmsafrica.co.za/api/schedule/programme/get_episodes.php`,
    {
        employee_no: 0,
        prog_no: prog_no,
        seasonNo: season_no,

     },{ responseType: 'json'}).pipe()
    .subscribe(
      (data:any) => {
        
        this.episode_array = [];

        for(var i=0;i<data.length;i++)
        {
            this.episode_array[i] = {epi_len: data[i].epi_len,seas_no: Number(data[i].seas_no),title: data[i].title,b: data[i].b,break_price: data[i].break_price,d: data[i].d,day: data[i].day,epi_date: data[i].epi_date,epi_no: Number(data[i].epi_no),exhibition_no: data[i].exhibition_no,idNo: data[i].idNo,n: data[i].n,nl: data[i].nl,omni_epi_no: data[i].omni_epi_no,p: data[i].p,pg: data[i].pg,prog_name: data[i].prog_name,prog_no: Number(data[i].prog_no),rate_code: data[i].rate_code,replaced: data[i].replaced,run_no: data[i].run_no,s: data[i].s,synopsis: data[i].synopsis,v: data[i].v,material: data[i].material,segments_data: data[i].segments_data};
        }

      } 
    )
  }

  async onReadSeasons(progNo)
  {
    const response = this.httpClient.post(`https://mojalove.mmsafrica.co.za/api/schedule/programme/get_seasons.php`,
    {
        employee_no: 0,
        prog_no: progNo,

     },{ responseType: 'json'}).pipe()
    .subscribe(
      (data:any) => {
        
        this.season_array = [];

        for(var i=0;i<data.length;i++)
        {
            this.season_array[i] = {actors: data[i].actors,directors: data[i].directors,producer: data[i].producer,cost_per_min: data[i].cost_per_min,end_date: data[i].end_date,episodes: data[i].episodes,exhibition_no: data[i].exhibition_no,idNo: data[i].idNo,prog_name: data[i].prog_name,prog_no: Number(data[i].prog_no),run_no: data[i].run_no,seas_no: Number(data[i].seas_no),segments: data[i].segments,sst_date: data[i].sst_date,year_of_release: data[i].year_of_release,censorship: '',pg: '',synopsis: '',hashtags: data[i].hashtags};

            if(this.season_array[i].seas_no == this.segmentData.seas_no){
              this.onReadEpisodes(progNo,this.season_array[i].seas_no);
            }
        }
      } 
    )
  }

    dashboard() 
    {
      this.router.navigate(['/scheduling/dashboard']); 
    }

    schedule()
    {
      this.router.navigate(['/scheduling/new-schedule']); 
    }

    programme()
    {
      this.router.navigate(['/scheduling/programme-list']); 
    }

    export()
    {
      this.router.navigate(['/scheduling/export']); 
    }

    selectProgramme(prog_no) {

      localStorage.setItem('prog_no', JSON.stringify(prog_no));
      this.router.navigate(['/home/view-programme']);
    }

    back(){
      this._location.back();
    }

}
