import { Component, OnInit, ViewChild } from '@angular/core';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';
import { FormGroup, FormBuilder, Validators, FormControl,NgForm} from '@angular/forms';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {SelectionModel} from '@angular/cdk/collections';
import {MenuItem,TreeNode} from 'primeng/api';
import {TreeDragDropService} from 'primeng/api';
import {ScheduleClass} from '../../classes/schedule';
import { SeasonClass } from '../../classes/season';
import { EpisodeClass } from '../../classes/episode';
import {SareClass} from '../../classes/sare';
import {Location} from '@angular/common';
 
import {animate, state, style, transition, trigger} from '@angular/animations';


//Import used to make HTTP get and post requests
import { HttpClient } from '@angular/common/http';
import { LoadingController } from '@ionic/angular';
import { finalize } from 'rxjs/operators';
 
//To use autcomplete field
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { NavController } from '@ionic/angular';

//Toasta
import {AlertService} from '../../services/alert/alert.service';
import { Router, ActivatedRoute } from '@angular/router';
import * as $ from "jquery";

export const MY_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

import * as _moment from 'moment';
import {default as _rollupMoment, Moment} from 'moment';
import { MaterialClass } from 'src/app/classes/material';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';
import { CampaignMaterial } from 'src/app/classes/campaign.material';
const moment = _rollupMoment || _moment;

@Component({
  selector: 'app-spots-material',
  templateUrl: './spots-material.component.html',
  styleUrls: ['./spots-material.component.scss'],
  styles: [`
  .filter-container {
      text-align: center;
  }

  .car-details {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #d9dad9;
  }

  .car-details > div {
      display: flex;
      align-items: center;
  }

  .car-detail {
      padding: 0 1em 1em 1em;
      border-bottom: 1px solid #d9dad9;
  }
`],
animations: [
  trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
  ]),
],
})
export class SpotsMaterialComponent implements OnInit {

  selectedFile: FileList  = null;
  formData = new FormData();
  employee_no: number = 0;
  selected_channel; 

  searchFormGroup: FormGroup;
  channels: SareClass[] = [];
  blnselectAll: boolean = true;
  material_Sidebar: boolean = false;
  material_infor_Sidebar: boolean = false;
  material_status: string = 'All';
  material_type: string = 'All';

  campaignMaterial_array: MaterialClass[] = [];
  material_array: MaterialClass[] = [];
  available_array: MaterialClass[] = [];
  campaign_array: CampaignMaterial[] = [];

  reconFormGroup: FormGroup;
  displayedColumns: string[] = ['camp_no', 'advertiser_name', 'product_name', 'sst_date', 'end_date', 'no_of_spots', 'camp_type'];
  dataSource = new MatTableDataSource(this.campaign_array);
  dataEpisodeSource = new MatTableDataSource(this.campaign_array);
  selection = new SelectionModel<ScheduleClass>(true, []);
  expandedElement: CampaignMaterial | null;


  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild('paginator') paginator: MatPaginator;

  material_displayedColumns: string[] = ['select','MATERIAL_ID', 'MATERIAL_TITLE','DURATION','AGENCY_COPY_NUMBER', 'ENTRY_DATE','ACTION'];
  material_dataSource = new MatTableDataSource(this.material_array);
  material_selection = new SelectionModel<any>(true, []);

  @ViewChild(MatSort, {static: true}) sort2: MatSort;
  @ViewChild('paginator2') paginator2: MatPaginator;

  available_displayedColumns: string[] = ['MATERIAL_ID', 'MATERIAL_TITLE','DURATION','ACTION'];
  available_dataSource = new MatTableDataSource(this.available_array);
  available_selection = new SelectionModel<any>(true, []);

  @ViewChild(MatSort, {static: true}) sort3: MatSort;
  @ViewChild('paginator3') paginator3: MatPaginator;

  //Assign
  selected_spot_no: number = 0;
  selected_camp_no: number = 0;
  material: MaterialClass = new MaterialClass();

  constructor(private alertService: AlertService,route:ActivatedRoute,private router: Router,private formBuilder: FormBuilder,private httpClient: HttpClient,private loadingCtrl: LoadingController,private navCtrl: NavController,public dialog: MatDialog,private _location: Location) 
  {
    this.employee_no = parseInt(JSON.parse(localStorage.getItem('employee_no')));
    this.onChannels();
    this.onReadSpotsMaterials();
  }

  ngOnInit() {

    this.searchFormGroup = this.formBuilder.group({
      selected_channelCtrl: [this.selected_channel,Validators.required],
      material_statusCtrl: [this.material_status,Validators.required],
      material_typeCtrl: [this.material_type,Validators.required],
    });
  }

  ngAfterViewInit(){
    
    //File import
    $("form").on("change", ".file-upload-field", function(){ 
      $(this).parent(".file-upload-wrapper").attr("data-text",         $(this).val().replaceAll(/.*(\/|\\)/, '') );
    });
  }

  isAllSelected() {
    const numSelected = this.material_selection.selected.length;
    const numRows = this.material_dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.material_selection.clear() :
      this.material_dataSource.data.forEach(row => this.material_selection.select(row));
  }


  //Apply filter when using search box
  applyFilter(filterValue: string) {
    this.selection.clear();
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

    //Apply filter when using search box
  applyFilter2(filterValue: string) {
    this.material_selection.clear();
    this.material_dataSource.filter = filterValue.trim().toLowerCase();
  }

  //Apply filter when using search box
  applyFilter3(filterValue: string) {
    this.available_selection.clear();
    this.available_dataSource.filter = filterValue.trim().toLowerCase();
  }


  removeMaterial(camp_no,spot_no,materialId): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '400px',
        data: 'Are you sure you want to remove material '+materialId+' ?'
    });
    
    dialogRef.afterClosed().subscribe(result => {
        if (result) {

            this.remMaterial(camp_no,spot_no,materialId);
        }
    });
  }

  remMaterial(camp_no,spot_no,materialId) {
    const response = this.httpClient.post(`https://mojalove.mmsafrica.co.za/api/schedule/material/remove_spot_material.php`,
        {
          spot_no: spot_no,
          materialId: materialId
    
        }, {responseType: 'json'}).pipe()
        .subscribe(
            (data: any) => {
    
                if (data.message === 'success') {
                    this.alertService.success('Material successfully removed');

                    for(var i=0;i<this.campaignMaterial_array.length;i++)
                    {
                        if(this.campaignMaterial_array[i].MATERIAL_ID == materialId){
                          this.available_array.push(this.campaignMaterial_array[i]);

                          this.available_dataSource = new MatTableDataSource(this.available_array);
                          this.available_dataSource.sort = this.sort3;
                          this.available_dataSource.paginator = this.paginator3;
                          

                          //Updating table after removing (USELESS CODE- JUST FOR VIEW)
                          for(var x=0;x<this.campaign_array.length;x++)
                          {
                            if(this.campaign_array[x].camp_no == camp_no){
                              for(var x2=0;x2<this.campaign_array[x].spots.length;x2++)
                              {
                                if(this.campaign_array[x].spots[x2].spot_no == spot_no){
                                  this.campaign_array[x].spots[x2].material = '';
                                }
                              }
                            }
                          }
                          //END--OF--UPDATING TABLE

                        }
                    }

                } else {
                    this.alertService.error(data.message);
                }
            }
        );
 }

 
 viewMaterial(materialElement: MaterialClass) {

  this.material = materialElement;
  this.material_infor_Sidebar = true;
 }


 viewMaterialData(materialId) {

  for(var i=0;i<this.campaignMaterial_array.length;i++){
      if(this.campaignMaterial_array[i].MATERIAL_ID == materialId){
        this.material = this.campaignMaterial_array[i];
      }
  }

  this.material_infor_Sidebar = true;
 }


 assignMaterial(camp_no,spot_no) {

  this.selected_spot_no = spot_no;
  this.selected_camp_no = camp_no;
  this.material_Sidebar = true;
 }

 addMaterial(materialId,object) {

      const response = this.httpClient.post(`https://mojalove.mmsafrica.co.za/api/schedule/material/add_spot_material.php`,
      {
        spot_no: this.selected_spot_no,
        materialId: materialId

      }, {responseType: 'json'}).pipe()
      .subscribe(
          (data: any) => {

              if (data.message === 'success') {
                  this.alertService.success('Material successfully saved');
                  this.material_Sidebar = false;
                  
                  const index = this.available_array.indexOf(object, 0);
                  if (index > -1) {
                    this.available_array.splice(index, 1);
                  }

                  //Updating table after removing (USELESS CODE- JUST FOR VIEW)
                  for(var x=0;x<this.campaign_array.length;x++)
                  {
                    if(this.campaign_array[x].camp_no == this.selected_camp_no){
                      for(var x2=0;x2<this.campaign_array[x].spots.length;x2++)
                      {
                        if(this.campaign_array[x].spots[x2].spot_no == this.selected_spot_no){
                          this.campaign_array[x].spots[x2].material = materialId;
                        }
                      }
                    }
                  }
                  //END--OF--UPDATING TABLE


                  this.available_dataSource = new MatTableDataSource(this.available_array);
                  this.available_dataSource.sort = this.sort3;
                  this.available_dataSource.paginator = this.paginator3;
                  
              } else {
                  this.alertService.error(data.message);
              }
          }
      );
  }

  async onReadSpotsMaterials(){ 
      
    const response = this.httpClient.post(`https://mojalove.mmsafrica.co.za/api/schedule/material/read_spots_materials.php`,
    {
      material_type: 'All',
      employee_no: this.employee_no,

    },{ responseType: 'json'}).pipe( )
    .subscribe(
      (data:any) => {
         
        this.campaignMaterial_array = [];
        this.available_array = [];
        let index = 0;

        for(var i=0;i<data.length;i++)
        {
            this.campaignMaterial_array[i] = {idNo: data[i].idNo,_NOT_ASSIGNED: data[i]._NOT_ASSIGNED,_NOT_ASSIGNED_TIME: data[i]._NOT_ASSIGNED_TIME,ABBREVIATED_TITLE: data[i].ABBREVIATED_TITLE,AGENCY_COPY_NUMBER: data[i].AGENCY_COPY_NUMBER,ASPECT_RATIO: data[i].ASPECT_RATIO,AUDIO1: data[i].AUDIO1,AUDIO2: data[i].AUDIO2,AUDIO3: data[i].AUDIO3,AUDIO4: data[i].AUDIO4,CHANGE_TIME: data[i].CHANGE_TIME,DESCRIPTION: data[i].DESCRIPTION,DURATION: data[i].DURATION,EMBARGO_DATE: data[i].EMBARGO_DATE,ENTRY_DATE: data[i].ENTRY_DATE,ENTRY_OPERATOR: data[i].ENTRY_OPERATOR,EXPIRE_DATE: data[i].EXPIRE_DATE,FRAME_RATE: data[i].FRAME_RATE,GROUP_ID: data[i].GROUP_ID,MATERIAL_EVENTS: data[i].MATERIAL_EVENTS,MATERIAL_ID: data[i].MATERIAL_ID,MATERIAL_TITLE: data[i].MATERIAL_TITLE,MATERIAL_TYPE: data[i].MATERIAL_TYPE,NOMINAL_DURATION: data[i].NOMINAL_DURATION,RELEASED: data[i].RELEASED,SEGMENTS: data[i].SEGMENTS,SOM: data[i].SOM,status: data[i].status};

            if(data[i].status == '' || data[i].status == null){
              this.available_array[index] = this.campaignMaterial_array[i];
              index = index + 1;
            }
        }

        this.available_dataSource = new MatTableDataSource(this.available_array);
        this.available_dataSource.sort = this.sort3;
        this.available_dataSource.paginator = this.paginator3;
      } 
    ) 
  }

  async onReadMaterials(){ 
      
    const response = this.httpClient.post(`https://mojalove.mmsafrica.co.za/api/schedule/material/read_spots_materials.php`,
    {
      material_type: this.material_type,
      employee_no: this.employee_no,

    },{ responseType: 'json'}).pipe( )
    .subscribe(
      (data:any) => {
         
        this.material_array = [];

        for(var i=0;i<data.length;i++)
        {
            this.material_array[i] = {idNo: data[i].idNo,_NOT_ASSIGNED: data[i]._NOT_ASSIGNED,_NOT_ASSIGNED_TIME: data[i]._NOT_ASSIGNED_TIME,ABBREVIATED_TITLE: data[i].ABBREVIATED_TITLE,AGENCY_COPY_NUMBER: data[i].AGENCY_COPY_NUMBER,ASPECT_RATIO: data[i].ASPECT_RATIO,AUDIO1: data[i].AUDIO1,AUDIO2: data[i].AUDIO2,AUDIO3: data[i].AUDIO3,AUDIO4: data[i].AUDIO4,CHANGE_TIME: data[i].CHANGE_TIME,DESCRIPTION: data[i].DESCRIPTION,DURATION: data[i].DURATION,EMBARGO_DATE: data[i].EMBARGO_DATE,ENTRY_DATE: data[i].ENTRY_DATE,ENTRY_OPERATOR: data[i].ENTRY_OPERATOR,EXPIRE_DATE: data[i].EXPIRE_DATE,FRAME_RATE: data[i].FRAME_RATE,GROUP_ID: data[i].GROUP_ID,MATERIAL_EVENTS: data[i].MATERIAL_EVENTS,MATERIAL_ID: data[i].MATERIAL_ID,MATERIAL_TITLE: data[i].MATERIAL_TITLE,MATERIAL_TYPE: data[i].MATERIAL_TYPE,NOMINAL_DURATION: data[i].NOMINAL_DURATION,RELEASED: data[i].RELEASED,SEGMENTS: data[i].SEGMENTS,SOM: data[i].SOM,status: data[i].status};
        }
        
        this.material_dataSource = new MatTableDataSource(this.material_array);
        this.material_dataSource.sort = this.sort2;
        this.material_dataSource.paginator = this.paginator2;
      } 
    ) 
  }

  onClickRow(expandedElement, element) {
  this.dataEpisodeSource.data = element.season_data;
  this.expandedElement = this.expandedElement === element ? null : element
  }

  async onReadCampaigns(){ 
      
    const response = this.httpClient.post(`https://mojalove.mmsafrica.co.za/api/schedule/material/read_campaigns.php`,
    {
      channes: this.selected_channel,
      employee_no: this.employee_no,
      material_status: this.material_status

    },{ responseType: 'json'}).pipe( )
    .subscribe(
      (data:any) => {
         
        this.campaign_array = [];
        
        for(var i=0;i<data.length;i++)
        {
            this.campaign_array[i] = {advertiser_name: data[i].advertiser_name,agency_code: data[i].agency_code,budget: data[i].budget,business_type: data[i].business_type,camp_no: data[i].camp_no,camp_type: data[i].camp_type,copy_weeks: data[i].copy_weeks,discount: data[i].discount,end_date: data[i].end_date,no_of_spots: data[i].no_of_spots,owner_code: data[i].owner_code,pers_code: data[i].pers_code,prod_code: data[i].prod_code,product_name: data[i].product_name,spots: data[i].spots,sst_date: data[i].sst_date,status: data[i].status,total_nominal_price: data[i].total_nominal_price};
        }

        this.dataSource = new MatTableDataSource(this.campaign_array);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      } 
    ) 
  }


  async onChannels(){ 
      
    const response = this.httpClient.post(`https://mojalove.mmsafrica.co.za/api/schedule/get_channels.php`,
    {
      channes: this.selected_channel,
      date: moment().format("YYYY/MM/DD"),
      employee_no: this.employee_no,

    },{ responseType: 'json'}).pipe()
    .subscribe(
      (data:any) => {
         
        for(var i=0;i<data.length;i++)
        {
            this.channels[i] = {bdcr_no: data[i].bdcr_no,genre: data[i].genre,platform: data[i].platform,sare_name: data[i].sare_name,sare_no: data[i].sare_no};
            this.selected_channel = data[i].sare_no;
        }
      } 
    ) 
  }

  back(){
    this._location.back(); 
  }

}