import {Component, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {AlertService} from '../../services/alert/alert.service';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {environment} from '../../../environments/environment';
import {BehaviorSubject} from 'rxjs';
import {SelectionModel} from '@angular/cdk/collections';
import {ConfirmationDialogComponent} from '../confirmation-dialog/confirmation-dialog.component';
import {Location} from '@angular/common';
 
//Import used to make HTTP get and post requests
import {HttpClient} from '@angular/common/http';
import {LoadingController, NavController} from '@ionic/angular'; 
import {finalize} from 'rxjs/operators';
import { UserClass } from 'src/app/classes/users';

@Component({
  selector: 'app-users-list',
  templateUrl: './users-list.component.html',
  styleUrls: ['./users-list.component.scss'],
})
export class UsersListComponent implements OnInit {

  campaign: any;
  users_list: UserClass[];
  baseUrl = environment.serverUrl;
  displayedColumns: string[] = ['select', 'firstName', 'surname', 'emailAddress', 'phoneNumber', 'accountType',
    'status','edit'];
  dataSource: any;
  selection = new SelectionModel<UserClass>(true, [], true);
  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private router: Router, private alertService: AlertService,private _location: Location,private httpClient: HttpClient, private loadingCtrl: LoadingController, private navCtrl: NavController, public dialog: MatDialog,) {

    this.onReadUsers();
  }

  ngOnInit() {
   
    
  }

  /*ionViewWillEnter(){

    this.onReadUsers();
  }*/

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }


  openDeleteDialog(): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      data: 'Are you sure you want to delete the selected users?'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {

        this.selection.selected.forEach(user => {          
          this.deleteUser(user.pers_code);
        });
        
        this.selection.clear(); 
        this.alertService.success('Users successfully deleted')
      }
    });
  }

  async deleteUser(userId)
  {
    this.httpClient.get(`https://mojalove.mmsafrica.co.za/api/admin/delete_user.php?userId=${userId}`).pipe()
    .subscribe(
      (data:any) =>{

        this.onReadUsers();
      },error => {
        this.alertService.error("Something went wrong, Try again");
      }
    )
  }


  async onReadUsers(){ 

    const response = this.httpClient.post(`https://mojalove.mmsafrica.co.za/api/admin/read_all_employee.php`,
    {
      employee_no: 0,

    },{ responseType: 'json'}).pipe()
    .subscribe(
      (data:any) => {
         
        this.users_list = [];
        
        for(var i=0;i<data.length;i++)
        {
            this.users_list[i] = {pers_code: data[i].pers_code,name: data[i].name,surname: data[i].surname,bdcr_no: data[i].bdcr_no,paswd: data[i].paswd,email: data[i].email,role_id: data[i].role_id,atemps: data[i].atemps,phoneNumber: data[i].phoneNumber,accountType: data[i].accountType,status: data[i].status,analytics_delete: null,
              analytics_read: null,
              analytics_write:null,
              content_management_delete:null,
              content_management_read:null,
              content_management_write:null,
              content_sales_delete:null,
              content_sales_read:null,
              content_sales_write:null,
              elearning_delete:null,
              elearning_read:null,
              elearning_write:null,
              finance_delete:null,
              finance_read:null,
              finance_write:null,
              hr_delete:null,
              hr_read:null,
              hr_write:null,
              inventory_management_delete:null,
              inventory_management_read:null,
              inventory_management_write:null,
              market_place_delete:null,
              market_place_read:null,
              market_place_write:null,
              mat_man_delete:null,
              mat_man_read:null,
              mat_man_write:null,
              news_delete:null,
              news_read:null,
              news_write:null,
              production_services_delete:null,
              production_services_read:null,
              production_services_write:null,
              prog_delete:null,
              prog_read:null,
              prog_write:null,
              project_management_delete:null,
              project_management_read:null,
              project_management_write:null,
              revenue_management_delete:null,
              revenue_management_read:null,
              revenue_management_write:null,
              schedule_delete:null,
              schedule_read:null,
              schedule_write:null,
              web2app_delete:null,
              web2app_read:null,
              web2app_write:null};
        } 

        this.dataSource = new MatTableDataSource<UserClass>(this.users_list);
        this.dataSource.paginator = this.paginator;

      },error => {
        this.alertService.error("Something went wrong, Try again");
      } 
    ) 
  }

  editUser(userId){

    localStorage.setItem('userId', JSON.stringify(userId));
    this.router.navigate(['/home/edit-user']);
  }
 
  addNewUser(){
    this.router.navigate(['/home/new-user']);
  }

  returnToDashboard() {
    this.router.navigate(['/home']);
  }

  back(){
    this._location.back();
  }

}