import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators} from '@angular/forms';
import { MatDialog} from '@angular/material/dialog';
import { SeasonClass } from '../../classes/season';
import { EpisodeClass } from '../../classes/episode';
import {SareClass} from '../../classes/sare';
import {ProgrammeClass} from '../../classes/programme';
import {Location} from '@angular/common';

import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {SelectionModel} from '@angular/cdk/collections';

 
//Import used to make HTTP get and post requests
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import {AlertService} from '../../services/alert/alert.service';

export const MY_FORMATS = { 
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

import * as _moment from 'moment';
import {default as _rollupMoment, Moment} from 'moment';
const moment = _rollupMoment || _moment;

@Component({
  selector: 'app-planning',
  templateUrl: './planning.component.html',
  styleUrls: ['./planning.component.scss'],
})
export class PlanningComponent implements OnInit {

  employee_no: number = 0;
  searchFormGroup: FormGroup;
  channels: SareClass[] = [];
  display_array: ProgrammeClass[] = [];
  season_array: SeasonClass[] = [];
  episode_array: EpisodeClass[] = [];

  selected_channel;
  prog_no: number = 0;
  season_no: number = 0;

  start_date: String;
  end_date: String;
  start_time: String = "00:00";
  end_time: String = "23:59";

  mon = false;
  tue = false;
  wed = false;
  thu = false;
  fri = false;
  sat = false;
  sun = false;

  displayedColumns: string[] = ['select','programme','seasonNo', 'episodeNo'];
  dataSource = new MatTableDataSource(this.episode_array);
  selection = new SelectionModel<EpisodeClass>(true, []);

  constructor(private alertService: AlertService,route:ActivatedRoute,private router: Router,private formBuilder: FormBuilder,private httpClient: HttpClient,public dialog: MatDialog,private _location: Location) 
  {
    this.employee_no = parseInt(JSON.parse(localStorage.getItem('employee_no')));
    this.onChannels();
  }

  ngOnInit() {

    this.searchFormGroup = this.formBuilder.group({
      selected_channelCtrl: [this.selected_channel],
      progNoCtrl: [this.prog_no],
      seasonNoCtrl: [this.season_no],
      start_dateCtrl: [this.start_date,Validators.required],
      end_dateCtrl: [this.end_date,Validators.required],
      start_timeCtrl: [this.start_time,Validators.required],
      end_timeCtrl: [this.end_time,Validators.required],
      monCtrl: [this.mon],
      tueCtrl: [this.tue],
      wedCtrl: [this.wed],
      thuCtrl: [this.thu],
      friCtrl: [this.fri],
      satCtrl: [this.sat],
      sunCtrl: [this.sun], 
    });

  }


  async schedulePlanning()
  {
    var start_date = moment(this.start_date.toString()).format("YYYY/MM/DD");
    var end_date = moment(this.end_date.toString()).format("YYYY/MM/DD");

    var strstart_time = this.start_time.toString()+":00";

    const response = this.httpClient.post(`https://mojalove.mmsafrica.co.za/api/schedule/schedule/schedule_planning.php`,
    {
      start_date: start_date,
      end_date: end_date,
      channelNo: this.selected_channel,
      prog_no: this.prog_no,
      season_no: this.season_no,
      start_time: strstart_time,
      selection: this.selection.selected,
      mon: this.mon,
      tue: this.tue,
      wed: this.wed,
      thu: this.thu,
      fri: this.fri,
      sat: this.sat,
      sun: this.sun,

    },{ responseType: 'json'}).pipe()
    .subscribe(
      (data:any) => {
         
        if(data.message == 'success')
        {
          this.alertService.success(data.episodes+' Episodes successfully scheduled');
        }
        else
        { 
          this.alertService.error(data.message);
        }

      } 
    ) 
  }

  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  } 

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
        this.selection.clear() :
        this.dataSource.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: EpisodeClass): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.idNo + 1}`;
  }

  //Apply filter when using search box
  applyFilter(filterValue: string) {
    this.selection.clear();
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }


  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  async onReadEpisodes(seaN)
  {
    this.season_no = seaN;
    
    const response = this.httpClient.post(`https://mojalove.mmsafrica.co.za/api/schedule/programme/get_episodes.php`,
    {
        employee_no: this.employee_no,
        prog_no: this.prog_no,
        seasonNo: this.season_no,

     },{ responseType: 'json'}).pipe()
    .subscribe(
      (data:any) => {
        
        this.episode_array = [];

        for(var i=0;i<data.length;i++)
        {
            this.episode_array[i] = {epi_len: data[i].epi_len,seas_no: data[i].seas_no,title: data[i].title,b: data[i].b,break_price: data[i].break_price,d: data[i].d,day: data[i].day,epi_date: data[i].epi_date,epi_no: data[i].epi_no,exhibition_no: data[i].exhibition_no,idNo: data[i].idNo,n: data[i].n,nl: data[i].nl,omni_epi_no: data[i].omni_epi_no,p: data[i].p,pg: data[i].pg,prog_name: data[i].prog_name,prog_no: data[i].prog_no,rate_code: data[i].rate_code,replaced: data[i].replaced,run_no: data[i].run_no,s: data[i].s,synopsis: data[i].synopsis,v: data[i].v,material: data[i].material,segments_data: data[i].segments_data};
        }

        this.dataSource = new MatTableDataSource(this.episode_array);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
      } 
    )
  }

  async onReadSeasons(progN)
  {
    this.prog_no = progN;
    const response = this.httpClient.post(`https://mojalove.mmsafrica.co.za/api/schedule/programme/get_seasons.php`,
    {
        employee_no: this.employee_no,
        prog_no: this.prog_no,

     },{ responseType: 'json'}).pipe()
    .subscribe(
      (data:any) => {
        
        this.season_array = [];
        this.episode_array = [];
        this.season_no = 0;

        for(var i=0;i<data.length;i++)
        {
            this.season_array[i] = {actors: data[i].actors,directors: data[i].directors,producer: data[i].producer,cost_per_min: data[i].cost_per_min,end_date: data[i].end_date,episodes: data[i].episodes,exhibition_no: data[i].exhibition_no,idNo: data[i].idNo,prog_name: data[i].prog_name,prog_no: data[i].prog_no,run_no: data[i].run_no,seas_no: data[i].seas_no,segments: data[i].segments,sst_date: data[i].sst_date,year_of_release: data[i].year_of_release,censorship: '',pg: '',synopsis: '',hashtags: data[i].hashtags};
        }
      } 
    )
  }

  validatePeriod(){
    
  }

  async onReadProgrammes(){ 
        
    const response = this.httpClient.post(`https://mojalove.mmsafrica.co.za/api/schedule/programme/get_programme.php`,
    {
      channes: this.selected_channel,
      employee_no: this.employee_no,

    },{ responseType: 'json'}).pipe()
    .subscribe(
      (data:any) => {
         
        this.display_array = [];
        
        for(var i=0;i<data.length;i++)
        {
            this.display_array[i] = {genre: data[i].genre,image: data[i].image,language: data[i].language,length: data[i].length,prod_house: data[i].prod_house,producer: data[i].producer,prog_name: data[i].prog_name,prog_no: data[i].prog_no,bdcr_no: data[i].bdcr_no,type: data[i].type,category: data[i].category,rcrd_no: data[i].rcrd_no,repeat_name: data[i].repeat_name,sare_no: data[i].sare_no,subgenre: data[i].subgenre,season_data: data[i].season_data,genre_sentech: data[i].genre_sentech,subgenre_sentech: data[i].subgenre_sentech,
              contract_no: data[i].contract_no,
              contract_type: data[i].contract_type,
              distributors: data[i].distributors};
        }

      } 
    ) 
  }

  async onChannels(){ 

    const response = this.httpClient.post(`https://mojalove.mmsafrica.co.za/api/schedule/get_channels.php`,
    {
      channes: this.selected_channel,
      date: moment().format("YYYY/MM/DD"),
      employee_no: this.employee_no,

    },{ responseType: 'json'}).pipe()
    .subscribe(
      (data:any) => {
         
        for(var i=0;i<data.length;i++)
        {
            this.channels[i] = {bdcr_no: data[i].bdcr_no,genre: data[i].genre,platform: data[i].platform,sare_name: data[i].sare_name,sare_no: data[i].sare_no};
        }

      } 
    ) 
  }

  back(){
    this._location.back();
  }

}
  