export class SponsorshipSpotModel {
  element: string;
  segmentNumber: number;
  duration: number;
  rateCardId: string;
  rate: number;
  breakScheduleDate: string;
  day: string;
  industryCode: string;
  discount: number;
  sponsorshipCost: number;
  rateCardTotal: number;
  elementId: number;

  constructor(element = '', segmentNumber = 0, duration = 0, rateCardId = '', rate = 0, breakScheduleDate = '', day = '', industryCode = '',
              discount = 0, sponsorshipCost = 0, rateCardTotal = 0, elementId = 0) {
    this.element = element;
    this.segmentNumber = segmentNumber;
    this.duration = duration;
    this.rateCardId = rateCardId;
    this.rate = rate;
    this.breakScheduleDate = breakScheduleDate;
    this.day = day;
    this.industryCode = industryCode;
    this.discount = discount;
    this.sponsorshipCost = sponsorshipCost;
    this.rateCardTotal = rateCardTotal;
    this.elementId = elementId;
  }
}
