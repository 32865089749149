import {Component, OnInit, ViewChild} from '@angular/core';
import {PackageService} from '../../services/package/package.service';
import {CampaignSpotService} from '../../services/campaignSpot/campaign-spot.service';
import {CampaignService} from '../../services/campaign/campaign.service';
import {Router} from '@angular/router';
import {CampaignSpotModel} from '../../models/campaign-spot.model';
import {AlertService} from '../../services/alert/alert.service';
import {MatDialog} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {AllocateFlightingCodeDialogComponent} from '../allocate-flighting-code-dialog/allocate-flighting-code-dialog.component';
import {FlightingCodeService} from '../../services/flighting-code/flighting-code.service';
import {environment} from '../../../environments/environment';
import {EditCampaignSpotDialogComponent} from '../edit-campaign-spot-dialog/edit-campaign-spot-dialog.component';
import {AddSpotDialogComponent} from '../add-spot-dialog/add-spot-dialog.component';
import {BehaviorSubject} from 'rxjs';
import {SelectionModel} from '@angular/cdk/collections';
import {SponsorshipSpotModel} from '../../models/sponsorship-spot.model';
import {ConfirmationDialogComponent} from '../confirmation-dialog/confirmation-dialog.component';
import {Location} from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { FormGroup, Validators,FormBuilder,FormControl } from '@angular/forms';
import { CampaignClass } from 'src/app/classes/campaign';
import { ClientClass } from 'src/app/classes/client';
import { ProductClass } from 'src/app/classes/product';

@Component({
  selector: 'app-spot-view',
  templateUrl: './spot-view.component.html', 
  styleUrls: ['./spot-view.component.scss']
})
export class SpotViewComponent implements OnInit {
  campaign: any; 
  campaignSpots: CampaignSpotModel[];
  selectedSpots: CampaignSpotModel[];
  baseUrl = environment.serverUrl; 
  displayedColumns: string[] = ['select', 'channelName', 'breakScheduledDate', 'startTime', 'endTime', 'rate',
    'duration', 'u1', 'industryCode', 'programName'];
  dataSource: any;
  selection = new SelectionModel<CampaignSpotModel>(true, [], true);
  @ViewChild(MatTable) table: MatTable<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator; 

  campaignFormGroup: FormGroup;
  campaign_class: CampaignClass = new CampaignClass();

  display_array: ClientClass[] = [];
  products_array: ProductClass[] = [];
  agency_array: ClientClass[] = [];

  constructor(private packageService: PackageService, private campaignSpotService: CampaignSpotService,private campaignService: CampaignService,
              private router: Router, private alertService: AlertService, public dialog: MatDialog,
              private flightingCodeService: FlightingCodeService,private _location: Location,private httpClient: HttpClient,private formBuilder: FormBuilder) {

      
  }

  ngOnInit() {
    this.campaign = this.packageService.getCreatedPackage();
    if (this.campaign) {
      this.getCampaignSpots(this.campaign.campaignId);
    } else {
      this.campaign = JSON.parse(localStorage.getItem('selectedCampaign'));
      if (this.campaign && this.campaign.campaignId) {
        this.getCampaignSpots(this.campaign.campaignId);
      } else {
        this.returnToDashboard();
      }
    }

    this.campaignFormGroup = this.formBuilder.group({
      agency_codeCtrl: [this.campaign_class.agency_code, Validators.required],
      budgetCtrl: [this.campaign_class.budget, Validators.required],
      business_typeCtrl: [this.campaign_class.business_type, Validators.required],
      camp_noCtrl: [this.campaign_class.camp_no, Validators.required],
      discountCtrl: [this.campaign_class.discount],
      end_dateCtrl: [this.campaign_class.end_date, Validators.required],
      no_of_spotsCtrl: [this.campaign_class.no_of_spots],
      owner_codeCtrl: [this.campaign_class.owner_code, Validators.required],
      pers_codeCtrl: [this.campaign_class.pers_code],
      prod_codeCtrl: [this.campaign_class.prod_code, Validators.required],
      sst_dateCtrl: [this.campaign_class.sst_date, Validators.required],
      statusCtrl: [this.campaign_class.status, Validators.required],
      total_nominal_priceCtrl: [this.campaign_class.total_nominal_price]});

    this.onReadOneCampain();
    this.onReadProducts();
    this.onReadAgencies();
    this.onReadAdvertisers();
  }

  async saveCampaign(){ 

    const response = this.httpClient.post(`https://mojalove.mmsafrica.co.za/api/sales/update_campaign.php`,
    {
      camp_no: this.campaign_class.camp_no,
      prod_code: this.campaign_class.prod_code,
      owner_code: this.campaign_class.owner_code,
      agency_code: this.campaign_class.agency_code,
      sst_date: this.campaign_class.sst_date,
      end_date: this.campaign_class.end_date,
      budget: this.campaign_class.budget,
      business_type: this.campaign_class.business_type,
      status: this.campaign_class.status,

    },{ responseType: 'json'}).pipe()
    .subscribe(
      (data:any) => {
         
        if(data.message == 'success')
        {
          this.onReadOneCampain();
          this.alertService.success("Campaign successfully saved");
        }
        else{

          this.alertService.error(data.message);
        }

      },error => {
        this.alertService.error("Something went wrong, Try again");
      } 
    ) 
  }

  async onReadAgencies()
  {

    const response = this.httpClient.post(`https://mojalove.mmsafrica.co.za/api/sales/agency/filter_clients.php`,
    {
        employee_no: 0,

    },{ responseType: 'json'}).pipe()
    .subscribe(
      (data:any) => {
        
        this.agency_array = [];

        for(var i=0;i<data.length;i++)
        { 
            var strLate_fee_value = 0;
            this.agency_array[i] = {type: data[i].type,idNo: data[i].idNo,employee_no: data[i].employee_no,company: data[i].company,first_name: data[i].first_name,last_name: data[i].last_name,email: data[i].email,phone: data[i].phone,address: data[i].address,city: data[i].city,state: data[i].state,zip_code: data[i].zip_code,vat_number: data[i].vat_number,tax_number: data[i].tax_number, currency: data[i].currency,rem_days: data[i].rem_days,rem_position: data[i].rem_position,late_fee_percentage: data[i].late_fee_percentage,late_fee_option: data[i].late_fee_option,late_fee_value: strLate_fee_value,late_fee_days: data[i].late_fee_days,late_fee_tax_rate: data[i].late_fee_tax_rate,late_fee_tax_name: data[i].late_fee_tax_name,late_fee_tax_number: data[i].late_fee_tax_number,company_logo: data[i].company_logo,date_time: data[i].date_time,checked: false,ship_address: data[i].ship_address,ship_city: data[i].ship_city,ship_state: data[i].ship_state,ship_zip_code: data[i].ship_zip_code,agencyId: data[i].agencyId,agency: ''};
        }
        
      },error => {

        this.alertService.error("Something went wrong, Try again");
      } 
    )
  }

  async onReadProducts()
  {
    const response = this.httpClient.post(`https://mojalove.mmsafrica.co.za/api/sales/product/read_products.php`,
    {
        employee_no: 0,

    },{ responseType: 'json'}).pipe()
    .subscribe(
      (data:any) => {
        
        this.products_array = [];

        for(var i=0;i<data.length;i++) 
        {
          this.products_array[i] = {idNo: data[i].idNo,product_name: data[i].product_name,description: data[i].description,quantity: data[i].quantity,discount: data[i].discount,turnVat: data[i].turnVat,unit_price: data[i].unit_price,vat_rate: data[i].vat_rate,vat_type: data[i].vat_type,date_time: data[i].date_time,image: data[i].image,position: data[i].position,category: data[i].category,company: data[i].company,clientNo: data[i].clientNo};
        }

      } ,error => {
        this.alertService.error("Something went wrong, Try again");
      }
    )
  }

  async onReadAdvertisers()
  {
    const response = this.httpClient.post(`https://mojalove.mmsafrica.co.za/api/sales/client/filter_clients.php`,
    {
        employee_no: 0,

    },{ responseType: 'json'}).pipe()
    .subscribe(
      (data:any) => { 
        
        this.display_array = [];

        for(var i=0;i<data.length;i++)
        { 
            var strLate_fee_value = 0;
            this.display_array[i] = {type: data[i].type,idNo: data[i].idNo,employee_no: data[i].employee_no,company: data[i].company,first_name: data[i].first_name,last_name: data[i].last_name,email: data[i].email,phone: data[i].phone,address: data[i].address,city: data[i].city,state: data[i].state,zip_code: data[i].zip_code,vat_number: data[i].vat_number,tax_number: data[i].tax_number, currency: data[i].currency,rem_days: data[i].rem_days,rem_position: data[i].rem_position,late_fee_percentage: data[i].late_fee_percentage,late_fee_option: data[i].late_fee_option,late_fee_value: strLate_fee_value,late_fee_days: data[i].late_fee_days,late_fee_tax_rate: data[i].late_fee_tax_rate,late_fee_tax_name: data[i].late_fee_tax_name,late_fee_tax_number: data[i].late_fee_tax_number,company_logo: data[i].company_logo,date_time: data[i].date_time,checked: false,ship_address: data[i].ship_address,ship_city: data[i].ship_city,ship_state: data[i].ship_state,ship_zip_code: data[i].ship_zip_code,agencyId: data[i].agencyId,agency: ''};
        }
        
      },error => {

        this.alertService.error("Something went wrong, Try again");
      } 
    )
  }

  async onReadOneCampain()
  {

    const response = this.httpClient.post(`https://mojalove.mmsafrica.co.za/api/sales/get_one_campaign.php`,
    {
        employee_no: 0,
        camp_no: this.campaign.campaignId,

    },{ responseType: 'json'}).pipe()
    .subscribe(
      (data:any) => {

          this.campaign_class.agency_code = Number(data[0].agency_code);
          this.campaign_class.budget = Number(data[0].budget);
          this.campaign_class.business_type = data[0].business_type;
          this.campaign_class.camp_no = Number(data[0].camp_no);
          this.campaign_class.discount = Number(data[0].discount);
          this.campaign_class.end_date = data[0].end_date;
          this.campaign_class.no_of_spots = Number(data[0].no_of_spots);
          this.campaign_class.owner_code = Number(data[0].owner_code);
          this.campaign_class.pers_code = Number(data[0].pers_code);
          this.campaign_class.prod_code = Number(data[0].prod_code);
          this.campaign_class.sst_date = data[0].sst_date;
          this.campaign_class.status = data[0].status;
          this.campaign_class.total_nominal_price = Number(data[0].total_nominal_price);

      } ,error => {

        this.alertService.error("Something went wrong, Try again");
      }
    )
  }

  getCampaignSpots(campaignId) {
    this.campaignSpotService.getCampaignSpotsForCampaign(campaignId)
      .subscribe(campaignSpots => {
          this.campaignSpots = campaignSpots;
          this.dataSource = new MatTableDataSource<CampaignSpotModel>(campaignSpots);
          this.dataSource.paginator = this.paginator;
         
        },
        error => {
          this.alertService.error("Something went wrong, Try again");
        });
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.dataSource.data.forEach(row => this.selection.select(row));
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(AllocateFlightingCodeDialogComponent, {
      width: '600px',
      data: {flightingCode: '', spots: this.selection, productId: this.campaign.productId}
    });

    dialogRef.afterClosed().subscribe(result => {
      if (this.selection) {
        this.selection.selected.forEach(spot => spot.industryCode = result.flightingCode);
        this.flightingCodeService.saveFlightingCodes(result).subscribe(success =>
          this.alertService.success('Code successfully allocated'), error => this.alertService.error('Code not saved'));
        this.selection.clear();
      }
      
    });
  }


  openAddDialog(): void {
    const dialogRef = this.dialog.open(AddSpotDialogComponent, {
      width: '600px',
      data: new CampaignSpotModel()
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        result.campaignId = this.campaign.campaignId;
        this.campaignSpots.push(result);
        this.dataSource.data = this.campaignSpots;
        this.table.renderRows();
        
        this.campaignSpotService.addSpot(result).subscribe(success =>
        {
          this.alertService.success('Spots successfully added');
          this.getCampaignSpots(this.campaign.campaignId);
        }
        , error => this.alertService.error('Spot not saved'));
      }
    });
  }

  openDeleteDialog(): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      data: 'Are you sure you want to delete the selected spots?'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let spotsToDelete = [];
        this.selection.selected.forEach(spot => {
          spotsToDelete.push(spot.spotNo);
           const index = this.campaignSpots.indexOf(spot, 0);
          this.campaignSpots.splice(index, 1);
        });

        this.selection.clear();
        this.dataSource.data = this.campaignSpots;
        this.table.renderRows();
        this.campaignSpotService.deleteSpots(spotsToDelete).subscribe(success =>
        this.alertService.success('Spots successfully deleted'), error => this.alertService.error('Spot not deleted'));
      }
    });
  }

  openEditDialog(): void {
    const dialogRef = this.dialog.open(EditCampaignSpotDialogComponent, {
      width: '600px',
      data: new CampaignSpotModel()
    });

    dialogRef.afterClosed().subscribe(result => {
      if (this.selection && result) {

        this.campaignSpotService.editSpots(this.selection.selected,result).subscribe(success =>
        {
          this.alertService.success('Spots successfully updated')
          this.getCampaignSpots(this.campaign.campaignId);
          this.selection.clear();
          this.table.renderRows();
        }
        
        , error => this.alertService.error('Spot changes not saved'));
      }
    });
  }
 
  openDeleteCampaignDialog(): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      data: 'Are you sure you want to delete campaign ?'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {

        this.deleteCampaign(this.campaign.campaignId);
      }
    });
  }

  async deleteCampaign(campaignNo)
  {
    this.httpClient.get(`https://mojalove.mmsafrica.co.za/api/sales/delete_campaign.php?campaignNo=${campaignNo}`).pipe()
    .subscribe(
      (data:any) =>{

        this.campaignService.getCampaignsFromRemote().subscribe(campaigns => {

        }); 
        
        this.alertService.success("Campaign successfully deleted !");
        localStorage.removeItem('selectedCampaign');
        localStorage.removeItem('createdPackage');
        this.router.navigate(['/home/sales']);
        
      },error => {
        this.alertService.error("Something went wrong, Try again");
      }
    )
  }

  setEditedValues(editedSpot) {
    this.selection.selected.forEach(spot => {
      spot.breakScheduledDate = this.checkIfValue(editedSpot.breakScheduledDate) || spot.breakScheduledDate;
      spot.duration = this.checkIfValue(editedSpot.duration) || spot.duration;
      spot.programName = this.checkIfValue(editedSpot.programName) || spot.programName;
      spot.programId = this.checkIfValue(editedSpot.programId) || spot.programId;
      spot.channelName = this.checkIfValue(editedSpot.channelName) || spot.channelName;
      spot.channelId = this.checkIfValue(editedSpot.channelId) || spot.channelId;
      spot.startTime = this.checkIfValue(editedSpot.startTime) || spot.startTime;
      spot.endTime = this.checkIfValue(editedSpot.endTime) || spot.endTime;
    });

    this.selection.clear();
  }

  checkIfValue(value) {
    if (value === '' || value === 0) {
      return null;
    } else {
      return value;
    }
  }


  setSelectedSpots(spots) {
    this.selection = spots;
  }

  printInvoice()
  {
    localStorage.setItem('campaignNo', JSON.stringify(this.campaign.campaignId));
    this.router.navigate(['/home/invoice-pdf']);
  }
  

  returnToDashboard() {
    localStorage.removeItem('selectedCampaign');
    localStorage.removeItem('createdPackage');
    this.router.navigate(['/home']);
  }

  back(){
    this._location.back();
  }

}
