import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingController, NavController } from '@ionic/angular';
import { PrimeNGConfig, SelectItem } from 'primeng/api';
import { SareClass } from 'src/app/classes/sare';
import { AlertService } from 'src/app/services/alert/alert.service';
import {Location} from '@angular/common';
import { MaterialMedia } from 'src/app/classes/material.media';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-programme-assets',
  templateUrl: './programme-assets.component.html',
  styleUrls: ['./programme-assets.component.scss'],
})
export class ProgrammeAssetsComponent implements OnInit {

  employee_no: number = 0; 
  formData = new FormData();
  selectedFile: FileList  = null;
  selected_channels: number[] = []; 
  searchFormGroup: FormGroup;
  channels: SareClass[] = [];
  prog_no: number = 0;
   
  display_array: MaterialMedia[] = [];
  sortOptions: SelectItem[];
  sortOrder: number;
  sortField: string;

  //FTP
  ftpHost = 'ftp.mmsafrica.co.za';
  ftpUsername = 'mojaftp@mmsafrica.co.za';
  ftpPassword = 'pfnmms#2022';
  material_folder = '/Ftp-In/';
  
  constructor(private primengConfig: PrimeNGConfig,private alertService: AlertService, route: ActivatedRoute,
    private router: Router,
    private formBuilder: FormBuilder, private httpClient: HttpClient,
    private loadingCtrl: LoadingController, private navCtrl: NavController, public dialog: MatDialog,
    private _location: Location) {

      this.prog_no = parseInt(JSON.parse(localStorage.getItem('prog_no')));
      this.employee_no = parseInt(JSON.parse(localStorage.getItem('employee_no')));
      this.onChannels();
  }

  ngOnInit() {

    this.searchFormGroup = this.formBuilder.group({
        channelsCtrl: [this.selected_channels],
    });

    this.sortOptions = [
      {label: 'Newest First', value: '!prog_no'},
      {label: 'Oldest First', value: 'prog_no'},
      {label: 'Programme Name', value: 'prog_name'}
  ];

    this.primengConfig.ripple = true;
  }

  ionViewWillEnter(){

    this.prog_no = parseInt(JSON.parse(localStorage.getItem('prog_no')));
    this.employee_no = parseInt(JSON.parse(localStorage.getItem('employee_no')));
    this.onChannels();
  }

  onSortChange(event) {
    let value = event.value;

      if (value.indexOf('!') === 0) {
          this.sortOrder = -1;
          this.sortField = value.substring(1, value.length);
      }
      else {
          this.sortOrder = 1;
          this.sortField = value;
      }
  }

  async onChannels() {

    const response = this.httpClient.post(`https://mojalove.mmsafrica.co.za/api/schedule/get_channels.php`,
        {
            channes: this.selected_channels,
            date: '',
            employee_no: this.employee_no,
    
        }, {responseType: 'json'}).pipe()
        .subscribe(
            (data: any) => {
    
                for (let i = 0; i < data.length; i++) {
                    this.channels[i] = {
                        bdcr_no: data[i].bdcr_no,
                        genre: data[i].genre,
                        platform: data[i].platform,
                        sare_name: data[i].sare_name,
                        sare_no: data[i].sare_no
                    };
                }
                this.selected_channels.push(this.channels[0].sare_no);
                this.onReadMaterialFiles();
            }
        );
    }

  async onReadMaterialFiles() {

    const response = this.httpClient.post(`https://mojalove.mmsafrica.co.za/api/schedule/material/read_materials_files.php`,
        {
            channel: this.selected_channels,
            employee_no: this.employee_no,
            prog_no: this.prog_no,
            ftpHost: this.ftpHost,
            ftpUsername: this.ftpUsername,
            ftpPassword: this.ftpPassword,
            dir: this.material_folder,
            name: 'Material'

    
        }, {responseType: 'json'}).pipe()
        .subscribe(
            (data: any) => {
    
                this.display_array = [];
     
                for (let i = 0; i < data.length; i++) {
                    this.display_array[i] = {
                        channel: data[i].channel,date_added: data[i].date_added,duration: data[i].duration,employee_no: data[i].employee_no,file_name: data[i].file_name,idNo: data[i].idNo,image: data[i].image,material_id: data[i].material_id,prog_no: data[i].prog_no,publication_date: data[i].publication_date,size: data[i].size,status: data[i].status,subtype: data[i].subtype,type: data[i].type,file_format: data[i].file_format,selected: false,collection: data[i].collection,copyright: data[i].copyright,description: data[i].description,tags: data[i].tags,subtypeArray: null,base64: data[i].base64,episode_no: data[i].episode_no,season_no: data[i].season_no,segment_no: data[i].segment_no};
                }
    
            }
        );
    }

    getFile(){
        document.getElementById("uploadMedia").click();
    }

    onFileSelected(event){
    
      this.selectedFile  = null;
      this.selectedFile = <FileList>event.target.files;
      this.uploadMediaFile();
    }

    async uploadMediaFile(){
    
      if(this.selectedFile != null)
      {
        for (var x = 0; x < this.selectedFile.length; x++) {
          this.formData.append('fileToUpload[]',this.selectedFile[x],this.selectedFile[x].name);
        }

        this.formData.append('employee_no',<any>this.employee_no);
        this.formData.append('selected_channel',<any>this.selected_channels);
        this.formData.append('prog_no',<any>this.prog_no);
        this.formData.append('ftpHost',<any>this.ftpHost);
        this.formData.append('ftpUsername',<any>this.ftpUsername);
        this.formData.append('ftpPassword',<any>this.ftpPassword);
        this.formData.append('name',<any>'Material');
        this.formData.append('dir',<any>this.material_folder);

    
        this.httpClient.post('https://mojalove.mmsafrica.co.za/api/schedule/material/upload_media_file.php',this.formData,{ responseType: 'json'})
        .subscribe((res_data:any) => {
          
          if(res_data.message == 'success'){
            this.alertService.success('Media file successfully uploaded !');
            this.onReadMaterialFiles();

            localStorage.setItem('madia_ids', JSON.stringify(res_data.madia_ids));
            this.router.navigate(['/home/view-asset']); 
          }
          else{
            this.alertService.error(res_data.message);
          }
    
          this.selectedFile = null;
          this.formData = new FormData();
        });
      }
      else{
        this.alertService.error("Select media file to proceed !");
      }
    }

    openDeleteCampaignDialog(): void {
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '400px',
        data: 'Are you sure you want to delete selected files ?'
      });
  
      dialogRef.afterClosed().subscribe(result => {
        if (result) {
  
          this.deleteSelection();
        }
      });
    }

    async deleteSelection(){ 

      let error = 'Select at least one file to delete';
  
      for (let i = 0; i < this.display_array.length; i++) {

        if(this.display_array[i].selected == true){
          error = '';
        } 
      }
  
      if(error == ''){
        
      const response = this.httpClient.post(`https://mojalove.mmsafrica.co.za/api/schedule/material/delete_asset_files.php`,
      {
        userId: this.employee_no,
        assets: this.display_array,
        ftpHost: this.ftpHost,
        ftpUsername: this.ftpUsername,
        ftpPassword: this.ftpPassword,
        dir: this.material_folder,
        name: 'Material'
  
      },{ responseType: 'json'}).pipe()
      .subscribe(
        (data:any) => {
           
          if(data.message == 'success')
          {
            this.alertService.success("Files successfully deleted");
            this.onReadMaterialFiles();
          }
          else{
  
            this.alertService.error(data.message);
          }
  
        },error => {
          this.alertService.error("Something went wrong, Try again");
        } 
      ) 
    }
    else{
      this.alertService.error("Something went wrong, Try again");
    }
    }

    editSelection(){
      let selectedIds = '';

      for (let i = 0; i < this.display_array.length; i++) {

        if(this.display_array[i].selected == true){
          selectedIds = selectedIds+this.display_array[i].idNo+';';
        } 
      }

      if(selectedIds != ''){
        localStorage.setItem('madia_ids', JSON.stringify(selectedIds));
        this.router.navigate(['/home/view-asset']); 
      }
      else{
        this.alertService.error("Select at least one file to edit");
      }
      
    }

    viewAsset(idNo){

      localStorage.setItem('madia_ids', JSON.stringify(idNo));
      this.router.navigate(['/home/view-asset']); 
    }

    selectAsset(prog_no) {

    localStorage.setItem('prog_no', JSON.stringify(prog_no));
    this.router.navigate(['/home/view-asset']);
    }
}
