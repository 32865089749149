import { Component, OnInit, ViewChild} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import { MatDialog} from '@angular/material/dialog';
import {Chart} from 'chart.js';
import {ConfirmationDialogComponent} from '../confirmation-dialog/confirmation-dialog.component';
import { Location } from '@angular/common';
import { saveAs } from 'file-saver';

//Import used to make HTTP get and post requests
import { HttpClient } from '@angular/common/http';
import { LoadingController } from '@ionic/angular';
import { finalize } from 'rxjs/operators';
 
//To use autcomplete field
import {Observable} from 'rxjs'; 
import {map, startWith} from 'rxjs/operators';
import { NavController } from '@ionic/angular';

//Toasta
import { Router, ActivatedRoute } from '@angular/router';
import { FTPFiles } from 'src/app/classes/ftp.files';
import { AlertService } from 'src/app/services/alert/alert.service';

@Component({
  selector: 'app-file-transfer-protocol',
  templateUrl: './file-transfer-protocol.component.html',
  styleUrls: ['./file-transfer-protocol.component.scss'],
})
export class FileTransferProtocolComponent implements OnInit {

  database_name: string = '';
  del_Sidebar: boolean = false;
  selected_section: number = 0;
  employee_no: number = 0;

  projNo: String = '';
  taskNo: number = 0;
  phaseNo: number = 0;
  new_folder_name: String;

  selected_folder: String = 'file-manager';

  //Import
  selectedFile: FileList  = null;
  formData = new FormData();

  current_dir: String = '/';
  list_files: FTPFiles[] = [];
  list_folders: FTPFiles[] = [];
  menu_folders: any[] = [];

  allChecked: boolean = false;
  allChecked_file: boolean = false;

  //FTP
  ftpHost = 'ftp.mmsafrica.co.za';
  ftpUsername = 'mojaftp@mmsafrica.co.za';
  ftpPassword = 'pfnmms#2022';
  //FTP Folders
  Asrun_folder = 'Ftp-In/As-run/';
  spots_folder = 'Ftp-In/Spots/';

  constructor(private alertService: AlertService,private route:ActivatedRoute,private router: Router,private formBuilder: FormBuilder,private httpClient: HttpClient,private loadingCtrl: LoadingController,private navCtrl: NavController,public dialog: MatDialog,public _location: Location)
  {
    this.database_name = JSON.parse(localStorage.getItem('database_name'));
    this.employee_no = parseInt(JSON.parse(localStorage.getItem('employee_no')));

    this.refresh();
  }

  refresh()
  {
    this.onReadDocuments(this.current_dir);
  }

  ngOnInit() {
    
  }

  //Expandable side Div 
  toggle(list,icon) {
    var element = document.getElementById(list);
    element.classList.toggle("open");
    
    var x = document.querySelector(icon);

    if (x.innerHTML === "expand_more") {
      x.innerHTML = "expand_less";
    } else {
      x.innerHTML = "expand_more";
    }  
  }

  setAll(checked: boolean) {
    this.allChecked = checked;

    if (this.list_folders == null) {
      return;
    } 
    this.list_folders.forEach(t => t.check = checked);
  }

  someChecked(): boolean {
    if (this.list_folders == null) {
      return false;
    }
    return this.list_folders.filter(t => t.check).length > 0 && !this.allChecked;
  }

  updateAllchecked() {
    this.allChecked = this.list_folders != null && this.list_folders.every(t => t.check);
  }


  setAll_file(checked: boolean) {
    this.allChecked_file = checked;

    if (this.list_files == null) {
      return;
    }
    this.list_files.forEach(t => t.check = checked);
  }

  someChecked_file(): boolean {
    if (this.list_files == null) {
      return false;
    }
    return this.list_files.filter(t => t.check).length > 0 && !this.allChecked_file;
  }

  updateAllchecked_file() {
    this.allChecked_file = this.list_files != null && this.list_files.every(t => t.check);
  }

  uploadfile()
  {
    document.getElementById("file-receipt").click();
  }


  openDeletDialog(): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '500px',
      data: 'Are you sure you want to delete selected files ?'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) 
      {
        this.deleteDocs();
      }
    });
  }


  //Delete docs
  async deleteDocs()
  {

    const response = this.httpClient.post(`https://mojalove.mmsafrica.co.za/api/filemanager/delete_doc.php`,
    {
      files: this.list_files,
      folders: this.list_folders,
      dir: this.current_dir,
      ftpHost: this.ftpHost,
      ftpUsername: this.ftpUsername,
      ftpPassword: this.ftpPassword

    },{ responseType: 'json'}).pipe( )
    .subscribe(
      (data:any) => {
        
        if(data.message == 'success')
        {
          this.alertService.success('Document(s) successfully deleted');
          this.onReadDocuments(this.current_dir);
        }
        else
        {
          this.alertService.error(data.message);
        }

      },error => {
        this.alertService.error("Something went wrong, Try again");
      }
    ) 
  }

  //Reading Sub-Tasks
  async onReadDocuments(dir)
  {

    const response = this.httpClient.post(`https://mojalove.mmsafrica.co.za/api/filemanager/file_manager.php`,
    {
      employee_no: this.employee_no,
      dir: dir,
      ftpHost: this.ftpHost,
      ftpUsername: this.ftpUsername,
      ftpPassword: this.ftpPassword

    },{ responseType: 'json'}).pipe()
    .subscribe(
      (data:any) => {
        
        this.list_files = [];
        this.list_folders = [];

        for(var i=0;i<data[0].list_files.length;i++)
        {
          this.list_files[i] = {check: false,doc_name: data[0].list_files[i],path: '',idNo: i};
        } 


        for(var i=0;i<data[0].list_folders.length;i++)
        {
          this.list_folders[i] = {check: false,doc_name: data[0].list_folders[i],path: '',idNo: i};         
        }

      },error => {
        this.alertService.error("Something went wrong, Try again");
      }
    ) 
  }


  //Reading Sub-Tasks
  async createFolder()
  {

    const response = this.httpClient.post(`https://mojalove.mmsafrica.co.za/api/filemanager/create_folder.php`,
    {
      employee_no: this.employee_no,
      name: this.new_folder_name,
      dir: this.current_dir,
      ftpHost: this.ftpHost,
      ftpUsername: this.ftpUsername,
      ftpPassword: this.ftpPassword

    },{ responseType: 'json'}).pipe( )
    .subscribe(
      (data:any) => {
        
        if(data.message == 'success')
        {
          this.alertService.success('Folder successfully created !');
          this.onReadDocuments(this.current_dir);
          this.new_folder_name = "";
        }
        else
        { 
          this.alertService.error(data.message);
        }

      },error => {
        this.alertService.error("Something went wrong, Try again");
      }
    ) 
  }

  async onFileSelected(event)
  {

    this.selectedFile = <FileList>event.target.files;


    if(this.selectedFile != null)
    {
      for (var x = 0; x < this.selectedFile.length; x++) {
        this.formData.append('fileToUpload[]',this.selectedFile[x],this.selectedFile[x].name);
      }
    }

    this.formData.append('dir',<any>this.current_dir);
    this.formData.append('ftpHost',<any>this.ftpHost);
    this.formData.append('ftpUsername',<any>this.ftpUsername);
    this.formData.append('ftpPassword',<any>this.ftpPassword);

    this.httpClient.post('https://mojalove.mmsafrica.co.za/api/filemanager/upload_file.php',this.formData,{ responseType: 'json'})
    .subscribe((res_data:any) => {  
      
      if(res_data.message == 'success')
      {
        this.selectedFile = null;
        this.formData = new FormData();
        this.alertService.success('File successfully uploaded !');
        this.onReadDocuments(this.current_dir);
      }
      else
      {
        this.selectedFile = null;
        this.formData = new FormData();
        this.alertService.error(res_data.message);
      }

    },error => {
        this.alertService.error("Something went wrong, Try again");
    });
  }


  async openDocument(file_name)
  {

    const response = this.httpClient.post(`https://mojalove.mmsafrica.co.za/api/filemanager/get_file_from_server.php`,
    {
      employee_no: this.employee_no,
      file_name: file_name,
      ftpHost: this.ftpHost,
      ftpUsername: this.ftpUsername,
      ftpPassword: this.ftpPassword

    },{ responseType: 'json'}).pipe( )
    .subscribe(
      (data:any) => {
        
        if(data.message == 'success')
        {
          
          var files = data.download_files.split(";");

          for(var i=0;i<(files.length-1);i++)
          {
            var fileName = files[i].split("/")[1];
            this.export(files[i]).subscribe(data => saveAs(data, fileName)); 
          }
        }
        else
        {
          this.alertService.error(data.message);
        }

      },error => {
        this.alertService.error("Something went wrong, Try again");
      }
    ) 
  }

  export(name) {
    return this.httpClient.get(`https://mojalove.mmsafrica.co.za/api/filemanager/download_file.php?file_name=${name}`, 
      {responseType: 'blob'});
  }

  openFolder(folder_name)
  {
    this.current_dir = folder_name+'/';
    this.onReadDocuments(this.current_dir);
  }

  previousFolder()
  {
    var splitted = this.current_dir.split("/"); 
    var len = splitted.length;

    if(len > 2)
    {
      this.current_dir = "";
      for(var i=0;i<len-2;i++){
        this.current_dir = this.current_dir+splitted[i]+'/';
        this.selected_folder = splitted[i];
      }

      this.onReadDocuments(this.current_dir);
    }
    
  }
}
