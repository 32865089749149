import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertService } from 'src/app/services/alert/alert.service';
import {Location} from '@angular/common';
import { WebArticleClass } from 'src/app/classes/web.article';
import { ArticleAuthor } from 'src/app/classes/article.author';
import * as $ from "jquery";

import * as _moment from 'moment';
import {default as _rollupMoment, Moment} from 'moment';
import { FTPFiles } from 'src/app/classes/ftp.files';
import { DomSanitizer } from '@angular/platform-browser';
import { MessageService } from 'primeng/api';
import { WebAdvertClass } from 'src/app/classes/web.advert';
import { ClientClass } from 'src/app/classes/client';
import { ProductClass } from 'src/app/classes/product';
import { SareClass } from 'src/app/classes/sare';
const moment = _rollupMoment || _moment;

@Component({
  selector: 'app-edit-advert',
  templateUrl: './edit-advert.component.html',
  styleUrls: ['./edit-advert.component.scss'],
  providers: [MessageService]
})
export class EditAdvertComponent implements OnInit {

  formData = new FormData();
  imageSrc: string | ArrayBuffer;
  author_list: ArticleAuthor[] = [];
  uploadedFiles: any[] = [];
  statusOptions: any[] = [{label: 'Draft', value: 'Draft'}, {label: 'Publish Now', value: 'Publish Now'}];

  employee_no: number = 0;
  advertId: number =0;
  advertFormGroup: FormGroup;
  selectedFile_large: FileList  = null;
  selectedFile_medium: FileList  = null;
  selectedFile_mobile: FileList  = null;
  advert: WebAdvertClass  = new WebAdvertClass();

  advertisers_array: ClientClass[] = [];
  products_array: ProductClass[] = [];
  agency_array: ClientClass[] = [];
  channels: SareClass[] = [];
  selected_channels: number[] = [];
  selected_channelNames: String[] = [];
  channelPercentages: number[] = []

  constructor(private alertService: AlertService,route:ActivatedRoute,private router: Router,private formBuilder: FormBuilder,private httpClient: HttpClient,public dialog: MatDialog,private _location: Location,private sanitizer: DomSanitizer) {

    this.advertId = parseInt(JSON.parse(localStorage.getItem('advertId')));
    this.onReadAuthors();
    this.onReadProducts();
    this.onReadAgencies();
    this.onReadAdvertisers();
    this.onChannels();
   }

  ngOnInit() {

    this.advert.Status = {label: 'Draft', value: 'Draft'};
    this.advert.Published_On = moment().format("YYYY-MM-DD");
    this.advert.Starting_Time = moment().format("HH:mm");
    this.advert.End_date = moment().format("YYYY-MM-DD");
    this.advert.End_Time = "23:59";
    this.advert.Slug = "mms";

    //Planning
    this.advertFormGroup = this.formBuilder.group({
      NameCtrl: [this.advert.Name],
      Published_OnCtrl: [this.advert.Published_On],
      Large_ImageCtrl: [this.advert.Large_Image],
      Medium_ImageCtrl: [this.advert.Medium_Image],
      Mobile_ImageCtrl: [this.advert.Mobile_Image], 
      LinkCtrl: [this.advert.Link],
      Starting_TimeCtrl: [this.advert.Starting_Time],
      End_dateCtrl: [this.advert.End_date],
      End_TimeCtrl: [this.advert.End_Time],
      StatusCtrl: [this.advert.Status],
      agency_codeCtrl: [this.advert.agency_code],
      owner_codeCtrl: [this.advert.owner_code], 
      prod_codeCtrl: [this.advert.prod_code],
      selected_channelsCtrl: [this.selected_channels],
      percentagesCtrl: [this.channelPercentages],
      //Mobile
      home_imoja_LogoCtrl: [this.advert.home_imoja_Logo],
      home_Feed_section_1Ctrl: [this.advert.home_Feed_section_1],
      home_Feed_section_2Ctrl: [this.advert.home_Feed_section_2],
      home_under_More_FeedCtrl: [this.advert.home_under_More_Feed],
      home_Celeb_Buzz_sectionCtrl: [this.advert.home_Celeb_Buzz_section],
      home_LIFE_99_sectionCtrl: [this.advert.home_LIFE_99_section],
      home_NEWS_sectionCtrl: [this.advert.home_NEWS_section],
      home_MULTIMEDIA_sectionCtrl: [this.advert.home_MULTIMEDIA_section],
      home_Sponsored_Content_sectionCtrl: [this.advert.home_Sponsored_Content_section],
      bottom_of_home_pageCtrl: [this.advert.bottom_of_home_page],
      article_Zimoja_LogoCtrl: [this.advert.article_Zimoja_Logo],
      article_paragraph_1Ctrl: [this.advert.article_paragraph_1],
      article_paragraph_2Ctrl: [this.advert.article_paragraph_2],
      article_paragraph_3Ctrl: [this.advert.article_paragraph_3],
      article_Popular_StoriesCtrl: [this.advert.article_Popular_Stories],
      article_More_like_thisCtrl: [this.advert.article_More_like_this],
      //Desktop
      desk_home_imoja_LogoCtrl: [this.advert.desk_home_imoja_Logo],
      desk_home_under_More_FeedCtrl: [this.advert.desk_home_under_More_Feed],
      desk_home_Celeb_Buzz_sectionCtrl: [this.advert.desk_home_Celeb_Buzz_section],
      desk_home_LIFE_99_sectionCtrl: [this.advert.desk_home_LIFE_99_section],
      desk_home_NEWS_sectionCtrl: [this.advert.desk_home_NEWS_section],
      desk_home_MULTIMEDIA_sectionCtrl: [this.advert.desk_home_MULTIMEDIA_section],
      desk_home_Sponsored_Content_sectionCtrl: [this.advert.desk_home_Sponsored_Content_section],
      desk_bottom_of_home_pageCtrl: [this.advert.desk_bottom_of_home_page],
      desk_article_Zimoja_LogoCtrl: [this.advert.desk_article_Zimoja_Logo],
      desk_article_paragraph_1Ctrl: [this.advert.desk_article_paragraph_1],
      desk_article_paragraph_2Ctrl: [this.advert.desk_article_paragraph_2],
      desk_article_paragraph_3Ctrl: [this.advert.desk_article_paragraph_3],
      desk_article_Popular_StoriesCtrl: [this.advert.desk_article_Popular_Stories],
      desk_article_More_like_thisCtrl: [this.advert.desk_article_More_like_this]
    });
  }

  ngAfterViewInit(){
    
    //File import
    $("form").on("change", ".file-upload-field18", function(){ 
      $(this).parent(".file-upload-wrapper").attr("data-text",  $(this).val().replace(/.*(\/|\\)/, '') );
    });

    $("form").on("change", ".file-upload-field19", function(){ 
      $(this).parent(".file-upload-wrapper").attr("data-text",  $(this).val().replace(/.*(\/|\\)/, '') );
    });

  }

  async onChannels() {

    const response = this.httpClient.post(`https://mojalove.mmsafrica.co.za/api/schedule/get_channels.php`,
        {
            channes: this.selected_channels,
            date: moment().format('YYYY/MM/DD'),
            employee_no: this.employee_no,
    
        }, {responseType: 'json'}).pipe()
        .subscribe(
            (data: any) => {
    
                for (let i = 0; i < data.length; i++) {
                    this.channels[i] = {
                        bdcr_no: data[i].bdcr_no,
                        genre: data[i].genre,
                        platform: data[i].platform,
                        sare_name: data[i].sare_name,
                        sare_no: data[i].sare_no
                    };
                }

                this.onReadAdvert();
            }
        );
    }

  async onReadAgencies()
  {
    const response = this.httpClient.post(`https://mojalove.mmsafrica.co.za/api/sales/agency/filter_clients.php`,
    {
        employee_no: 0,

    },{ responseType: 'json'}).pipe()
    .subscribe(
      (data:any) => {
        
        this.agency_array = [];

        for(var i=0;i<data.length;i++)
        { 
            var strLate_fee_value = 0;
            this.agency_array[i] = {type: data[i].type,idNo: data[i].idNo,employee_no: data[i].employee_no,company: data[i].company,first_name: data[i].first_name,last_name: data[i].last_name,email: data[i].email,phone: data[i].phone,address: data[i].address,city: data[i].city,state: data[i].state,zip_code: data[i].zip_code,vat_number: data[i].vat_number,tax_number: data[i].tax_number, currency: data[i].currency,rem_days: data[i].rem_days,rem_position: data[i].rem_position,late_fee_percentage: data[i].late_fee_percentage,late_fee_option: data[i].late_fee_option,late_fee_value: strLate_fee_value,late_fee_days: data[i].late_fee_days,late_fee_tax_rate: data[i].late_fee_tax_rate,late_fee_tax_name: data[i].late_fee_tax_name,late_fee_tax_number: data[i].late_fee_tax_number,company_logo: data[i].company_logo,date_time: data[i].date_time,checked: false,ship_address: data[i].ship_address,ship_city: data[i].ship_city,ship_state: data[i].ship_state,ship_zip_code: data[i].ship_zip_code,agencyId: data[i].agencyId,agency: ''};
        }
        
      },error => {

        this.alertService.error("Something went wrong, Try again");
      } 
    )
  }

  async onReadProducts()
  {
    const response = this.httpClient.post(`https://mojalove.mmsafrica.co.za/api/sales/product/read_products.php`,
    {
        employee_no: 0,

    },{ responseType: 'json'}).pipe()
    .subscribe(
      (data:any) => {
        
        this.products_array = [];

        for(var i=0;i<data.length;i++) 
        {
          this.products_array[i] = {idNo: data[i].idNo,product_name: data[i].product_name,description: data[i].description,quantity: data[i].quantity,discount: data[i].discount,turnVat: data[i].turnVat,unit_price: data[i].unit_price,vat_rate: data[i].vat_rate,vat_type: data[i].vat_type,date_time: data[i].date_time,image: data[i].image,position: data[i].position,category: data[i].category,company: data[i].company,clientNo: data[i].clientNo};
        }

      } ,error => {
        this.alertService.error("Something went wrong, Try again");
      }
    )
  }

  async onReadAdvertisers()
  {
    const response = this.httpClient.post(`https://mojalove.mmsafrica.co.za/api/sales/client/filter_clients.php`,
    {
        employee_no: 0,

    },{ responseType: 'json'}).pipe()
    .subscribe(
      (data:any) => {
        
        this.advertisers_array = [];

        for(var i=0;i<data.length;i++)
        { 
            var strLate_fee_value = 0;
            this.advertisers_array[i] = {type: data[i].type,idNo: data[i].idNo,employee_no: data[i].employee_no,company: data[i].company,first_name: data[i].first_name,last_name: data[i].last_name,email: data[i].email,phone: data[i].phone,address: data[i].address,city: data[i].city,state: data[i].state,zip_code: data[i].zip_code,vat_number: data[i].vat_number,tax_number: data[i].tax_number, currency: data[i].currency,rem_days: data[i].rem_days,rem_position: data[i].rem_position,late_fee_percentage: data[i].late_fee_percentage,late_fee_option: data[i].late_fee_option,late_fee_value: strLate_fee_value,late_fee_days: data[i].late_fee_days,late_fee_tax_rate: data[i].late_fee_tax_rate,late_fee_tax_name: data[i].late_fee_tax_name,late_fee_tax_number: data[i].late_fee_tax_number,company_logo: data[i].company_logo,date_time: data[i].date_time,checked: false,ship_address: data[i].ship_address,ship_city: data[i].ship_city,ship_state: data[i].ship_state,ship_zip_code: data[i].ship_zip_code,agencyId: data[i].agencyId,agency: ''};
        }
        
      },error => {

        this.alertService.error("Something went wrong, Try again");
      } 
    )
  }

  headlineKeyUp(){ 
   
    const specialCharsRegex = /[^a-zA-Z0-9]/g;
    //this.advert.headline = this.advert.headline.trim();
    this.advert.Slug = (this.advert.Name.replace(' ','-').replace('"','').replace("'","")).replace(specialCharsRegex, '-');
  }

  onFileSelectedLarge(event){
    
    let image:any = event.target.files[0];
    this.selectedFile_large  = null;

    let size = image.size; //bytes
    let width = 0;
    let height = 0;

    let fr = new FileReader;
    fr.onload = () => {
     var img = new Image();
 
     img.onload = () => {
         width = img.width;
         height = img.height;

         if(width == 1600 && height == 413){
          this.selectedFile_large = <FileList>event.target.files;
    
          if (event.target.files && event.target.files[0]) {
              const file = event.target.files[0];
    
              const reader = new FileReader();
              reader.onload = e => this.advert.Large_Image = reader.result;
              reader.readAsDataURL(file);
          }
    
          document.getElementById("largeImage").style.border = "rgba(1, 3, 39,0.2)";
        }
        else{
          
          document.getElementById("largeImage").style.border = "1px solid red";
          this.alertService.error("Image dimensions must be: 1600 × 413");
        }
     };

     img.src = <string>fr.result;
   };

   fr.readAsDataURL(image);
  }


  onFileSelectedMedium(event){
    
    let image:any = event.target.files[0];
    this.selectedFile_medium  = null;

    let size = image.size; //bytes
    let width = 0;
    let height = 0;

    let fr = new FileReader;
    fr.onload = () => {
     var img = new Image();
 
     img.onload = () => {
         width = img.width;
         height = img.height;

         if(width == 1200 && height == 150){
          this.selectedFile_medium = <FileList>event.target.files;
    
          if (event.target.files && event.target.files[0]) {
              const file = event.target.files[0];
    
              const reader = new FileReader();
              reader.onload = e => this.advert.Medium_Image = reader.result;
              reader.readAsDataURL(file);
          }
    
          document.getElementById("mediumImage").style.border = "rgba(1, 3, 39,0.2)";
        }
        else{
          
          document.getElementById("mediumImage").style.border = "1px solid red";
          this.alertService.error("Image dimensions must be: 1200 × 150");
        }
     };

     img.src = <string>fr.result;
   };

   fr.readAsDataURL(image);
  }


  onFileSelectedMobile(event){
    
    let image:any = event.target.files[0];
    this.selectedFile_mobile  = null;

    let size = image.size; //bytes
    let width = 0;
    let height = 0;

    let fr = new FileReader;
    fr.onload = () => {
     var img = new Image();
 
     img.onload = () => {
         width = img.width;
         height = img.height;

         if(width == 450 && height == 273){
          this.selectedFile_mobile = <FileList>event.target.files;
    
          if (event.target.files && event.target.files[0]) {
              const file = event.target.files[0];
    
              const reader = new FileReader();
              reader.onload = e => this.advert.Mobile_Image = reader.result;
              reader.readAsDataURL(file);
          }
    
          document.getElementById("mobileImage").style.border = "rgba(1, 3, 39,0.2)";
        }
        else{
          
          document.getElementById("mobileImage").style.border = "1px solid red";
          this.alertService.error("Image dimensions must be: 450 × 273");
        }
     };
    
     img.src = <string>fr.result;
   };

   fr.readAsDataURL(image);
  }

  channelClick(){

    this.selected_channelNames = [];
    this.channelPercentages = [];

    let index = 0;
    let totFields = 0;
    for(var i=0;i<this.channels.length;i++){

      for(var z=0;z<this.selected_channels.length;z++){

        if(this.selected_channels[z] == this.channels[i].sare_no){
          this.selected_channelNames[index] = this.channels[i].sare_name;
          this.channelPercentages[index] = 0;
          index = index + 1;
          totFields = totFields + 50;
        }
      }
      
    }

    let height = 250 + totFields;
    document.getElementById("campDiv").style.minHeight = height+"px";
  }

  async onReadAdvert(){
 
    var start_date = moment().format("YYYY/MM/DD");
    var end_date = moment().format("YYYY/MM/DD");
    
    const response = this.httpClient.post(`https://mojalove.mmsafrica.co.za/api/web-admin/read_one_adverts.php`,
    {
      advertId: this.advertId,
      start_date: start_date,
      end_date: end_date,

    },{ responseType: 'json'}).pipe()
    .subscribe( 
      (data:any) => {

      this.advert.Name = data[0].Name;
      this.advert.Slug = data[0].Slug;
      this.advert.Published_On = data[0].Published_On;
      this.advert.Large_Image = data[0].Large_Image;
      this.advert.Medium_Image = data[0].Medium_Image;
      this.advert.Mobile_Image = data[0].Mobile_Image;
      this.advert.Link = data[0].Link;
      this.advert.Starting_Time = data[0].Starting_Time;
      this.advert.End_date = data[0].End_date;
      this.advert.End_Time = data[0].End_Time;
      this.advert.Status = data[0].Status;
      this.advert.home_imoja_Logo = data[0].home_imoja_Logo;
      this.advert.home_Feed_section_1 = data[0].home_Feed_section_1;
      this.advert.home_Feed_section_2 = data[0].home_Feed_section_2;
      this.advert.home_under_More_Feed = data[0].home_under_More_Feed;
      this.advert.home_Celeb_Buzz_section = data[0].home_Celeb_Buzz_section;
      this.advert.home_LIFE_99_section = data[0].home_LIFE_99_section;
      this.advert.home_NEWS_section = data[0].home_NEWS_section;
      this.advert.home_MULTIMEDIA_section = data[0].home_MULTIMEDIA_section;
      this.advert.home_Sponsored_Content_section = data[0].home_Sponsored_Content_section;
      this.advert.bottom_of_home_page = data[0].bottom_of_home_page;
      this.advert.article_Zimoja_Logo = data[0].article_Zimoja_Logo;
      this.advert.article_paragraph_1 = data[0].article_paragraph_1;
      this.advert.article_paragraph_2 = data[0].article_paragraph_2;
      this.advert.article_paragraph_3 = data[0].article_paragraph_3;
      this.advert.article_Popular_Stories = data[0].article_Popular_Stories;
      this.advert.article_More_like_this = data[0].article_More_like_this;
      this.advert.desk_home_imoja_Logo = data[0].desk_home_imoja_Logo;
      this.advert.desk_home_under_More_Feed = data[0].desk_home_under_More_Feed;
      this.advert.desk_home_Celeb_Buzz_section = data[0].desk_home_Celeb_Buzz_section;
      this.advert.desk_home_LIFE_99_section = data[0].desk_home_LIFE_99_section;
      this.advert.desk_home_NEWS_section = data[0].desk_home_NEWS_section;
      this.advert.desk_home_MULTIMEDIA_section = data[0].desk_home_MULTIMEDIA_section;
      this.advert.desk_home_Sponsored_Content_section = data[0].desk_home_Sponsored_Content_section;
      this.advert.desk_bottom_of_home_page = data[0].desk_bottom_of_home_page;
      this.advert.desk_article_Zimoja_Logo = data[0].desk_article_Zimoja_Logo;
      this.advert.desk_article_paragraph_1 = data[0].desk_article_paragraph_1;
      this.advert.desk_article_paragraph_2 = data[0].desk_article_paragraph_2;
      this.advert.desk_article_paragraph_3 = data[0].desk_article_paragraph_3;
      this.advert.desk_article_Popular_Stories = data[0].desk_article_Popular_Stories;
      this.advert.desk_article_More_like_this = data[0].desk_article_More_like_this;
      this.advert.prod_code = data[0].prod_code;
      this.advert.agency_code = data[0].agency_code;
      this.advert.owner_code = data[0].owner_code;

      this.advert.Status = {label: 'Draft', value: 'Draft'};
      if(data[0].status == "Publish Now"){
        this.advert.Status = {label: 'Publish Now', value: 'Publish Now'};
      }

      this.selected_channelNames = [];
      this.selected_channels = [];
      this.channelPercentages = [];
      
      let index = 0;
      let totFields = 0;
      for(var z=0;z<data[0].channels.length;z++){
        this.selected_channelNames[z] = data[0].channels[z];

        for(var i=0;i<this.channels.length;i++){

          if(this.channels[i].sare_no == Number(this.selected_channelNames[z])){

            this.selected_channels.push(this.channels[i].sare_no);
            this.selected_channelNames[index] = this.channels[i].sare_name;
            index = index + 1;
            totFields = totFields + 50;
          }
        }
      }


      // for(var z=0;z<data[0].channelPercentages.length;z++){
      //   this.channelPercentages[z] = 0;
      // }

      for(var z=0;z<data[0].channelPercentages.length;z++){
        this.channelPercentages.push(data[0].channelPercentages[z]);
      }
 
      console.log(this.selected_channels);
      console.log(this.channelPercentages);

      //let height = 250 + totFields;
      //document.getElementById("campDiv").style.minHeight = height+"px";

      }  
    ) 
  }

  async saveAdvert(){
    
    if(this.selectedFile_large != null)
    {
      for (var x = 0; x < this.selectedFile_large.length; x++) {
        this.formData.append('fileToUpload_large[]',this.selectedFile_large[x],this.selectedFile_large[x].name);
      }
    }

    if(this.selectedFile_medium != null)
    {
      for (var x = 0; x < this.selectedFile_medium.length; x++) {
        this.formData.append('fileToUpload_medium[]',this.selectedFile_medium[x],this.selectedFile_medium[x].name);
      }
    }

    if(this.selectedFile_mobile != null)
    {
      for (var x = 0; x < this.selectedFile_mobile.length; x++) {
        this.formData.append('fileToUpload_mobile[]',this.selectedFile_mobile[x],this.selectedFile_mobile[x].name);
      }
    }

    if(this.advert.Link == null || this.advert.Link == ""){
      this.advert.Link = "#";
    }

    var firstLetter = this.advert.Slug.charAt(0);
    var LastLetter = this.advert.Slug.charAt(this.advert.Slug.length-1);

    if(firstLetter == "-"){this.advert.Slug = '' + this.advert.Slug.slice(1);}
    if(LastLetter == "-"){this.advert.Slug = this.advert.Slug.slice(0, -1) + '_';}

    var startDate = moment(this.advert.Published_On.toString()).format("YYYY-MM-DD");
    var endDate = moment(this.advert.End_date.toString()).format("YYYY-MM-DD");

    this.formData.append('advertId',<any>this.advertId);
    this.formData.append('Name',<any>this.advert.Name);
    this.formData.append('Slug',<any>this.advert.Slug);
    this.formData.append('Published_On',<any>startDate);
    this.formData.append('Large_Image',<any>this.advert.Large_Image);
    this.formData.append('Medium_Image',<any>this.advert.Medium_Image);
    this.formData.append('Mobile_Image',<any>this.advert.Mobile_Image);
    this.formData.append('Link',<any>this.advert.Link);
    this.formData.append('Starting_Time',<any>this.advert.Starting_Time);
    this.formData.append('End_date',<any>endDate);
    this.formData.append('End_Time',<any>this.advert.End_Time);
    this.formData.append('Status',<any>this.advert.Status.value);
    this.formData.append('agency_code',<any>this.advert.agency_code);
    this.formData.append('owner_code',<any>this.advert.owner_code);
    this.formData.append('prod_code',<any>this.advert.prod_code);
    this.formData.append('channels',<any>this.selected_channels);
    this.formData.append('channelPercentages',<any>this.channelPercentages);
    this.formData.append('home_imoja_Logo',<any>this.advert.home_imoja_Logo);
    this.formData.append('home_Feed_section_1',<any>this.advert.home_Feed_section_1);
    this.formData.append('home_Feed_section_2',<any>this.advert.home_Feed_section_2);
    this.formData.append('home_under_More_Feed',<any>this.advert.home_under_More_Feed);
    this.formData.append('home_Celeb_Buzz_section',<any>this.advert.home_Celeb_Buzz_section);
    this.formData.append('home_LIFE_99_section',<any>this.advert.home_LIFE_99_section);
    this.formData.append('home_NEWS_section',<any>this.advert.home_NEWS_section);
    this.formData.append('home_MULTIMEDIA_section',<any>this.advert.home_MULTIMEDIA_section);
    this.formData.append('home_Sponsored_Content_section',<any>this.advert.home_Sponsored_Content_section);
    this.formData.append('bottom_of_home_page',<any>this.advert.bottom_of_home_page);
    this.formData.append('article_Zimoja_Logo',<any>this.advert.article_Zimoja_Logo);
    this.formData.append('article_paragraph_1',<any>this.advert.article_paragraph_1);
    this.formData.append('article_paragraph_2',<any>this.advert.article_paragraph_2);
    this.formData.append('article_paragraph_3',<any>this.advert.article_paragraph_3);
    this.formData.append('article_Popular_Stories',<any>this.advert.article_Popular_Stories);
    this.formData.append('article_More_like_this',<any>this.advert.article_More_like_this);
    this.formData.append('desk_home_imoja_Logo',<any>this.advert.desk_home_imoja_Logo);
    this.formData.append('desk_home_under_More_Feed',<any>this.advert.desk_home_under_More_Feed);
    this.formData.append('desk_home_Celeb_Buzz_section',<any>this.advert.desk_home_Celeb_Buzz_section);
    this.formData.append('desk_home_LIFE_99_section',<any>this.advert.desk_home_LIFE_99_section);
    this.formData.append('desk_home_NEWS_section',<any>this.advert.desk_home_NEWS_section);
    this.formData.append('desk_home_MULTIMEDIA_section',<any>this.advert.desk_home_MULTIMEDIA_section);
    this.formData.append('desk_home_Sponsored_Content_section',<any>this.advert.desk_home_Sponsored_Content_section);
    this.formData.append('desk_bottom_of_home_page',<any>this.advert.desk_bottom_of_home_page);
    this.formData.append('desk_article_Zimoja_Logo',<any>this.advert.desk_article_Zimoja_Logo);
    this.formData.append('desk_article_paragraph_1',<any>this.advert.desk_article_paragraph_1);
    this.formData.append('desk_article_paragraph_2',<any>this.advert.desk_article_paragraph_2);
    this.formData.append('desk_article_paragraph_3',<any>this.advert.desk_article_paragraph_3);
    this.formData.append('desk_article_Popular_Stories',<any>this.advert.desk_article_Popular_Stories);
    this.formData.append('desk_article_More_like_this',<any>this.advert.desk_article_More_like_this);
  

    this.httpClient.post('https://mojalove.mmsafrica.co.za/api/web-admin/edit_advert.php',this.formData,{ responseType: 'json'})
    .subscribe((data:any) => {
      

      if(data.message == 'success')
        {
          this.alertService.success("Advert successfully saved"); 
        }
        else{

          this.alertService.error(data.message);
        } 

    });
  }


  async onReadAuthors() {

    const response = this.httpClient.post(`https://mojalove.mmsafrica.co.za/api/web-admin/zimoja/read_authors.php`,
        {
            employee_no: this.employee_no,
    
        }, {responseType: 'json'}).pipe()
        .subscribe(
            (data: any) => {
    
                this.author_list = [];
    
                for (let i = 0; i < data.length; i++) {
                    this.author_list[i] = {Bio: data[i].Bio,Bio_Summary: data[i].Bio_Summary,Collection_ID: data[i].Collection_ID,Created_On: data[i].Created_On,Email: data[i].Email,Facebook_Profile_Link: data[i].Facebook_Profile_Link,Instagram_Profile_Link: data[i].Instagram_Profile_Link,Item_ID: data[i].Item_ID,Name: data[i].Name,Picture: data[i].Picture,Published_On: data[i].Published_On,Slug: data[i].Slug,Twitter_Profile_Link: data[i].Twitter_Profile_Link,Updated_On: data[i].Updated_On
                    };
                }
    
            }
        ); 
    }

  back(){
    this._location.back();
  }

} 
