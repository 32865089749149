import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators, FormControl} from '@angular/forms';
import {Router, ActivatedRoute} from '@angular/router';
import {first} from 'rxjs/operators';
import {AuthenticationService} from '../../services/authentication/authentication.service';
import {AlertService} from '../../services/alert/alert.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit {

  signInForm: FormGroup; 
  hide = true; // password hiding
  employee_no:number = 0;
  rand: string = '';

  constructor(private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private alertService: AlertService,private httpClient: HttpClient) { 

      var url = window.location.href;
      var search = url.indexOf("?");

      if (search < 0) {

        this.router.navigate(['/signin']);
      }
      else{

        var parsed = url.substr(search+1).split("&");
        this.rand = parsed[0].split("=")[1];
        this.employee_no = parseInt(parsed[1].split("=")[1]);
        this.validateAccount();
      }
    }

  ngOnInit() {

    this.signInForm = this.fb.group({
      'password': [null, [Validators.required]],
      'password2': [null, [Validators.required]]
    });
  }

  get f() {
    return this.signInForm.controls;
  }

  async savePassword()
  {

    if(this.f.password.value != this.f.password2.value){

      this.alertService.error("Entered password must match !");
    }
    else{

    const response = this.httpClient.post('https://mojalove.mmsafrica.co.za/api/admin/change_password.php',
    {
      employee_no: this.employee_no,
      password: this.f.password.value,

    },{ responseType: 'json'}).pipe( )
    .subscribe(
      (data:any) => {
        
        if(data.message == "success")
        {
          this.alertService.success("Password successfully changed");
          this.router.navigate(['/signin']);
        }
        else
        { 
          this.alertService.error(data.message);
        }
        
      },error => {
        this.alertService.error("Something went wrong, Try again");
      }
    ) 
    }
  }

  async validateAccount()
  {

    this.httpClient.get(`https://mojalove.mmsafrica.co.za/api/admin/validate_account.php?employee_no=${this.employee_no}&validate_key=${this.rand}`).pipe( )
    .subscribe(
      (data:any) =>{

          if(data.message == "success"){
             
          }
          else{

            this.alertService.error("Invalid password reset link !");
            this.router.navigate(['/signin']);
          }

      },error => {
        this.alertService.error("Something went wrong, Try again");
      }
    )
  }

}
