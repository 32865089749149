import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { EpisodeDialogClass } from 'src/app/classes/episode.dialog';

//Import used to make HTTP get and post requests
import { HttpClient } from '@angular/common/http';
import {AlertService} from '../../../services/alert/alert.service';

import * as _moment from 'moment';
import {default as _rollupMoment, Moment} from 'moment';
import { SegmentClass } from 'src/app/classes/segment';
import { AddNewEpisode } from 'src/app/classes/addNewEpisodes';
const moment = _rollupMoment || _moment;

@Component({
  selector: 'app-add-participants',
  templateUrl: './add-participants.component.html',
  styleUrls: ['./add-participants.component.scss']
})
export class AddParticipantsComponent implements OnInit {

  editFormGroup: FormGroup; 
  participantsData: any;

  firstName: string = "";
  surname: string = "";
  additionalName: string = "";
  fullName: string = "";
  romanNumbers: string = "";
  title: string = "";
  date: string = "";

  constructor(private formBuilder: FormBuilder,public dialogRef: MatDialogRef<AddParticipantsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private httpClient: HttpClient,
    private alertService: AlertService) { 

      this.participantsData = this.data;
      //participantsData.participants
    }

  ngOnInit() {

    this.editFormGroup = this.formBuilder.group({
      firstNameCtrl: [this.firstName,Validators.required],
      surnameCtrl: [this.surname,Validators.required],
      additionalNameCtrl: [this.additionalName],
      fullNameCtrl: [this.fullName],
      romanNumbersCtrl: [this.romanNumbers],
      titleCtrl: [this.title],
      dateCtrl: [this.date]
    });

  } 

  cancel(): void {
    this.dialogRef.close();
  }

  async save(){ 

    const response = this.httpClient.post(`https://mojalove.mmsafrica.co.za/api/schedule/material/save_abcd_names.php`,
    {
        firstName: this.firstName,
        surname: this.surname,
        additionalName: this.additionalName,
        fullName: this.fullName,
        romanNumbers: this.romanNumbers,
        title: this.title,
        date: this.date

    },{ responseType: 'json'}).pipe()
    .subscribe(
      (data:any) => {
         
        if(data.message == 'success')
        {
          //this.dialogRef.close();
        }
        else{
          this.alertService.error(data.message);
        }

      },error => {
        this.alertService.error("Something went wrong, Try again");
      } 
    ) 
  }
}